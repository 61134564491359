import {useDispatch, useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {removeDependentCoverage, removeExclusionKey} from '../../utils/dependentCoverageUtil';
import {VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN} from '../../properties/coverages';

export const useTieredCoverageRemover = () => {
    const memberElection = useSelector(memberElectionSelector);
    const dispatch = useDispatch();

    const removeEnrolledCoverage = (coverage, exclusionKeyName) => {
        const isVHos = coverage.product.productType === VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN;

        const data = {
            benefitOptions: memberElection.benefitOptions.filter(benefitOption => benefitOption.benefitKey !== coverage.benefitKey),
            enrolledCoverages: memberElection.enrolledCoverages
                .filter(enrolledCoverage => (isVHos ? enrolledCoverage.productType : enrolledCoverage.coverageName) !== coverage.product.productType),
            familyDemographics: {
                dependents: memberElection.familyDemographics.dependents.map(dependent => {
                    const dependentWithoutExclusionKey = removeExclusionKey(dependent, exclusionKeyName);
                    return removeDependentCoverage(dependentWithoutExclusionKey, coverage.dependentBenefitKey);
                })
            }
        };

        return dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
    };

    return (coverage, exclusionKeyName) => removeEnrolledCoverage(coverage, exclusionKeyName);
};