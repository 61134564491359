import {useAuthService} from '../provider/AuthProvider';
import {getBenefitSummaryPdf} from '../component/coverage/ElectionUtil';
import {useDispatch} from 'react-redux';
import {pushTextLink} from '../utils/googleAnalyticsUtil';

export const useBenefitSummaryService = () => {
    const authService = useAuthService();
    const dispatch = useDispatch();

    pushTextLink('benefit summary');

    return async (memberGroupKey, product) => {
        try {
            await getBenefitSummaryPdf(authService, memberGroupKey, product);
        } catch (e) {
            dispatch({
                type: 'UPDATE_BENEFIT_SUMMARY_DATA',
                data: {error: true}
            });
        }
    };
};