import {lowercaseFirstLetter} from '../../utils/stringUtil';
import FormError from '../common/warning/FormError';
import React from 'react';
import FormWarning from '../common/warning/FormWarning';
import {pushCallToAction} from '../../utils/googleAnalyticsUtil';

const ElectionPanel = (props) => {
    const {
        percentOfSalaryAndWageZero,
        rateSelectorCoverage,
        coverageName,
        selected,
        isElectionValid,
        hoursWorked,
        setSelected,
        children
    } = props;
    const coverageDisplayName = coverageName.replace(/([a-z])([A-Z])/g, '$1 $2');

    const getErrorText = (coverageName) => {
        return `Oops, there is a problem with ${coverageName} coverage.`;
    };

    const isEligibleForCoverage = () => {
        if (Boolean(rateSelectorCoverage)) {
            const minimumRequiredHoursWorked = Array.isArray(rateSelectorCoverage)
                ? rateSelectorCoverage[0].minimumPeriodicHours
                : rateSelectorCoverage.minimumPeriodicHours;
            return hoursWorked >= minimumRequiredHoursWorked;
        }
    };

    if (isEligibleForCoverage()) {
        return <div className='panel' id={`electionPanel${coverageName}Panel`}>
            <div
                id={`electionPanel${coverageName}PanelHeader`}
                className='row'
                style={{backgroundColor: '#035fa4', borderRadius: '10px'}}
            >
                <div className='col-md-6' style={{marginBottom: '7px'}}>
                    <h3 id={`electionPanel${coverageName}PanelHeaderText`} style={{color: 'white'}}>
                        {coverageDisplayName}
                    </h3>
                </div>
                <div className='col-md-6'>
                    <button
                        id={`electionPanel${coverageName}Button`}
                        aria-label={coverageDisplayName}
                        className='btn btn-default pull-right'
                        onClick={() => {
                            pushCallToAction(selected ? 'Minimize' : 'View benefit');
                            setSelected();
                        }}
                        style={{marginTop: '10px', outline: 'none'}}
                    >
                        {selected
                            ? 'Minimize'
                            : 'View benefit'}
                        <i style={{paddingLeft: '5px'}}
                           className={selected ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}/>
                    </button>
                </div>
            </div>
            <div
                id={`electionPanel${coverageName}ComponentDiv`}
                className={selected ? 'collapsible active' : 'collapsible'}
            >
                {percentOfSalaryAndWageZero
                    ? <FormWarning
                        display={percentOfSalaryAndWageZero}
                        warningText='We cannot find a salary to elect this coverage. Please work with your employer to have them elect this coverage for you.'
                    />
                    : children}
            </div>
            <div
                id={`${lowercaseFirstLetter(coverageName)}ElectionPanelFormErrorDiv`}
                style={{textAlign: 'center'}}
                className={isElectionValid ? '' : 'has-error col-md-12'}
            >
                <FormError
                    style={{margin: 0, padding: '15px'}}
                    errorText={getErrorText(coverageDisplayName)}
                />
            </div>
        </div>;
    } else {
        return <span/>;
    }
};

export default ElectionPanel;