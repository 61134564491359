import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {useDependentPopulator} from './useDependentPopulator';
import {GROUP_TERM_LIFE_ENSPACEN, MULTIPLE_OF_PAY_PLAN_TYPE} from '../../properties/coverages';
import CurrencyUtil from '../../utils/currencyUtil';
import {groupTermLifeRateSelector} from '../../redux/selectors/GroupTermLifeRateSelector';

export const useGroupTermLifeCoveragePopulator = () => {
    const groupTermLifeCoverage = useSelector(groupTermLifeRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentPopulator();

    const populateEnrolledCoverage = (selectedRate, selectedBenefitKey) => {
        const enrolledCoverages = buildEnrolledCoverages(selectedBenefitKey, selectedRate);
        const dependents = populateDependents(selectedBenefitKey, groupTermLifeCoverage);

        return {
            enrolledCoverages: enrolledCoverages,
            familyDemographics: {dependents: dependents},
        };
    };

    const buildEnrolledCoverages = (selectedBenefitKey, selectedRate) => {
        const aDnDCoverageBenefitKey = getADnDBenefitKey(selectedBenefitKey);

        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== selectedBenefitKey)
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== aDnDCoverageBenefitKey);

        enrolledCoverages.push({
            benefitKey: selectedBenefitKey,
            esclKey: groupTermLifeCoverage.esclKey,
            memberGroupKey: groupTermLifeCoverage.memberGroupKey,
            coverageName: GROUP_TERM_LIFE_ENSPACEN,
            tierDisplayName: getTierDisplayName(selectedBenefitKey),
            rate: selectedRate.rate,
            amount: selectedRate.value,
            percentOfSalary: getPercentOfSalary(selectedRate, selectedBenefitKey),
            benefit: CurrencyUtil.formatUSD(selectedRate.value),
            guaranteedIssueAmount: getGuaranteedIssueAmount(selectedBenefitKey),
            dependent: !isEmployeeCoverage(selectedBenefitKey)
        });

        if (aDnDCoverageBenefitKey) {
            enrolledCoverages.push(
                {
                    benefitKey: aDnDCoverageBenefitKey,
                    accidentalDeathAndDismemberment: true,
                    esclKey: groupTermLifeCoverage.esclKey,
                    dependent: !isEmployeeCoverage(selectedBenefitKey)
                }
            );
        }
        return enrolledCoverages;
    };

    const getADnDBenefitKey = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? groupTermLifeCoverage.employeeADnDBenefitKey
            : null;
    };

    const getTierDisplayName = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? 'You'
            : 'Family';
    };

    const getPercentOfSalary = (selectedRate, selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            && groupTermLifeCoverage.planType === MULTIPLE_OF_PAY_PLAN_TYPE
            && parseInt(selectedRate.display) * 100;
    };

    const getGuaranteedIssueAmount = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? groupTermLifeCoverage.employeeGuaranteedIssueAmount
            : null;
    };

    const isEmployeeCoverage = (selectedBenefitKey) => {
        return selectedBenefitKey === groupTermLifeCoverage.benefitKey;
    };

    return (selectedRate, selectedBenefitKey) => populateEnrolledCoverage(selectedRate, selectedBenefitKey);
};