import {DEPENDENT_HOSPITAL_INDEMNITY_SORT_CODES} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {getTierDisplayName} from '../../utils/mapper/coverageMapperUtil';
import {useHospitalIndemnityCoveragePopulator} from '../populator/useHospitalIndemnityCoveragePopulator';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';

export const useHospitalIndemnityCoverageMapper = () => {
    const hospitalIndemnityCoverages = useSelector(hospitalIndemnityRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateHospitalIndemnityCoverage = useHospitalIndemnityCoveragePopulator();

    const map = (coverage) => {
        const matchingHospitalIndemnityCoverage = hospitalIndemnityCoverages
            .find(hospitalIndemnityCoverage => hospitalIndemnityCoverage.memberGroupKey
                === memberElection.censusMember.censusMemberEmployment.memberGroupKey
                && hospitalIndemnityCoverage.benefitKey === coverage.benefitKey);

        if (matchingHospitalIndemnityCoverage) {
            const rateDisplayName = getTierDisplayName(
                memberElection.censusMember.dependents,
                DEPENDENT_HOSPITAL_INDEMNITY_SORT_CODES,
                matchingHospitalIndemnityCoverage.rates
            );
            const currentRate = matchingHospitalIndemnityCoverage.rates.find(rate => rate.displayName === rateDisplayName);
            return populateHospitalIndemnityCoverage(currentRate, matchingHospitalIndemnityCoverage);
        }
    };

    return (coverage) => map(coverage);
};