import {
    CHILD_CRITICAL_ILLNESS_SORT_CODE,
    EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE,
    SPOUSE_CRITICAL_ILLNESS_SORT_CODE
} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {criticalIllnessRateSelector} from '../../redux/selectors/CriticalIllnessRateSelector';
import {useCriticalIllnessCoveragePopulator} from '../populator/useCriticalIllnessCoveragePopulator';

export const useCriticalIllnessCoverageMapper = () => {
    const criticalIllnessCoverage = useSelector(criticalIllnessRateSelector);
    const populateCriticalIllnessCoverage = useCriticalIllnessCoveragePopulator();

    const map = (coverage) => {
        if (coverage.product.compassGroupSortCode === EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE) {
            const matchingRate = criticalIllnessCoverage.employeeRates.find(rate => rate.value === coverage.approvedAmount);
            return populateCriticalIllnessCoverage(matchingRate, criticalIllnessCoverage.benefitKey);
        }
        if (coverage.product.compassGroupSortCode === SPOUSE_CRITICAL_ILLNESS_SORT_CODE) {
            const matchingRate = criticalIllnessCoverage.spouseRates.find(rate => rate.value === coverage.approvedAmount);
            return populateCriticalIllnessCoverage(matchingRate, criticalIllnessCoverage.spouseBenefitKey);
        }
        if (coverage.product.compassGroupSortCode === CHILD_CRITICAL_ILLNESS_SORT_CODE) {
            const matchingRate = criticalIllnessCoverage.childRates.find(rate => rate.value === coverage.approvedAmount);
            return populateCriticalIllnessCoverage(matchingRate, criticalIllnessCoverage.childBenefitKey);
        }
    };

    return (coverage) => map(coverage);
};