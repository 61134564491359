import React from 'react';
import moment from 'moment';

const Footer = () => {
    return <footer className='footer noPrint' style={{position: 'absolute', bottom: 0, width: '100%', height: '5px'}}>
        <section className='footer-row'>
            <nav>
                <ul className='nav nav-inline'>
                    <li><a href='https://www.principal.com/terms-of-use'>Terms of Use</a></li>
                    <li><a href='https://www.principal.com/products-services-disclosures'>Disclosures</a></li>
                    <li><a href='https://www.principal.com/privacy-policies'>Privacy</a></li>
                    <li><a href='https://www.principal.com/security-policies'>Security</a></li>
                    <li><a
                        href='https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct'>Report Fraud</a></li>
                </ul>
            </nav>
            <div className='footer-legal'>
                <p>&copy; {moment().year()}, Principal Financial Services, Inc.</p>
                <p>Securities offered through Principal Securities, Inc., <a href='http://www.sipc.org'>member SIPC</a>
                </p>
                <p>Insurance from Principal&reg; is issued by Principal National Life (except in NY) and Principal Life
                    Insurance Company, Des Moines, IA 50392</p>
                <p>
                    To ensure confidentiality of your information, either select Logout, exit the browser, or
                    turn off
                    the PC
                    when you’re finished.
                </p>
            </div>
        </section>
    </footer>;
};

export default Footer;