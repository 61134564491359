import {VISION_SORT_CODES} from '../../properties/coverages';
import {convertToRateList} from '../../utils/rates/convertToRateListUtil';

export const visionRateSelector = (state) => {
    const firstAvailableMemberGroupKey = state.memberElection.memberGroups[0];
    return state.policyData.rates
        .filter(rateTier => VISION_SORT_CODES.includes(rateTier.rateProduct.compassGroupSortCode)
            && rateTier.memberGroupKey === firstAvailableMemberGroupKey)
        .map(rateTier => convertToRateList(state, rateTier))[0];
};

