export const combineDependentsAndCoverages = (currentDependents, newDependents) => {
    if (currentDependents.length > newDependents.length) {
        throw new Error('New dependent list should always contain all dependents.');
    }
    return newDependents.map(newDependent => {
        const matchingCurrentDependent = currentDependents
            .find(dependentToAdd => dependentToAdd.guid === newDependent.guid);
        if (matchingCurrentDependent) {
            const uniqueCoverages = [...new Set(matchingCurrentDependent.coverages.concat(newDependent.coverages))];
            return {
                ...matchingCurrentDependent,
                ...newDependent,
                coverages: uniqueCoverages
            };
        }
        return newDependent;
    });
};