import {getSettings} from '../repository/SettingsRepository';

export const cognitoAuthSettings = async () => {
    if (process.env.REACT_APP_STAGE === 'local') {
        return localSettings();
    }
    const settings = await getSettings();
    const redirectUri = settings.stage === 'prod' ? 'https://benefitenrollment.individuals.principal.com/.auth/callback'
        : `https://benefitenrollment.individuals.${settings.stage}.principal.com/.auth/callback`;
    const postLogoutRedirectUri = settings.stage === 'prod' ? 'https://benefitenrollment.individuals.principal.com/.auth/logoutCallback'
        : `https://benefitenrollment.individuals.${settings.stage}.principal.com/.auth/logoutCallback`;
    return {
        authority: `https://gb-member-elect-domain-${settings.stage}.auth.us-east-2.amazoncognito.com`,
        metadataUrl: `https://cognito-idp.us-east-2.amazonaws.com/${settings.userPoolId}/.well-known/openid-configuration`,
        client_id: `${settings.clientId}`,
        response_type: 'code',
        scope: `${settings.scope}`,
        redirect_uri: redirectUri,
        post_logout_redirect_uri: postLogoutRedirectUri,
        automaticSilentRenew: true,
        metadata: {
            authorization_endpoint: `https://gb-member-elect-domain-${settings.stage}.auth.us-east-2.amazoncognito.com/oauth2/authorize`,
            id_token_signing_alg_values_supported: [
                'RS256'
            ],
            issuer: `https://cognito-idp.us-east-2.amazonaws.com/${settings.userPoolId}`,
            jwks_uri: `https://cognito-idp.us-east-2.amazonaws.com/${settings.userPoolId}/.well-known/jwks.json`,
            response_types_supported: [
                'code',
                'token'
            ],
            scopes_supported: [
                'openid',
                'email',
                'phone',
                'profile'
            ],
            subject_types_supported: [
                'public'
            ],
            token_endpoint: `https://gb-member-elect-domain-${settings.stage}.auth.us-east-2.amazoncognito.com/oauth2/token`,
            token_endpoint_auth_methods_supported: [
                'client_secret_basic',
                'client_secret_post'
            ],
            userinfo_endpoint: `https://gb-member-elect-domain-${settings.stage}.auth.us-east-2.amazoncognito.com/oauth2/userInfo`,
            end_session_endpoint: `https://gb-member-elect-domain-${settings.stage}.auth.us-east-2.amazoncognito.com/logout?logout_uri=${postLogoutRedirectUri}&client_id=${settings.clientId}`
        }
    };
};

const localSettings = () => {
    const client_id = '7hc9i9jjn2ld5r0ifesrjbgvf1';
    const userPool = 'us-east-2_JH5Fe282C';
    return {
        authority: 'https://gb-member-elect-domain-sandbox.auth.us-east-2.amazoncognito.com',
        metadataUrl: `https://cognito-idp.us-east-2.amazonaws.com/${userPool}/.well-known/openid-configuration`,
        client_id,
        response_type: 'code',
        scope: 'gb-member-resource-server/member openid',
        redirect_uri: 'http://localhost/.auth/callback',
        post_logout_redirect_uri: 'http://localhost/.auth/logoutCallback',
        loadUserInfo: true,
        automaticSilentRenew: true,
        isOkta: false,
        metadata: {
            authorization_endpoint: 'https://gb-member-elect-domain-sandbox.auth.us-east-2.amazoncognito.com/oauth2/authorize',
            id_token_signing_alg_values_supported: [
                'RS256'
            ],
            issuer: `https://cognito-idp.us-east-2.amazonaws.com/${userPool}`,
            jwks_uri: `https://cognito-idp.us-east-2.amazonaws.com/${userPool}/.well-known/jwks.json`,
            response_types_supported: [
                'code',
                'token'
            ],
            scopes_supported: [
                'openid',
                'email',
                'phone',
                'profile'
            ],
            subject_types_supported: [
                'public'
            ],
            token_endpoint: 'https://gb-member-elect-domain-sandbox.auth.us-east-2.amazoncognito.com/oauth2/token',
            token_endpoint_auth_methods_supported: [
                'client_secret_basic',
                'client_secret_post'
            ],
            userinfo_endpoint: 'https://gb-member-elect-domain-sandbox.auth.us-east-2.amazoncognito.com/oauth2/userInfo',
            end_session_endpoint: `https://gb-member-elect-domain-sandbox.auth.us-east-2.amazoncognito.com/logout?logout_uri=http://localhost/.auth/logoutCallback&client_id=${client_id}`
        }
    };
};

export const oktaAuthSettings = async () => {
    if (process.env.REACT_APP_STAGE === 'local') {
        console.log('getting local settings...');
        return localOktaSettings();
    }
    const settings = await getSettings();
    return {
        authority: settings.stage === 'prod'
            ? 'https://accounts.principal.com/oauth2/aus8xm6hson7W0A385d7'
            : 'https://accounts.staging.principal.com/oauth2/aus4nvp00mFyV36By697',
        client_id: settings.oktaClientId,
        response_type: 'code',
        scope: 'openid profile',
        redirect_uri: settings.stage === 'prod'
            ? 'https://benefitenrollment.individuals.principal.com/.auth/oktaCallback'
            : `https://benefitenrollment.individuals.${settings.stage}.principal.com/.auth/oktaCallback`,
        post_logout_redirect_uri: settings.stage === 'prod'
            ? 'https://secure05.principal.com/auth/corp/logout.jsp'
            : 'https://secure05.pilot.principal.com/auth/corp/logout.jsp',
        loadUserInfo: true,
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        isOkta: true
    };
};

const localOktaSettings = () => {
    return {
        authority: 'https://accounts.staging.principal.com/oauth2/aus4nvp00mFyV36By697',
        client_id: 'sKss9NIH2leYFtnHD6LnGj7Ud2CBnLj3',
        response_type: 'code',
        scope: 'openid profile',
        redirect_uri: 'http://localhost.pilot.principal.com/.auth/oktaCallback',
        post_logout_redirect_uri: 'http://localhost.pilot.principal.com/.auth/oktaLogoutCallback',
        loadUserInfo: true,
        automaticSilentRenew: true,
        isOkta: true
    };
};