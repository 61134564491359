import React from 'react';

const DropDownField = (props) => {
    return  <span className={props.required && !props.value ? 'has-error' : ''}>
                    <select
                        aria-label={props.ariaLabel}
                        id={props.id}
                        className='form-control'
                        onChange={props.onChange}
                        value={props.value}
                        onBlur={props.onBlur}
                        required={props.required}
                        disabled={props.disabled}
                        autoFocus={props.autoFocus ? props.autoFocus : false}
                        autoComplete='new-password'
                    >
                        {buildOptions(props)}
                    </select>
            </span>;
};

const buildOptions = (props) => {
    return props.options.map(option => {
        const value = props.capitalize ? option.value.toUpperCase() : option.value,
            display = props.capitalize ? option.display.toUpperCase() : option.display;

        return (
            <option key={`select-${value}`} value={value}>
                {display}
            </option>
        );
    });
};

export default DropDownField;