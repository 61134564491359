import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {voluntaryTermLifeRateSelector} from '../../redux/selectors/VoluntaryTermLifeRateSelector';
import {useDependentPopulator} from './useDependentPopulator';
import {MULTIPLE_OF_PAY_PLAN_TYPE, VOLUNTARY_TERM_LIFE_ENSPACEN} from '../../properties/coverages';
import CurrencyUtil from '../../utils/currencyUtil';

export const useVoluntaryTermLifeCoveragePopulator = () => {
    const voluntaryTermLifeCoverage = useSelector(voluntaryTermLifeRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentPopulator();

    const populateEnrolledCoverage = (selectedRate, selectedBenefitKey) => {
        const enrolledCoverages = buildEnrolledCoverages(selectedBenefitKey, selectedRate);
        const dependents = populateDependents(selectedBenefitKey, voluntaryTermLifeCoverage);

        return {
            enrolledCoverages: enrolledCoverages,
            familyDemographics: {dependents: dependents},
        };
    };

    const buildEnrolledCoverages = (selectedBenefitKey, selectedRate) => {
        const aDnDCoverageBenefitKey = getADnDBenefitKey(selectedBenefitKey);

        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== selectedBenefitKey)
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== aDnDCoverageBenefitKey);

        enrolledCoverages.push({
            benefitKey: selectedBenefitKey,
            esclKey: voluntaryTermLifeCoverage.esclKey,
            memberGroupKey: voluntaryTermLifeCoverage.memberGroupKey,
            coverageName: VOLUNTARY_TERM_LIFE_ENSPACEN,
            tierDisplayName: getTierDisplayName(selectedBenefitKey),
            rate: selectedRate.rate,
            amount: selectedRate.value,
            percentOfSalary: getPercentOfSalary(selectedRate, selectedBenefitKey),
            benefit: CurrencyUtil.formatUSD(selectedRate.value),
            guaranteedIssueAmount: getGuaranteedIssueAmount(selectedBenefitKey),
            dependent: !isEmployeeCoverage(selectedBenefitKey)
        });

        if (aDnDCoverageBenefitKey) {
            enrolledCoverages.push(
                {
                    benefitKey: aDnDCoverageBenefitKey,
                    accidentalDeathAndDismemberment: true,
                    esclKey: voluntaryTermLifeCoverage.esclKey,
                    dependent: !isEmployeeCoverage(selectedBenefitKey)
                }
            );
        }
        return enrolledCoverages;
    };

    const getADnDBenefitKey = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? voluntaryTermLifeCoverage.employeeADnDBenefitKey
            : isSpouseCoverage(selectedBenefitKey)
                ? voluntaryTermLifeCoverage.spouseADnDBenefitKey
                : null;
    };

    const getTierDisplayName = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? 'You'
            : isSpouseCoverage(selectedBenefitKey)
                ? 'Spouse'
                : 'Child(ren)';
    };

    const getPercentOfSalary = (selectedRate, selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            && voluntaryTermLifeCoverage.planType === MULTIPLE_OF_PAY_PLAN_TYPE
            && parseInt(selectedRate.display) * 100;
    };

    const getGuaranteedIssueAmount = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? voluntaryTermLifeCoverage.employeeGuaranteedIssueAmount
            : isSpouseCoverage(selectedBenefitKey)
                ? voluntaryTermLifeCoverage.spouseGuaranteedIssueAmount
                : null;
    };

    const isEmployeeCoverage = (selectedBenefitKey) => {
        return selectedBenefitKey === voluntaryTermLifeCoverage.benefitKey;
    };

    const isSpouseCoverage = (selectedBenefitKey) => {
        return selectedBenefitKey === voluntaryTermLifeCoverage.spouseBenefitKey;
    };

    return (selectedRate, selectedBenefitKey) => populateEnrolledCoverage(selectedRate, selectedBenefitKey);
};