import {
    DEPENDENT_GROUP_TERM_LIFE_SORT_CODE,
    EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE,
    EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE,
    MULTIPLE_OF_PAY_PLAN_TYPE
} from '../../properties/coverages';
import {getCoverageBySortCode} from '../../utils/rates/getCoverageBySortCodeUtil';
import {getGuaranteedIssueAmount} from '../../utils/rates/getGuaranteedIssueAmountUtil';
import {getCoverage} from '../../utils/rates/getCoverageUtil';
import {buildMultipleOfPayRates} from '../../utils/rates/buildMultipleOfPayRatesUtil';
import {buildFlatRates} from '../../utils/rates/buildFlatRatesUtil';
import {getRateAmount} from '../../utils/rates/getRateFactorUtil';
import {getDependentContributionDollarRate, getDependentContributionPercentRate} from '../../utils/rates/getDependentContributionRateUtil';

export const groupTermLifeRateSelector = (state) => {
    const firstAvailableMemberGroupKey = state.memberElection.memberGroups[0];

    const convertToRateTranche = (rate, memberGroupKey) => {
        const employeeCoverage = getCoverageBySortCode(state, memberGroupKey, EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE);
        const employeeADnDCoverage = getCoverageBySortCode(state, memberGroupKey, EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE);
        const dependentCoverage = getCoverageBySortCode(state, memberGroupKey, DEPENDENT_GROUP_TERM_LIFE_SORT_CODE);
        const dependentRateAmount = dependentCoverage && parseFloat(getRateAmount(rate, 0, undefined, undefined, DEPENDENT_GROUP_TERM_LIFE_SORT_CODE));
        const dependentCoverageAmount = getDependentAmount(state, dependentRateAmount, memberGroupKey, employeeCoverage.benefitKey);
        const employeeADnDRate = state.policyData.rates.find(rate => rate.rateProduct.compassGroupSortCode === EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE);
        return {
            memberGroupKey: rate.memberGroupKey,
            esclKey: rate.esclKey,
            employeeRates: employeeCoverage.planType === MULTIPLE_OF_PAY_PLAN_TYPE
                ? buildMultipleOfPayRates(
                    state,
                    rate,
                    employeeCoverage,
                    employeeCoverage.benefitKey,
                    EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE)
                : buildFlatRates(
                    state,
                    rate,
                    employeeCoverage.benefitOptions,
                    state.memberElection.memberDemographics.smoker,
                    state.memberElection.memberDemographics.gender,
                    employeeCoverage.benefitKey,
                    EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE,
                    EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE),
            employeeGuaranteedIssueAmount: getGuaranteedIssueAmount(employeeCoverage.benefitOptions, state.memberElection.memberDemographics.dateOfBirth),
            dependentBenefitKey: dependentCoverage && dependentCoverage.benefitKey,
            dependentRates: dependentCoverage
                ? [{amount: dependentCoverageAmount, rate: dependentCoverageAmount}]
                : [],
            employeeADnDBenefitKey: employeeADnDCoverage && employeeADnDCoverage.benefitKey,
            employeeADnDEsclKey: employeeADnDRate && employeeADnDRate.esclKey,
            ...getCoverage(state, rate.memberGroupKey, rate.bntpKey)
        };
    };

    if (!Boolean(state.memberElection.memberDemographics.dateOfBirth)) {
        return undefined;
    }
    return state.policyData.rates
        .filter(rate => rate.rateProduct.compassGroupSortCode === EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE
            && rate.memberGroupKey === firstAvailableMemberGroupKey)
        .map(rate => convertToRateTranche(rate, firstAvailableMemberGroupKey))[0];
};

const getDependentAmount = (state, dependentRateAmount, memberGroupKey, benefitKey) => {
    const contributionDollar = getDependentContributionDollarRate(state, memberGroupKey, benefitKey);
    const contributionPercent = getDependentContributionPercentRate(state, memberGroupKey, benefitKey);

    const rateAmount = contributionDollar
        ? Math.round((dependentRateAmount - contributionDollar) * 100) / 100
        : Math.round((dependentRateAmount * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};