export const rateTierForTier = (value) => {
    switch (value) {
        case 'EMPLOYEE':
            return 'You';
        case 'THREE_TIER_PLUS_ONE':
            return 'You and your dependent';
        case 'FOUR_TIER_SPOUSE':
            return 'You and your spouse';
        case 'FOUR_TIER_PLUS_DEPENDENT_NO_SPOUSE':
            return 'You and your children';
        case 'TWO_TIER':
        case 'THREE_TIER_PLUS_TWO_OR_MORE':
        case 'FOUR_TIER_PLUS_DEPENDENT_AND_SPOUSE':
            return 'You and your family';
        default:
            return 'None';
    }
};