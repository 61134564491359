import {DEPENDENT_DENTAL_SORT_CODES} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {dentalRateSelector} from '../../redux/selectors/DentalRateSelector';
import {useDentalCoveragePopulator} from '../populator/useDentalCoveragePopulator';
import {getTierDisplayName} from '../../utils/mapper/coverageMapperUtil';

export const useDentalCoverageMapper = () => {
    const dentalCoverages = useSelector(dentalRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDentalCoverage = useDentalCoveragePopulator();

    const map = () => {
        const matchingDentalCoverage = dentalCoverages
            .find(dentalCoverage => dentalCoverage.memberGroupKey
                === memberElection.censusMember.censusMemberEmployment.memberGroupKey);
        const rateDisplayName = getTierDisplayName(
            memberElection.censusMember.dependents,
            DEPENDENT_DENTAL_SORT_CODES,
            matchingDentalCoverage.rates
        );
        const currentRate = matchingDentalCoverage.rates.find(rate => rate.displayName === rateDisplayName);
        return populateDentalCoverage(currentRate, matchingDentalCoverage);
    };

    return () => map();
};