import React from 'react';

const Indicator = (props) => {
    return <li className={`horizon-step-indicator ${props.status} noPrint`}>
            <span>
                {props.indicatorText}
            </span>
    </li>;
};

export default Indicator;