const defaultState = {memberDemographics: {}, familyDemographics: {}};

const memberElectionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_MEMBER_ELECTION':
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default memberElectionReducer;