import {getCoverage} from './getCoverageUtil';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {rateTierForTier} from '../rateTierUtil';
import {getDependentContributionDollarRate, getDependentContributionPercentRate} from './getDependentContributionRateUtil';
import {getDependentCoverage} from '../dependentCoverageUtil';

export const convertToRateList = (state, rateTier) => {
    const matchingMemberCoverage = getCoverage(state, rateTier.memberGroupKey, rateTier.bntpKey);

    const getDependentBenefitKey = () => {
        const matchingMemberGroup = state.policyData.billGroupData.memberGroups
            .find(memberGroup => memberGroup.memberGroupKey === rateTier.memberGroupKey);
        const dependentCoverages = matchingMemberGroup.coverages.filter(coverage => coverage.product.dependentProduct);
        const dependentCoverage = getDependentCoverage(dependentCoverages, matchingMemberCoverage);
        return dependentCoverage && dependentCoverage.benefitKey;
    };

    return {
        memberGroupKey: rateTier.memberGroupKey,
        esclKey: rateTier.esclKey,
        rates: convertRates(state, rateTier),
        ...matchingMemberCoverage,
        dependentBenefitKey: getDependentBenefitKey()
    };
};

const convertRates = (state, rateTier) => {
    const contributionPercent = getContributionPercentRate(state, rateTier.memberGroupKey, rateTier.bntpKey);
    const contributionDollar = getContributionDollarRate(state, rateTier.memberGroupKey, rateTier.bntpKey);

    const dependentContributionPercent = getDependentContributionPercentRate(state, rateTier.memberGroupKey, rateTier.bntpKey);
    const dependentContributionDollar = getDependentContributionDollarRate(state, rateTier.memberGroupKey, rateTier.bntpKey);

    const employeeRate = Object.entries(rateTier.ratesByTier).find(rate => rate[0] === 'EMPLOYEE');
    let rates = Object.entries(rateTier.ratesByTier).map(entry => {
        return {
            tier: entry[0],
            displayName: rateTierForTier(entry[0]),
            rate: getRate(entry, employeeRate, contributionDollar, contributionPercent, dependentContributionDollar, dependentContributionPercent),
            preContributionRate: entry[0] === 'EMPLOYEE'
                ? parseFloat(entry[1].rateString)
                : parseFloat(entry[1].rateString) + parseFloat(employeeRate[1].rateString)
        };
    });
    rates = sortRateByValue(rates);
    return rates;
};

const getRate = (entry, employeeRate, contributionDollar, contributionPercent, dependentContributionDollar, dependentContributionPercent) => {
    const employeeRateAmount = getEmployeeRate(employeeRate, contributionDollar, contributionPercent);
    const dependentRateAmount = getDependentRate(entry, dependentContributionDollar, dependentContributionPercent);

    return entry[0] === 'EMPLOYEE'
        ? Math.round((employeeRateAmount) * 100) / 100
        : Math.round((employeeRateAmount + dependentRateAmount) * 100) / 100;
};

const getEmployeeRate = (employeeRate, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? parseFloat(employeeRate[1].rateString) - contributionDollar
        : parseFloat(employeeRate[1].rateString) * contributionPercent;

    return rateAmount < 0 ? 0 : rateAmount;
};

const getDependentRate = (entry, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? parseFloat(entry[1].rateString) - contributionDollar
        : parseFloat(entry[1].rateString) * contributionPercent;

    return rateAmount < 0 ? 0 : rateAmount;
};

const sortRateByValue = (rates) => {
    rates.sort((a, b) => {
        return a.rate - b.rate;
    });
    return rates;
};