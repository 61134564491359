import React from 'react';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const ModifyDependentWarning = (props) => {
    return <div className='modal' role='dialog' style={{display: 'block'}}>
        <div className='modal-dialog modal-std'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h4 className='modal-title'>Warning</h4>
                </div>
                <div className='modal-body'>
                    Are you sure you want to do this? Changes here will clear out any elections
                    you may have selected.
                    <div className='modal-footer'>
                        <div className='row'>
                            <div className='col-md-6' style={{paddingLeft: '0px', textAlign: 'left'}}>
                                <a
                                    href='#'
                                    onClick={() => {
                                        props.cancel();
                                        pushTextLink('Cancel');
                                    }}
                                >
                                    {'Cancel'}
                                </a>
                            </div>
                            <div className='col-md-6 pull-right'>
                                <a
                                    href='#'
                                    onClick={() => {
                                        props.onConfirm();
                                        pushTextLink('Confirm');
                                    }}
                                >
                                    {'Confirm'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ModifyDependentWarning;