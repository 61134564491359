import {useSelector} from 'react-redux';
import {shortTermDisabilityRateSelector} from '../../redux/selectors/ShortTermDisabilityRateSelector';
import {useShortTermDisabilityCoveragePopulator} from '../populator/useShortTermDisabilityCoveragePopulator';

export const useShortTermDisabilityCoverageMapper = () => {
    const shortTermDisabilityCoverage = useSelector(shortTermDisabilityRateSelector);
    const populateShortTermDisabilityCoverage = useShortTermDisabilityCoveragePopulator();

    const map = (coverage) => {
        const matchingRate = shortTermDisabilityCoverage.employeeRates.find(rate => rate.value === coverage.approvedAmount);
        return populateShortTermDisabilityCoverage(matchingRate);
    };

    return (coverage) => map(coverage);
};