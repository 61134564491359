import React from 'react';

export const useKeypress = (key, action, inputs) => {
    React.useEffect(() => {
        const onKeyup = (e) => {
            if (e.key === key) {
                action();
            }
        };
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [key, action, inputs]);
};