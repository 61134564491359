import React from 'react';

const FormWarning = (props) => {
    return (
        <div style={props.display ? {textAlign:'center'} : {textAlign:'center',display: 'none'}}
             className='alert alert-warning alert-icon col-md-12' role='alert'>
            {props.warningText}
        </div>
    );
};

export default FormWarning;
