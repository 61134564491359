import React from 'react';

export const CoverageTableHeaderWithAmount = () => {
    return <thead>
    <tr>
        <th>Covers</th>
        <th>Amount</th>
        <th>Cost per month</th>
        <th/>
    </tr>
    </thead>;
};

export default CoverageTableHeaderWithAmount;
