export const alphabeticWithSpaces = (text) => {
    return text.replace(/[^A-Za-z\s]/g, '');
};

export const alphabeticWithSpacesHyphensAndApostrophes = (text) => {
    return text.replace(/[^A-Za-z\s'-]/g, '');
};

export const alphaNumericWithSpaces = (text) => {
    return text.replace(/[^A-Za-z0-9\s#]/g, '');
};

export const removeSpaces = (text) => {
    return text.replaceAll(' ', '');
};

export const numeric = (text) => {
    return text.replace(/[^0-9]/g, '');
};

export const lowercaseFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
};

export const stripCents = (string) => {
    return string.split('.')[0];
};
