import React from 'react';
import InactivityModal from '../modal/InactivityModal';
import {THIRTY_MINUTES} from '../../../properties/times';
import {useAuthService} from '../../../provider/AuthProvider';

const Header = (props) => {
    const service = useAuthService();
    let [sessionExpiring, setSessionExpiring] = React.useState(false);

    React.useEffect(() => {
        const runTimer = async () => {
            await new Promise(resolve => setTimeout(resolve, THIRTY_MINUTES));
            setSessionExpiring(true);
        };
        !sessionExpiring && runTimer();
    }, [sessionExpiring]);

    return <div className='container' style={{paddingTop: '15px', paddingBottom: '10px'}}>
        <div className='row'>
            <div className='col-md-8'>
                <a href={'https://www.principal.com'}>
                    <img src='https://www.principalcdn.com/css/horizon/v2/logo-company-name-gradient.png'
                         alt='principal logo'/></a>
            </div>
            {props.showLogout &&
                <div className='col-md-4 noPrint'>
                    <a className='pull-right' href={'/logout'}>Log Out</a>
                </div>}
        </div>
        {(service && service.isAuthenticated() && sessionExpiring) &&
            <InactivityModal
                continue={() => setSessionExpiring(false)}
            />}
    </div>;
};

export default Header;