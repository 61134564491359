import {getMemberElectionData, postMemberElectionData} from '../../repository/MemberElectionRepository';
import {getPolicyData} from '../../repository/PolicyDataRepository';
import {getContributionData} from '../../repository/ContributionRepository';
import {ECONSENT_PAGE, ERROR_PAGE} from '../../component/Home';

export const retrieveData = (authService, setPage) => {
    return async (dispatch) => {
        try {
            const accessToken = await authService.getAccessToken();
            const memberElectionData = await getMemberElectionData(accessToken, authService.isOkta);
            const policyData = await getPolicyData(accessToken, authService.isOkta);
            const contributionData = await getContributionData(accessToken, authService.isOkta);
            dispatch({type: 'UPDATE_MEMBER_ELECTION', data: memberElectionData});
            dispatch({type: 'UPDATE_POLICY_DATA', data: policyData});
            dispatch({type: 'UPDATE_CONTRIBUTION_DATA', data: contributionData});
            setPage(ECONSENT_PAGE);
        } catch (error) {
            setPage(ERROR_PAGE);
        }
    };
};

export const saveMemberElection = (authService, memberElectionData, setPage) => {
    return async () => {
        try {
            const accessToken = await authService.getAccessToken();
            await postMemberElectionData(accessToken, memberElectionData, authService.isOkta);
        } catch (error) {
            setPage(ERROR_PAGE);
        }
    };
};