import {
    CHILD_VOLUNTARY_TERM_LIFE_SORT_CODE,
    EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE,
    SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE
} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {voluntaryTermLifeRateSelector} from '../../redux/selectors/VoluntaryTermLifeRateSelector';
import {useVoluntaryTermLifeCoveragePopulator} from '../populator/useVoluntaryTermLifeCoveragePopulator';

export const useVoluntaryTermLifeCoverageMapper = () => {
    const voluntaryTermLifeCoverage = useSelector(voluntaryTermLifeRateSelector);
    const populateVoluntaryTermLifeCoverage = useVoluntaryTermLifeCoveragePopulator();

    const map = (coverage) => {
        if (coverage.product.compassGroupSortCode === EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE) {
            const matchingRate = voluntaryTermLifeCoverage.employeeRates.find(rate => rate.value === coverage.approvedAmount);
            return populateVoluntaryTermLifeCoverage(matchingRate, voluntaryTermLifeCoverage.benefitKey);
        }
        if (coverage.product.compassGroupSortCode === SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE) {
            const matchingRate = voluntaryTermLifeCoverage.spouseRates.find(rate => rate.value === coverage.approvedAmount);
            return populateVoluntaryTermLifeCoverage(matchingRate, voluntaryTermLifeCoverage.spouseBenefitKey);
        }
        if (coverage.product.compassGroupSortCode === CHILD_VOLUNTARY_TERM_LIFE_SORT_CODE) {
            const matchingRate = voluntaryTermLifeCoverage.childRates.find(rate => rate.value === coverage.approvedAmount);
            return populateVoluntaryTermLifeCoverage(matchingRate, voluntaryTermLifeCoverage.childBenefitKey);
        }
    };

    return (coverage) => map(coverage);
};