import {isAdultAgeValid, isChildAgeValid, isHandicappedChildAgeValid} from '../utils/dateValidation';
import {FOSTER_CHILD, GRAND_CHILD, HANDICAPPED_CHILD, isChild} from '../properties/relationships';

export const validate = (state, setState, options) => {
    let validationFields = {
        firstNameIsValid: validateName(state.firstName),
        lastNameIsValid: validateName(state.lastName),
        genderIsValid: validateRequiredFieldIsSet(state.gender),
        dateOfBirthIsValid: validateDOB(state.dateOfBirth, state.relationship),
        smokerIsValid: validateSmoker(state.smoker, state.relationship, options.checkSmokerQuestion)
    };
    if (options.dependent) {
        validationFields = {...validationFields, ...dependentValidation(state)};
    }
    if (options.checkAddress) {
        validationFields = {...validationFields, ...addressValidation(state)};
    }

    setState({...state, ...validationFields});
    return Object.values(validationFields).every(bool => bool);
};

const addressValidation = (state) => {
    return {
        addressLineOneIsValid: validateRequiredFieldIsSet(state.addressLineOne),
        stateIsValid: validateRequiredFieldIsSet(state.state),
        cityIsValid: validateRequiredFieldIsSet(state.city),
        zipCodeIsValid: validateZip(state.zipCode)
    };
};

const dependentValidation = (state) => {
    return {
        relationshipIsValid: validateRequiredFieldIsSet(state.relationship),
        dependentQuestionIsValid: validateQuestionIsAnswered(state.relationship, state.dependentQuestion)
    };
};

const validateRequiredFieldIsSet = (value) => {
    return Boolean(value);
};

const validateName = (name) => {
    return Boolean(name) && name.length > 1;
};

const validateSmoker = (smoker, relationship, checkSmokerQuestion) => {
    if (checkSmokerQuestion && !isChild(relationship)) {
        return Boolean(smoker);
    }
    return true;
};

const validateZip = (zip) => {
    return Boolean(zip) && zip.toString().length > 4;
};

const validateQuestionIsAnswered = (relationship, question) => {
    if (relationship === FOSTER_CHILD || relationship === GRAND_CHILD) {
        return Boolean(question);
    } else {
        return true;
    }
};

const validateDOB = (dateOfBirth, relationship) => {
    let isAgeValid;
    if (!isChild(relationship)) {
        isAgeValid = Boolean(dateOfBirth) && isAdultAgeValid(dateOfBirth);
        return isAgeValid;
    } else if (relationship === HANDICAPPED_CHILD) {
        isAgeValid = Boolean(dateOfBirth) && isHandicappedChildAgeValid(dateOfBirth);
        return isAgeValid;
    } else {
        isAgeValid = Boolean(dateOfBirth) && isChildAgeValid(dateOfBirth);
        return isAgeValid;
    }
};