import React from 'react';
import {Redirect} from 'react-router';
import {TWO_MINUTES} from '../../../properties/times';
import {modalView} from '../../../utils/googleAnalyticsUtil';

const InactivityModal = (props) => {
    let [sessionExpired, setSessionExpired] = React.useState(false);

    React.useEffect(() => {
        const runTimer = async () => {
            await new Promise(resolve => setTimeout(resolve, TWO_MINUTES));
            setSessionExpired(true);
        };
        !sessionExpired && runTimer();
    }, [sessionExpired]);

    modalView('InactivityModal');

    return <div>{
        sessionExpired
            ? <Redirect to='logout'/>
            : <div className='modal'
                   role='dialog'
                   style={{display: 'block'}}>
                <div className='modal-dialog modal-std'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title'>Session Timeout</h4>
                        </div>
                        <div className='modal-body'>
                            You haven't used this site for more than <strong>30 minutes</strong>.
                            <br/>
                            To protect your information, we'll automatically log you out in <strong>2 minutes</strong>.
                            <br/>
                            <div className='modal-footer'>
                                <div className='row'>
                                    <div className='col-md-6' style={{paddingLeft: '0px', textAlign: 'left'}}>
                                        <a href='/logout'>Log Out</a>
                                    </div>
                                    <div className='col-md-6 pull-right'>
                                        <a href='#' onClick={props.continue}>Continue Working</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }</div>;
};

export default InactivityModal;