import {useSelector} from 'react-redux';
import {longTermDisabilityRateSelector} from '../../redux/selectors/LongTermDisabilityRateSelector';
import {useLongTermDisabilityCoveragePopulator} from '../populator/useLongTermDisabilityCoveragePopulator';

export const useLongTermDisabilityCoverageMapper = () => {
    const longTermDisabilityCoverage = useSelector(longTermDisabilityRateSelector);
    const populateLongTermDisabilityCoverage = useLongTermDisabilityCoveragePopulator();

    const map = (coverage) => {
        const matchingRate = longTermDisabilityCoverage.employeeRates.find(rate => rate.value === coverage.approvedAmount);
        return populateLongTermDisabilityCoverage(matchingRate);
    };

    return (coverage) => map(coverage);
};