import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../../redux/selectors/MemberElectionSelector';
import {amountIsNotGreaterThanBenefitOptionAmount} from '../../coverage/ElectionUtil';
import FormError from './FormError';
import {isPartner} from '../../../properties/relationships';

export const DependentAmountError = (props) => {
    const memberElection = useSelector(memberElectionSelector);

    const render = () => {
        const coverage = props.coverage;
        const dependentBenefitKey = isPartner(props.relationship)
            ? coverage.spouseBenefitKey
            : coverage.childBenefitKey;
        const dependentCannotExceedPercentage = isPartner(props.relationship)
            ? coverage.spouseCannotExceedPercent
            : coverage.childCannotExceedPercent;

        return <div
            className={getClassName(coverage, dependentBenefitKey, dependentCannotExceedPercentage)}
            style={{textAlign: 'center'}}>
            <FormError id={`dependentAmountError${props.relationship}FormError`}
                       errorText={`${props.relationship} cannot elect more than ${dependentCannotExceedPercentage * 100}% of the Employee benefit`}/>
        </div>;
    };

    const getClassName = (coverage, dependentBenefitKey, dependentCannotExceedPercentage) => {
        if (amountIsNotGreaterThanBenefitOptionAmount(memberElection, coverage, dependentBenefitKey, dependentCannotExceedPercentage)) {
            return '';
        }
        return 'has-error';
    };

    return render();
};
