import {numeric} from '../stringUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import {FLAT_AMOUNT} from '../../properties/benefitOptions';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {getRateFactor} from './getRateFactorUtil';
import CurrencyUtil from '../currencyUtil';

export const buildFlatRates = (state, rate, benefitOptions, smoker, gender, benefitKey, sortCode, aDnDSortCode) => {
    const aDnDRate = state.policyData.rates.find(rate => rate.rateProduct.compassGroupSortCode === aDnDSortCode);

    const flatAmount = parseInt(numeric(getMatchingBenefitOption(benefitOptions, FLAT_AMOUNT)));
    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const rateFactor = getRateFactor(rate, 0, smoker, gender, sortCode);
    const rateADnDFactor = aDnDRate
        ? getRateFactor(aDnDRate, 0, smoker, gender, aDnDSortCode)
        : 0;
    return Array(1).fill().map(() => {
        return {
            display: CurrencyUtil.formatUSD(flatAmount, {minimumFractionDigits: 0}),
            value: flatAmount,
            rate: getRate(flatAmount, rate, rateFactor, rateADnDFactor, contributionDollar, contributionPercent)
        };
    });
};

const getRate = (flatAmount, rate, rateFactor, rateADnDFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round(((flatAmount / rate.rateVolumeUnit * (rateFactor + rateADnDFactor)) - contributionDollar) * 100) / 100
        : Math.round(((flatAmount / rate.rateVolumeUnit * (rateFactor + rateADnDFactor)) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};