import moment from 'moment';

export const inOpenEnrollmentSelector = (state) => {
    const twoMonthPriorToDate = (givenDate) => {
        const twoMonthPriorToDate = moment(givenDate).subtract(2, 'month');
        const oneMonthAfter = moment(givenDate).add(1, 'month');
        return givenDate
            ? moment()
                .isBetween(twoMonthPriorToDate, oneMonthAfter, 'day', '[)')
            : false;
    };

    const doesDataExist = () => {
        return state && state.policyData && state.policyData.billGroupData && state.policyData.billGroupData.policy;
    };

    return doesDataExist()
        ? (twoMonthPriorToDate(state.policyData.billGroupData.policy.policyAnniversaryDate)
            || twoMonthPriorToDate(state.policyData.billGroupData.policy.nextPolicyAnniversaryDate))
        : false;
};