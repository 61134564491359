import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import DropDownField from '../common/form/DropDownField';
import {
    CIVIL_UNION_PARTNER,
    COMMON_LAW_SPOUSE,
    DOMESTIC_PARTNER,
    FOSTER_CHILD,
    GRAND_CHILD,
    isPartner,
    partnerRelationships,
    RECIPROCAL_BENEFICIARY,
    relationships,
    STATE_REGISTERED_DOMESTIC_PARTNER
} from '../../properties/relationships';
import FormError from '../common/warning/FormError';
import ModifyDependentWarning from './ModifyDependentWarning';
import {useSelector} from 'react-redux';
import {hasNicotineUsageQuestionSelector} from '../../redux/selectors/HasNicotineUsageQuestionSelector';
import SmokerQuestion from '../common/form/SmokerQuestion';
import {validate} from '../../validation/DemographicsValidator';
import DateOfBirthInput from '../demographics/DateOfBirthInput';
import GenderInput from '../demographics/GenderInput';
import FirstLastNameInput from '../demographics/FirstLastNameInput';
import {pushCallToAction} from '../../utils/googleAnalyticsUtil';

const DependentDemographics = (props) => {
    const showSmokerQuestion = useSelector(hasNicotineUsageQuestionSelector);

    const [state, setState] = React.useState({
        firstName: props.dependent.firstName ?? '',
        lastName: props.dependent.lastName ?? '',
        guid: props.dependent.guid,
        nameId: props.dependent.nameId ?? null,
        gender: props.dependent.gender ?? '',
        relationship: props.dependent.relationship ?? '',
        dateOfBirth: props.dependent.dateOfBirth ?? '',
        dependentQuestion: props.dependent.dependentQuestion ?? '',
        smoker: props.dependent.smoker ?? '',
        coverages: props.dependent.coverages ?? [],
        firstNameIsValid: true,
        lastNameIsValid: true,
        dateOfBirthIsValid: true,
        genderIsValid: true,
        relationshipIsValid: true,
        dependentQuestionIsValid: true,
        smokerIsValid: true,
        displayChildPlacementError: false,
        showDeleteDependentWarning: false,
        showSaveDependentWarning: false
    });

    const render = () => {
        return <div className='col-md-8'>
            <div id='memberNameSection' className='col-md-12 form-group form-group-sm panel' style={{padding: '15px'}}>
                {state.showDeleteDependentWarning && <ModifyDependentWarning
                    cancel={() => setState({...state, showDeleteDependentWarning: false})}
                    onConfirm={() => {
                        removeDependent();
                        props.setClearEnrolledCoverages(true);
                    }}
                />}
                {state.showSaveDependentWarning && <ModifyDependentWarning
                    cancel={() => setState({...state, showSaveDependentWarning: false})}
                    onConfirm={() => {
                        saveButtonActions();
                        props.setClearEnrolledCoverages(true);
                        setState({...state, showSaveDependentWarning: false});
                    }}
                />}
                <div className='row' style={{paddingBottom: '15px'}}>
                    <FirstLastNameInput
                        id={`firstNameLabel${props.index}`}
                        labelText='First name'
                        containerClass={'col-md-5'}
                        value={state.firstName}
                        isValid={state.firstNameIsValid}
                        disabled={!props.dependent.editable}
                        required={true}
                        setValue={(name) => setState({...state, firstName: name})}
                    />
                    <FirstLastNameInput
                        id={`lastNameLabel${props.index}`}
                        labelText='Last name'
                        containerClass={'col-md-5'}
                        value={state.lastName}
                        isValid={state.lastNameIsValid}
                        disabled={!props.dependent.editable}
                        required={true}
                        setValue={(name) => setState({...state, lastName: name})}
                    />
                    {removeButton()}
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    <DateOfBirthInput
                        id={`dateOfBirthLabel${props.index}`}
                        containerClass={'col-md-5'}
                        value={state.dateOfBirth}
                        isValid={state.dateOfBirthIsValid}
                        disabled={!props.dependent.editable}
                        setValue={(name) => setState({...state, dateOfBirth: name})}
                    />
                    <GenderInput
                        id={`genderLabel${props.index}`}
                        value={state.gender}
                        containerClass={'col-md-5'}
                        isValid={state.genderIsValid}
                        disabled={!props.dependent.editable}
                        setValue={(gender) => setState({...state, gender: gender})}
                    />
                </div>
                <div className='row'>
                    {buildRelationshipDropdown()}
                    {displaySpouseTermDisclosure(state.relationship) && buildSpouseTermDisclosure()}
                    {fosterOrGrandChildSection()}
                    {childPlacementQuestionError()}
                    {dependentQuestionError()}
                    <SmokerQuestion
                        visible={showSmokerQuestion && partnerRelationships.includes(state.relationship)}
                        spouse
                        disabled={!props.dependent.editable}
                        smoker={state.smoker}
                        smokerIsValid={state.smokerIsValid}
                        handleButtonClick={(smoker) => setState({...state, smoker})}
                    />
                    {props.dependent.editable
                        ? <div id='dependentDemographicsSaveCancelButtons' className='col-md-12'>
                            {!state.displayChildPlacementError && saveButton()}
                            {cancelButton()}
                        </div>
                        : <div id='dependentDemographicsEditButton' className='col-md-12'>
                            {editButton()}
                        </div>}
                </div>
            </div>
        </div>;
    };

    const fosterOrGrandChildSection = () => {
        return <>
            {isFosterOrGrandChild(state.relationship) && <div className='col-md-12'>
                    <span id='dependentDemographicsChildPlacementQuestion'>
                        Was the child placed with you by an authorized state placement agency or by order of a court?<RequiredFieldIndicator/>
                    </span>
                <div className='btn-group btn-group-sm' role='group' style={{margin: '10px'}}>
                    <button
                        id='dependentDemographicsChildPlacementQuestionYes'
                        className={state.dependentQuestion === 'Yes' ? 'btn btn-default active' : 'btn btn-default'}
                        disabled={!props.dependent.editable}
                        onClick={() => {
                            pushCallToAction('Yes');
                            handleYesQuestion();
                        }}
                    >
                        {'Yes'}
                    </button>
                    <button
                        id='dependentDemographicsChildPlacementQuestionNo'
                        className={state.dependentQuestion === 'No' ? 'btn btn-default active' : 'btn btn-default'}
                        disabled={!props.dependent.editable}
                        onClick={() => {
                            pushCallToAction('No');
                            handleNoQuestion();
                        }}
                    >
                        {'No'}
                    </button>
                </div>
            </div>}
        </>;
    };

    const saveButton = () => {
        return <button
            aria-label={`dependentDemographicsSave${props.index}`}
            id={`dependentDemographicsSave${props.index}`}
            tabIndex={-1}
            className={'btn btn-link pull-right'}
            style={{outline: 'none', fontSize: '20px'}}
            onClick={() => {
                pushCallToAction('Save');
                if (validate(state, setState, {dependent: true, checkSmokerQuestion: showSmokerQuestion})) {
                    props.hasBenefitElections
                        ? setState({...state, showSaveDependentWarning: true})
                        : saveButtonActions();
                }
            }}
        >
            <i className='fa fa-save'/>
        </button>;
    };

    const cancelButton = () => {
        return <button
            aria-label={`dependentDemographicsCancel${props.index}`}
            id={`dependentDemographicsCancel${props.index}`}
            tabIndex={-1}
            className={'btn btn-link pull-right'}
            style={{outline: 'none', fontSize: '20px'}}
            onClick={() => {
                pushCallToAction('Cancel');
                resetDemographicFields();
            }}
        >
            <i className='fa fa-times'/>
        </button>;
    };

    const editButton = () => {
        return <button
            aria-label={`dependentDemographicsEdit${props.index}`}
            id={`dependentDemographicsEdit${props.index}`}
            tabIndex={-1}
            className={'btn btn-link pull-right'}
            onClick={() => {
                pushCallToAction('Edit');
                editButtonAction(true);
            }}
            style={{outline: 'none', fontSize: '20px'}}
        >
            <i className='fa fa-pencil'/>
        </button>;
    };

    const removeButton = () => {
        return <div className='col-md-2'>
            <button
                aria-label={`removeDependent${props.index}`}
                id={`removeDependent${props.index}`}
                tabIndex={-1}
                className={'btn btn-link pull-right'}
                onClick={() => {
                    pushCallToAction('Remove');
                    props.hasBenefitElections
                        ? setState({...state, showDeleteDependentWarning: true})
                        : removeDependent();
                }}
                style={{outline: 'none', fontSize: '20px'}}
            >
                <i className='fa fa-trash-o'/>
            </button>
        </div>;
    };

    const isFosterOrGrandChild = (relationship) => {
        return (relationship === FOSTER_CHILD || relationship === GRAND_CHILD);
    };

    const displaySpouseTermDisclosure = (relationship) => {
        return [
            COMMON_LAW_SPOUSE,
            CIVIL_UNION_PARTNER,
            DOMESTIC_PARTNER,
            STATE_REGISTERED_DOMESTIC_PARTNER,
            RECIPROCAL_BENEFICIARY].includes(relationship);
    };

    const handleYesQuestion = () => {
        setState({
            ...state,
            displayChildPlacementError: false,
            dependentQuestionIsValid: true,
            dependentQuestion: 'Yes'
        });
    };

    const handleNoQuestion = () => {
        setState({
            ...state,
            displayChildPlacementError: true,
            dependentQuestionIsValid: true,
            dependentQuestion: 'No'
        });
    };

    const buildRelationshipDropdown = () => {
        return <div className='col-md-5'>
            <label id={`relationshipLabel${props.index}`} style={{'width': '100%'}}
                   className={state.relationshipIsValid ? '' : 'has-error'}>
                Relationship <RequiredFieldIndicator/>
                <DropDownField
                    ariaLabel='Relationship'
                    value={state.relationship}
                    options={filterRelationshipDropdown()}
                    onChange={(e) => setRelationship(e.target.value)}
                    disabled={!props.dependent.editable}
                />
                <FormError errorText='Please enter a valid relationship'/>
            </label>
        </div>;
    };

    const filterRelationshipDropdown = () => {
        const hasPartner = props.dependents
            .filter((dep, index) => index !== props.index)
            .some((dependent) => isPartner(dependent.relationship));

        if (hasPartner) {
            return relationships.filter(relationship => !isPartner(relationship.value));
        } else {
            return relationships;
        }
    };

    const buildSpouseTermDisclosure = () => {
        return <div className='col-md-12' id='dependentDemographicsSpouseTermDisclosure'>
                        <span id='dependentDemographicsSpouseTermDisclosureSpan'>
                        For the purpose of this enrollment, anywhere you see the term "spouse", it will also include the following terms, subject to state insurance laws and your employer’s election of optional domestic partner coverage:
                         </span>
            <br/>
            <br/>
            <ul id='dependentDemographicsSpouseTermDisclosureUnorderedList'>
                <li>State registered domestic partner</li>
                <li>Civil union partner</li>
                <li>Reciprocal beneficiary</li>
                <li>Domestic partner or non-registered domestic partner</li>
                <li>Common-law spouse</li>
            </ul>
        </div>;
    };

    const childPlacementQuestionError = () => {
        return <div id='dependentDemographicsChildPlacementQuestionError'
                    className={state.displayChildPlacementError ? 'has-error col-md-8' : ''}>
            <FormError
                errorText={'This Dependent is not eligible. Please Remove them.'}
            />
        </div>;
    };

    const dependentQuestionError = () => {
        return <div id='dependentDemographicsQuestionError'
                    className={state.dependentQuestionIsValid ? '' : 'has-error col-md-8'}>
            <FormError
                errorText={'Please make sure question is answered.'}
            />
        </div>;
    };

    const editButtonAction = (editable) => {
        props.setDependents([...props.dependents.slice(0, props.index),
            {
                ...props.dependent,
                editable
            },
            ...props.dependents.slice(props.index + 1)]);
    };

    const saveButtonActions = () => {
        props.setDependents([...props.dependents.slice(0, props.index),
            {
                firstName: state.firstName.trim().toUpperCase(),
                lastName: state.lastName.trim().toUpperCase(),
                dateOfBirth: state.dateOfBirth,
                gender: state.gender,
                relationship: state.relationship,
                editable: false,
                guid: state.guid,
                nameId: state.nameId,
                dependentQuestion: state.dependentQuestion,
                smoker: state.smoker,
                coverages: state.coverages
            },
            ...props.dependents.slice(props.index + 1)]);
    };

    const resetDemographicFields = () => {
        if (props.dependents[props.index] && props.dependents[props.index].firstName) {
            setState({
                ...state,
                firstName: props.dependents[props.index].firstName,
                lastName: props.dependents[props.index].lastName,
                dateOfBirth: props.dependents[props.index].dateOfBirth,
                gender: props.dependents[props.index].gender,
                relationship: props.dependents[props.index].relationship,
                dependentQuestion: props.dependents[props.index].dependentQuestion,
                firstNameIsValid: true,
                lastNameIsValid: true,
                dateOfBirthIsValid: true,
                genderIsValid: true,
                relationshipIsValid: true,
                smokerIsValid: true,
                displayChildPlacementError: false
            });
            editButtonAction(false);
        } else {
            removeDependent();
        }
    };

    const setRelationship = (relationship) => {
        setState({
            ...state,
            relationship: relationship,
            displayChildPlacementError: isFosterOrGrandChild(relationship) && state.dependentQuestion === 'No'
        });
    };

    const removeDependent = () => {
        return props.setDependents([...props.dependents.slice(0, props.index), ...props.dependents.slice(props.index + 1)]);
    };

    return render();
};

export default DependentDemographics;