import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import MaskedInput from 'react-text-mask';
import FormError from '../common/warning/FormError';

const DateOfBirthInput = (props) => {

    return <div className={props.containerClass}>
        <label id={props.id} style={{'width': '100%'}}
               className={props.isValid ? '' : 'has-error'}>
            Date of birth <RequiredFieldIndicator/>
            <MaskedInput
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                onBlur={(e) => props.setValue(e.target.value)}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder='MM/DD/YYYY'
                className='form-control'
                autoComplete='new-password'
                disabled={props.disabled}
            />
            <FormError errorText='Please enter a valid date of birth'/>
        </label>
    </div>;
};

export default DateOfBirthInput;