import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';

export const getDependentContributionPercentRate = (state, memberGroupKey, memberBenefitKey) => {
    const dependentCoverage = getDependentCoverage(state, memberGroupKey, memberBenefitKey);
    return dependentCoverage ? getContributionPercentRate(state, memberGroupKey, dependentCoverage.benefitKey) : 1.0;
};

export const getDependentContributionDollarRate = (state, memberGroupKey, memberBenefitKey) => {
    const dependentCoverage = getDependentCoverage(state, memberGroupKey, memberBenefitKey);
    return dependentCoverage ? getContributionDollarRate(state, memberGroupKey, dependentCoverage.benefitKey) : 0;
};

const getDependentCoverage = (state, memberGroupKey, memberBenefitKey) => {
    const memberCoverage = state.policyData.billGroupData.memberGroups
        .find(memberGroup => memberGroup.memberGroupKey === memberGroupKey)
        .coverages.find(coverage => coverage.benefitKey === memberBenefitKey);

    return state.policyData.billGroupData.memberGroups
        .find(memberGroup => memberGroup.memberGroupKey === memberGroupKey)
        .coverages.find(coverage => coverage.product.dependentProduct === true
            && coverage.product.productType === memberCoverage.product.productType);
};