import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import {useSelector} from 'react-redux';
import DropDownField from '../common/form/DropDownField';
import states from '../../properties/states';
import {alphabeticWithSpaces, alphaNumericWithSpaces, numeric} from '../../utils/stringUtil';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import FormError from '../common/warning/FormError';
import {useKeypress} from '../../hook/effect/useKeypress';
import {hasNicotineUsageQuestionSelector} from '../../redux/selectors/HasNicotineUsageQuestionSelector';
import SmokerQuestion from '../common/form/SmokerQuestion';
import {validate} from '../../validation/DemographicsValidator';
import DateOfBirthInput from '../demographics/DateOfBirthInput';
import GenderInput from '../demographics/GenderInput';
import FirstLastNameInput from '../demographics/FirstLastNameInput';
import MiddleNameInput from '../demographics/MiddleNameInput';

const MemberDemographics = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const showSmokerQuestion = useSelector(hasNicotineUsageQuestionSelector);

    const [state, setState] = React.useState({
        firstNameIsValid: true,
        lastNameIsValid: true,
        dateOfBirthIsValid: true,
        genderIsValid: true,
        addressLineOneIsValid: true,
        cityIsValid: true,
        stateIsValid: true,
        zipCodeIsValid: true,
        smokerIsValid: true
    });

    React.useEffect(() => {
        setState(state => ({
            ...state,
            firstName: memberElection.memberDemographics.firstName,
            middleName: memberElection.memberDemographics.middleName,
            lastName: memberElection.memberDemographics.lastName,
            gender: memberElection.memberDemographics.gender,
            dateOfBirth: memberElection.memberDemographics.dateOfBirth,
            addressLineOne: memberElection.memberDemographics.addressLineOne,
            addressLineTwo: memberElection.memberDemographics.addressLineTwo,
            city: memberElection.memberDemographics.city,
            state: memberElection.memberDemographics.state,
            zipCode: memberElection.memberDemographics.zipCode,
            smoker: memberElection.memberDemographics.smoker
        }));
    }, [memberElection]);

    const useKeypressAction = () => {
        const options = {checkSmokerQuestion: showSmokerQuestion, checkAddress: true};
        if (validate(state, setState, options)) {
            saveAndConfirm();
        }
    };

    useKeypress('Enter',
        useKeypressAction,
        [state, setState, props, showSmokerQuestion]
    );

    const render = () => {
        return <div className='container'>
            <div id='memberNameSection' className='col-md-8 form-group form-group-sm panel' style={{padding: '15px'}}>
                <div className='row' style={{paddingBottom: '15px'}}>
                    <FirstLastNameInput
                        id='FirstNameLabel'
                        labelText='First name'
                        value={state.firstName}
                        isValid={state.firstNameIsValid}
                        containerClass={'col-md-4'}
                        required={true}
                        setValue={(name) => setState({...state, firstName: name})}
                    />
                    <MiddleNameInput
                        id='MiddleNameLabel'
                        labelText='Middle name'
                        value={state.middleName}
                        isValid={true}
                        containerClass={'col-md-4'}
                        setValue={(name) => setState({...state, middleName: name})}
                    />
                    <FirstLastNameInput
                        id='LastNameLabel'
                        labelText='Last name'
                        value={state.lastName}
                        isValid={state.lastNameIsValid}
                        containerClass={'col-md-4'}
                        required={true}
                        setValue={(name) => setState({...state, lastName: name})}
                    />
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    <DateOfBirthInput
                        id='DateOfBirthLabel'
                        value={state.dateOfBirth}
                        isValid={state.dateOfBirthIsValid}
                        containerClass='col-md-6'
                        setValue={(dateOfBirth) => setState({...state, dateOfBirth: dateOfBirth})}
                    />
                    <GenderInput
                        id='GenderLabel'
                        value={state.gender}
                        isValid={state.genderIsValid}
                        setValue={(gender) => setState({...state, gender})}
                    />
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    {buildAddressOneInput()}
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    {buildAddressTwoInput()}
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    {buildCity()}
                    {buildState()}
                    {buildZip('col-md-4')}
                </div>
                <div className='row' style={{paddingBottom: '15px'}}>
                    {buildDisabledInput('Phone number', memberElection.phoneNumber, 'col-md-4')}
                    {buildDisabledInput('Email', memberElection.email, 'col-md-8')}
                </div>
                <SmokerQuestion
                    style={{paddingLeft: '0'}}
                    visible={showSmokerQuestion}
                    smoker={state.smoker}
                    smokerIsValid={state.smokerIsValid}
                    handleButtonClick={(smoker) => setState({...state, smoker: smoker})}
                />
            </div>
            <div className='row col-md-12'>
                <hr className='dotted'/>
                <Button
                    next
                    clickText={'Next'}
                    onClick={() => {
                        if (validate(state, setState, {checkSmokerQuestion: showSmokerQuestion, checkAddress: true})) {
                            saveAndConfirm();
                        }
                    }}
                >
                    {'Next '}
                </Button>
            </div>
        </div>;
    };

    const buildCity = () => {
        return <div className={'col-md-4'}>
            <label id='CityLabel' style={{'width': '100%'}}
                   className={state.cityIsValid ? '' : 'has-error'}>
                City <RequiredFieldIndicator/>
                <input
                    type='text'
                    value={state.city}
                    onChange={(e) => setState({...state, city: alphabeticWithSpaces(e.target.value)})}
                    className='form-control'
                    style={{textTransform: 'uppercase'}}
                    maxLength={50}
                    autoComplete='new-password'
                />
                <FormError errorText='Please enter a valid city'/>
            </label>
        </div>;
    };

    const buildDisabledInput = (label, value, className) => {
        return <div className={className}>
            <label id={`${label.replace(/\s/g, '')}Label`} style={{'width': '100%'}}>
                {label}
                <input
                    type='text'
                    value={value}
                    className='form-control'
                    style={{textTransform: 'uppercase'}}
                    maxLength={50}
                    disabled={true}
                    autoComplete='new-password'
                />
            </label>
        </div>;
    };

    const buildState = () => {
        return <div className='col-md-4'>
            <label id='StateLabel' style={{'width': '100%'}}
                   className={state.stateIsValid ? '' : 'has-error'}>
                State <RequiredFieldIndicator/>
                <DropDownField
                    ariaLabel='State'
                    options={states}
                    value={state.state}
                    onChange={(e) => setState({...state, state: e.target.value})}
                />
                <FormError errorText='Please enter a valid state'/>
            </label>
        </div>;
    };

    const buildAddressOneInput = () => {
        return <div className={'col-md-12'}>
            <label id='AddressLineOneLabel' style={{'width': '100%'}}
                   className={state.addressLineOneIsValid ? '' : 'has-error'}>
                Address line one <RequiredFieldIndicator/>
                <input
                    type='text'
                    value={state.addressLineOne}
                    onChange={(e) => setState({...state, addressLineOne: alphaNumericWithSpaces(e.target.value)})}
                    className='form-control'
                    style={{textTransform: 'uppercase'}}
                    maxLength={50}
                    autoComplete='new-password'
                />
                <FormError errorText='Please enter a valid address line one'/>
            </label>
        </div>;
    };

    const buildAddressTwoInput = () => {
        return <div className={'col-md-12'}>
            <label id='AddressLineTwoLabel' style={{'width': '100%'}}>
                Address line two
                <input
                    type='text'
                    value={state.addressLineTwo}
                    onChange={(e) => setState({...state, addressLineTwo: alphaNumericWithSpaces(e.target.value)})}
                    className='form-control'
                    style={{textTransform: 'uppercase'}}
                    maxLength={50}
                    autoComplete='new-password'
                />
            </label>
        </div>;
    };

    const buildZip = (className) => {
        return <div className={className}>
            <label id='ZipCodeLabel' style={{'width': '100%'}} className={state.zipCodeIsValid ? '' : 'has-error'}>
                ZIP code <RequiredFieldIndicator/>
                <input
                    type='text'
                    value={state.zipCode}
                    onChange={(e) => setState({...state, zipCode: numeric(e.target.value)})}
                    className='form-control'
                    maxLength={5}
                    autoComplete='new-password'
                />
                <FormError errorText='Please enter a valid ZIP code'/>
            </label>
        </div>;
    };

    const saveAndConfirm = () => {
        props.save({
            memberDemographics: {
                firstName: state.firstName.trim().toUpperCase(),
                middleName: state.middleName ? state.middleName.trim().toUpperCase() : '',
                lastName: state.lastName.trim().toUpperCase(),
                gender: state.gender,
                dateOfBirth: state.dateOfBirth,
                addressLineOne: state.addressLineOne.trim().toUpperCase(),
                addressLineTwo: state.addressLineTwo ? state.addressLineTwo.trim().toUpperCase() : '',
                city: state.city.trim().toUpperCase(),
                state: state.state,
                zipCode: state.zipCode,
                smoker: state.smoker
            }
        });
        props.onConfirm();
    };

    return render();
};

export default MemberDemographics;