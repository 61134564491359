import React from 'react';
import {
    BENEFITS_PAGE,
    CONFIRMATION_PAGE,
    ECONSENT_PAGE,
    FAMILY_DEMOGRAPHICS_PAGE,
    MEMBER_DEMOGRAPHICS_PAGE,
    REVIEW_PAGE
} from '../../Home';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {memberElectionSelector} from '../../../redux/selectors/MemberElectionSelector';
import {policyDataSelector} from '../../../redux/selectors/PolicyDataSelector';

const ContentHeader = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const policyData = useSelector(policyDataSelector);

    return <div className='container noPrint'>
        <div className='row'>
            <div className='col-md-8'>
                <h1><strong>{headerText(props.page)}</strong></h1>
                <p style={{fontSize: '18px'}}>{subHeaderText(props.page)}</p>
            </div>
            <div id='headerCountDownDiv' className='col-md-4' style={{paddingTop: '20px'}}>
                <div className='col-md-1' style={{fontSize: '25px'}}>
                    <i className='fa fa-calendar'/>
                </div>
                <div id='headerEndOfElectionDateDiv' className='col-md-11' style={{fontSize: '14px'}}>
                    <div className='col-md-12'>
                        Complete your enrollment by <strong>{Boolean(memberElection.censusMember.privacyId)
                        ? moment(policyData.billGroupData.policy.policyAnniversaryDate).subtract(1, 'day').format('MMM DD')
                        : moment(memberElection.employmentDate).add(30, 'days').format('MMM DD')}</strong>.
                    </div>
                    <div className='col-md-12'>
                        Enrolling by this date helps ensure you have the benefits you want.
                    </div>
                </div>
            </div>
        </div>
        <br/>
    </div>;
};

const headerText = (page) => {
    if (page === ECONSENT_PAGE) {
        return 'Electronic consent';
    } else if (page === MEMBER_DEMOGRAPHICS_PAGE) {
        return 'About you';
    } else if (page === FAMILY_DEMOGRAPHICS_PAGE) {
        return 'Your family';
    } else if (page === BENEFITS_PAGE) {
        return 'Choose your benefits';
    } else if (page === REVIEW_PAGE) {
        return 'Review and submit benefit choices';
    } else if (page === CONFIRMATION_PAGE) {
        return 'Summary';
    } else {
        return '';
    }
};

const subHeaderText = (page) => {
    if (page === BENEFITS_PAGE) {
        return 'The benefits your employer is offering are listed below. If you have questions about your benefits or the enrollment process, please contact your employer.';
    } else if (page === MEMBER_DEMOGRAPHICS_PAGE) {
        return 'To start your enrollment, tell us a little more about yourself.';
    } else if (page === FAMILY_DEMOGRAPHICS_PAGE) {
        return 'Add your family information here so you can choose benefits for them in the next steps.';
    } else {
        return '';
    }
};

export default ContentHeader;