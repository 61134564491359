import {useDispatch, useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {voluntaryTermLifeRateSelector} from '../../redux/selectors/VoluntaryTermLifeRateSelector';

export const useVoluntaryTermLifeCoverageRemover = () => {
    const memberElection = useSelector(memberElectionSelector);
    const voluntaryTermLifeCoverage = useSelector(voluntaryTermLifeRateSelector);
    const dispatch = useDispatch();

    const removeEnrolledCoverage = (benefitKey) => {
        let benefitKeysToRemove = getBenefitKeysToRemove(benefitKey);

        const dependents = memberElection.familyDemographics.dependents
            .map(dependent => ({
                    ...dependent,
                    coverages: dependent.coverages
                        .filter(dependentCoverageBenefitKey => !benefitKeysToRemove.includes(dependentCoverageBenefitKey))
                })
            );

        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: {
                benefitOptions: memberElection.benefitOptions
                    .filter(benefitOption => !benefitKeysToRemove.includes(benefitOption.benefitKey)),
                enrolledCoverages: memberElection.enrolledCoverages
                    .filter(enrolledCoverage => !benefitKeysToRemove.includes(enrolledCoverage.benefitKey)),
                familyDemographics: {dependents: dependents}
            }
        });
    };

    const getBenefitKeysToRemove = (benefitKey) => {
        let benefitKeysToRemove = [benefitKey];

        if (isEmployeeLevelCoverage(benefitKey)) {
            benefitKeysToRemove.push(
                voluntaryTermLifeCoverage.employeeADnDBenefitKey,
                voluntaryTermLifeCoverage.spouseBenefitKey,
                voluntaryTermLifeCoverage.spouseADnDBenefitKey
            );
            benefitKeysToRemove.push(voluntaryTermLifeCoverage.childBenefitKey);
        }
        if (isSpouseLevelCoverage(benefitKey)) {
            benefitKeysToRemove.push(voluntaryTermLifeCoverage.spouseADnDBenefitKey);
        }
        return benefitKeysToRemove;
    };

    const isEmployeeLevelCoverage = (benefitKey) => {
        return benefitKey === voluntaryTermLifeCoverage.benefitKey;
    };

    const isSpouseLevelCoverage = (benefitKey) => {
        return benefitKey === voluntaryTermLifeCoverage.spouseBenefitKey;
    };

    return (benefitKey) => removeEnrolledCoverage(benefitKey);
};

