import React from 'react';

export const PROGRESS_INDICATOR_STATUS = {
    ACTIVE: 'horizon-step-indicator-active',
    DISABLED: 'horizon-step-indicator-disabled',
    PREVIOUS: 'horizon-step-indicator-previous',
};

const ProgressIndicator = (props) => {
    const childrenWithProps = React.Children.map(props.children, (indicator, index) => {
        return indicator && React.cloneElement(indicator, {
            id: `indicator${index}`,
            key: `indicator${index}`,
            status: setIndicatorStatusFrom(index, indicator.props.activeOnPages, props.page),
        });
    });

    return (
        <div>
            <ol id='indicatorOl' className='indicator indicator-alt' style={{paddingTop: '50px'}}>
                {childrenWithProps}
            </ol>
        </div>
    );
};

const setIndicatorStatusFrom = (index, activeOnPages, page) => {
    if (activeOnPages.includes(page)) {
        return PROGRESS_INDICATOR_STATUS.ACTIVE;
    } else if (index >= page) {
        return PROGRESS_INDICATOR_STATUS.DISABLED;
    } else {
        return PROGRESS_INDICATOR_STATUS.PREVIOUS;
    }
};

export default ProgressIndicator;