import React from 'react';
import {useHistory} from 'react-router';
import {useBothAuthService} from '../../provider/AuthProvider';
import {AUTH_TYPE_COGNITO, AUTH_TYPE_OKTA} from '../../properties/auth';

const LogoutCallback = (props) => {
    const history = useHistory();
    const {authService, oktaAuthService} = useBothAuthService();

    React.useEffect(() => {
        const authCallback = async () => {
            try {
                const service = props.auth === AUTH_TYPE_COGNITO
                    ? authService
                    : oktaAuthService;
                await service.signoutRedirectCallback();
                if (props.auth === AUTH_TYPE_OKTA) {
                    if (!service.isAuthenticated()) {
                        history.push('/oktaLoggedOut');
                    } else {
                        history.push('/existing');
                    }
                } else {
                    if (!service.isAuthenticated()) {
                        history.push('/loggedOut');
                    } else {
                        history.push('/new');
                    }
                }
            } catch (error) {
                history.push('/error');
            }
        };

        authCallback();
    }, [history, authService, oktaAuthService, props.auth]);

    return <span/>;
};

export default LogoutCallback;