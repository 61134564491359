import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {
    hasChildDependent,
    hasSpouseDependent,
    NON_WAIVABLE_COVERAGE_MESSAGE,
    WAIVABLE_COVERAGE_MESSAGE
} from './ElectionUtil';
import DropDownField from '../common/form/DropDownField';
import Button from '../common/form/Button';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeaderWithAmount from '../common/header/CoverageTableHeaderWithAmount';
import {voluntaryTermLifeRateSelector} from '../../redux/selectors/VoluntaryTermLifeRateSelector';
import {TERM_LIFE_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {EoiInfo} from '../common/warning/EoiInfo';
import {useVoluntaryTermLifeCoverageDispatcher} from '../../hook/dispatch/useVoluntaryTermLifeCoverageDispatcher';
import {useVoluntaryTermLifeCoverageRemover} from '../../hook/remover/useVoluntaryTermLifeCoverageRemover';
import {DependentAmountError} from '../common/warning/DependentAmountError';
import {pushCallToAction, pushTextLink} from '../../utils/googleAnalyticsUtil';

const VoluntaryTermLifeElection = () => {
    const voluntaryTermLifeCoverage = useSelector(voluntaryTermLifeRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatchVoluntaryTermLifeCoverage = useVoluntaryTermLifeCoverageDispatcher();
    const removeVoluntaryTermLifeCoverage = useVoluntaryTermLifeCoverageRemover();
    const downloadBenefitSummary = useBenefitSummaryService();

    const buildCoverageSummary = () => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Provides an optional life insurance benefit to help supplement other life insurance policies you may
                    have purchased.
                </li>
                <li>Benefits are paid to your beneficiaries at the time of your death.</li>
                <li>Helps ease the financial burden your family may face without your income.</li>
                <li>Allows you to purchase life insurance for Spouse or dependents when you elect it for yourself.</li>
                <li>Includes an additional benefit which is paid when there is an accident that causes death or
                    dismemberment.
                </li>
                <li>{'To learn more about what your voluntary term life insurance benefit provides you, please download the '}
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(voluntaryTermLifeCoverage.memberGroupKey, 'VTL')}
                    >
                        {'benefit summary'}
                    </a>
                    {'and watch this '}
                    <a
                        target='_blank'
                        href={TERM_LIFE_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildTable = () => {
        return <div key={`voluntaryTermLifeElection-rate${voluntaryTermLifeCoverage.esclKey}`}>
            {buildCoverageSummary()}
            <div>
                {<table className='table'>
                    <CoverageTableHeaderWithAmount/>
                    {buildTableBody()}
                </table>}
                <EoiInfo coverage={voluntaryTermLifeCoverage}/>
                <DependentAmountError coverage={voluntaryTermLifeCoverage} relationship={'Spouse'}/>
                <DependentAmountError coverage={voluntaryTermLifeCoverage} relationship={'Child'}/>
                <div id='voluntaryTermLifeElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {voluntaryTermLifeCoverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = () => {
        return <tbody>
        {buildRow(voluntaryTermLifeCoverage.employeeRates, voluntaryTermLifeCoverage.benefitKey)}
        {hasSpouseDependent(memberElection.familyDemographics.dependents, voluntaryTermLifeCoverage.benefitOptions) && Boolean(voluntaryTermLifeCoverage.spouseBenefitKey)
        && buildRow(voluntaryTermLifeCoverage.spouseRates, voluntaryTermLifeCoverage.spouseBenefitKey)}
        {hasChildDependent(memberElection.familyDemographics.dependents) && Boolean(voluntaryTermLifeCoverage.childBenefitKey)
        && buildRow(voluntaryTermLifeCoverage.childRates, voluntaryTermLifeCoverage.childBenefitKey)}
        </tbody>;
    };

    const shouldShowButton = (benefitKey) => {
        if (benefitKey === voluntaryTermLifeCoverage.spouseBenefitKey || benefitKey === voluntaryTermLifeCoverage.childBenefitKey) {
            return hasElectedCoverage(voluntaryTermLifeCoverage.benefitKey);
        }
        return true;
    };

    const buildRow = (rates, coverageBenefitKey) => {
        const coverageGroupType = coverageBenefitKey === voluntaryTermLifeCoverage.benefitKey
            ? 'You'
            : coverageBenefitKey === voluntaryTermLifeCoverage.spouseBenefitKey
                ? 'Spouse'
                : 'Child';
        return <tr key={`voluntaryTermLifeElection${coverageBenefitKey}-row`}
                   id={`voluntaryTermLifeElection${coverageBenefitKey}-row`}
                   className={hasElectedCoverage(coverageBenefitKey) ? 'bg-light-blue' : ''}>
            <td id={`voluntaryTermLifeElection${coverageBenefitKey}-displayName`}>{coverageGroupType}</td>
            <td>
                <DropDownField
                    ariaLabel={coverageGroupType + ' Voluntary Term Life Amount'}
                    value={memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                        ? memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount
                        : rates[0].value}
                    options={rates}
                    onChange={(e) => dispatchVoluntaryTermLifeCoverage(
                        rates.find(rate => rate.value === parseInt(e.target.value)),
                        coverageBenefitKey)
                    }
                    disabled={!hasElectedCoverage(coverageBenefitKey)}
                />
            </td>
            <td id={`voluntaryTermLifeElection${coverageBenefitKey}-rate`}>{
                memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages
                        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).rate)
                    : CurrencyUtil.formatUSD(rates[0].rate)
            }</td>
            <td>
                {shouldShowButton(coverageBenefitKey) &&
                    <Button
                        style={{fontSize: '13px', minWidth: '140px'}}
                        default={hasElectedCoverage(coverageBenefitKey)}
                        onClick={() => {
                            pushCallToAction(hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add');
                            hasElectedCoverage(coverageBenefitKey)
                                ? removeVoluntaryTermLifeCoverage(coverageBenefitKey)
                                : dispatchVoluntaryTermLifeCoverage(rates[0], coverageBenefitKey);
                        }}
                    >
                        <i
                            style={{paddingRight: '5px'}}
                            className={hasElectedCoverage(coverageBenefitKey) ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}
                        />
                        {hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                    </Button>}
            </td>
        </tr>;
    };

    const hasElectedCoverage = (benefitKey) => memberElection.enrolledCoverages
        && memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === benefitKey);

    return <div className='col-md-12'>
        {buildTable()}
    </div>;
};

export default VoluntaryTermLifeElection;