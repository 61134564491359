import {numeric} from '../stringUtil';
import {BENEFIT_AMOUNT} from '../../properties/benefitOptions';
import CurrencyUtil from '../currencyUtil';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';

export const buildChildRates = (state, rate, benefitKey, benefitOptions, sortCode, amounts) => {
    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);
    const benefitAmounts = amounts && Boolean(amounts.length)
        ? amounts
        : [parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_AMOUNT)))];

    const rateFactor = getChildRateFactor(rate, sortCode);
    return benefitAmounts.map(benefitAmount => {
        return {
            display: CurrencyUtil.formatUSD(benefitAmount, {minimumFractionDigits: 0}),
            value: parseInt(benefitAmount),
            rate: getRate(contributionPercent, contributionDollar, benefitAmount, rate, rateFactor)
        };
    });
};

const getRate = (contributionPercent, contributionDollar, benefitAmount, rate, rateFactor) => {
    const rateAmount = contributionDollar
        ? Math.round(((benefitAmount / rate.rateVolumeUnit * rateFactor) - contributionDollar) * 100) / 100
        : Math.round(((benefitAmount / rate.rateVolumeUnit * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};

const getChildRateFactor = (rate, sortCode) => {
    return rate.rateTranches
        .find(rateTranche => (rateTranche.rateProduct.compassGroupSortCode === sortCode)).bands[0].factor;
};