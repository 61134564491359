import React, {createContext, useContext} from 'react';

const AuthContext = createContext();

export const useAuthService = () => {
    const context = useBothAuthService();
    return context.authService.isAuthenticated() ?
        context.authService :
        context.oktaAuthService.isAuthenticated() ?
            context.oktaAuthService :
            undefined;
};

export const useBothAuthService = () => {
    const context = useContext(AuthContext);
    if (context.authService === undefined) {
        throw new Error('authService must be used inside AuthProvider');
    }
    if (context.oktaAuthService === undefined) {
        throw new Error('oktaAuthService must be used inside AuthProvider');
    }
    return context;
};

export const AuthProvider = ({authService, oktaAuthService, children}) => {
    return (
        <AuthContext.Provider value={{authService, oktaAuthService}}>
            {children}
        </AuthContext.Provider>
    );
};