import React from 'react';
import {useSelector} from 'react-redux';
import {dentalRateSelector} from '../../redux/selectors/DentalRateSelector';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {childRelationships} from '../../properties/relationships';
import {hasChildDependent, hasSpouseDependent, NON_WAIVABLE_COVERAGE_MESSAGE, WAIVABLE_COVERAGE_MESSAGE} from './ElectionUtil';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeader from '../common/header/CoverageTableHeader';
import {DENTAL_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useDentalCoverageDispatcher} from '../../hook/dispatch/useDentalCoverageDispatcher';
import {useTieredCoverageRemover} from '../../hook/remover/useTieredCoverageRemover';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const DentalElection = () => {
    const dentalCoverages = useSelector(dentalRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const downloadBenefitSummary = useBenefitSummaryService();
    const dispatchDentalCoverage = useDentalCoverageDispatcher();
    const removeDentalCoverage = useTieredCoverageRemover();

    const buildCoverageSummary = (coverage) => {
        return <div className='col-md-12'>
            <br/>
            {dentalCoverages.length > 1
            && <h3 id={`dentalcoverage-choiceHeader${coverage.esclKey}`}
                   style={{marginTop: '0'}}>{getHighLowHeaderText(coverage)}</h3>}
            <ul style={{paddingLeft: '0'}}>
                <li>Provides full or partial coverage for common dental procedures and treatments, making visits to
                    the dentist less costly.
                </li>
                <li>Provides partial coverage for unexpected, high-cost dental procedures; not intended to cover 100% of
                    all
                    dental costs.
                </li>
                <li>Gives you access to a network of providers who discount fees for services.</li>
                <li>To learn more about what your dental benefit provides you, please download the
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(coverage.memberGroupKey, coverage.product.voluntary ? 'VOLUNTARY_DENTAL' : 'DENTAL')}
                    >
                        {'benefit summary'}
                    </a>
                    {' and watch this '}
                    <a
                        target='_blank'
                        href={DENTAL_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildRow = (rate, coverage, index) => {
        const activeTier = isActiveTier(coverage, rate);
        return <tr key={`${rate.tier}-row-${index}`} id={`${rate.tier}-row`}
                   className={activeTier ? 'bg-light-blue' : ''}>
            <td id={`${rate.tier}-displayName-${index}`}>{rate.displayName}</td>
            <td id={`${rate.tier}-rate`}>{CurrencyUtil.formatUSD(rate.rate)}</td>
            <td>
                <Button
                    id={`${rate.tier}-coverageButton-${index}`}
                    clickText={activeTier ? 'Remove' : 'Add'}
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={activeTier}
                    onClick={() => {
                        activeTier
                            ? removeDentalCoverage(coverage, 'dentalBenefitExclusionKey')
                            : dispatchDentalCoverage(rate, coverage);
                    }}
                >
                    <i style={{paddingRight: '5px'}} className={activeTier ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {activeTier ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const buildTable = (coverage) => {
        return <div key={`dentalcoverage-rate${coverage.esclKey}`}>
            {buildCoverageSummary(coverage)}
            <div>
                {<table className='table'>
                    <CoverageTableHeader/>
                    {buildTableBody(coverage)}
                </table>}
                <div id='dentalElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {coverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = (coverage) => {
        return <tbody>
        {coverage.rates
            .filter(rates => shouldDisplayRow(rates, memberElection.familyDemographics.dependents, coverage.benefitOptions))
            .map((rate, index) => buildRow(rate, coverage, index))}
        </tbody>;
    };

    const getHighLowHeaderText = (coverage) => {
        const otherDentalCoverage = dentalCoverages.find(dentalCoverage => dentalCoverage.rates[0] !== coverage.rates[0]);
        return otherDentalCoverage && coverage.rates[0].preContributionRate > otherDentalCoverage.rates[0].preContributionRate
            ? 'Dental high'
            : 'Dental low';
    };

    const hasChildren = (dependents) => {
        return dependents.filter(dependent => childRelationships.includes(dependent.relationship)).length > 1;
    };

    const isActiveTier = (coverage, rates) => {
        return memberElection.enrolledCoverages
            && memberElection.enrolledCoverages
                .some(electedCoverage => electedCoverage.esclKey === coverage.esclKey
                    && electedCoverage.tier === rates.tier
                    && electedCoverage.tierDisplayName === rates.displayName);
    };

    const shouldDisplayRow = (rate, dependents, benefitOptions) => {
        if (rate.tier.includes('THREE')) {
            if (rate.displayName === 'You and your family') {
                return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents)
                    || hasChildren(dependents);
            } else {
                return hasChildDependent(dependents) || hasSpouseDependent(dependents, benefitOptions);
            }
        }
        if (rate.displayName === 'You and your spouse') {
            return hasSpouseDependent(dependents, benefitOptions);
        } else if (rate.displayName === 'You and your children') {
            return hasChildDependent(dependents);
        } else if (rate.displayName === 'You and your family') {
            return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents)
                || rate.tier === 'TWO_TIER' && (hasSpouseDependent(dependents, benefitOptions) || hasChildDependent(dependents));
        } else {
            return rate.displayName === 'You';
        }
    };

    return <div className='col-md-12'>
        {dentalCoverages.map(coverage => buildTable(coverage))}
    </div>;
};

export default DentalElection;