export const dataLayer = window.dataLayer = window.dataLayer || [];

export const pushPathChange = (event, path, pageTitle) =>
    dataLayer.push({
        'event': event,
        'virtualpagedetails': {
            'pagepath': `/${path}/`,
            'pagetitle': pageTitle,
        },
        'content': {
            'purpose': 'retention',
            'businessline': 'benefits-and-protection',
            'intendedaudience': 'member',
        },
        'page': {
            'author': '',
            'compliancedate': '',
            'contractid': '',
            'documenttype': 'webpage',
            'fullurl': window.location.href,
            'language': 'english',
            'publishdate': ''
        }
    });

export const pushPolicyInformation = (policyNumber) => dataLayer.push({contract: {contractNumber: policyNumber}});

export const pushCallToAction = (clickText) => dataLayer.push({
    'event': 'pageNavigationClick',
    'navigationsection': 'call-to-action',
    'clicktext': clickText
});

export const pushTextLink = (clickText) => dataLayer.push({
    'event': 'pageNavigationClick',
    'navigationsection': 'text-link',
    'clicktext': clickText
});

export const modalView = (title) => {
    dataLayer.push({
        'event': 'modalView',
        'modaltitle': title
    });
};
