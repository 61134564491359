import React from 'react';
import {COMPLETED, ERRORED, SUBMITTED} from '../../properties/electionStatuses';
import {CONFIRMATION_PAGE, ECONSENT_PAGE, ERROR_PAGE, OUT_OF_ENROLLMENT_PAGE} from '../../component/Home';
import {retrieveData} from '../../redux/action/DataAction';
import {useDispatch, useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {useAuthService} from '../../provider/AuthProvider';
import {inOpenEnrollmentSelector} from '../../redux/selectors/InOpenEnrollmentSelector';

export const useHomeEffect = (page, setPage) => {
    const memberElection = useSelector(memberElectionSelector);
    const inOpenEnrollment = useSelector(inOpenEnrollmentSelector);
    const dispatch = useDispatch();
    const authService = useAuthService();

    React.useEffect(() => {
        dispatch(retrieveData(authService, setPage));
    }, [setPage, dispatch, authService]);

    React.useEffect(() => {
        if (memberElection && page >= ECONSENT_PAGE) {
            if (!Boolean(memberElection.policyKey)
                || memberElection.electionStatus === ERRORED) {
                setPage(ERROR_PAGE);
            } else if (memberElection.electionStatus === COMPLETED
                || memberElection.electionStatus === SUBMITTED) {
                setPage(CONFIRMATION_PAGE);
            } else if (!inOpenEnrollment && memberElection.censusMember.privacyId) {
                setPage(OUT_OF_ENROLLMENT_PAGE);
            }
        }
    }, [page, setPage, memberElection, inOpenEnrollment]);

};