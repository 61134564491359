export const coverageQuestionSelector = (state) => {
    const firstAvailableMemberGroupKey = state.memberElection.memberGroups[0];
    const selectedMemberGroup = state.policyData.billGroupData.memberGroups
        .find(memberGroup => memberGroup.memberGroupKey === firstAvailableMemberGroupKey);

    const enrolledCoverages = state.memberElection.enrolledCoverages;

    return selectedMemberGroup.coverageQuestions
        .filter(filterQuestionsByEnrolledCoverages(enrolledCoverages));
};

const filterQuestionsByEnrolledCoverages = (enrolledCoverages) => {
    return question => question.applicableBenefits
        .some(applicableBenefit => enrolledCoverages
            && enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === applicableBenefit.benefitKey));
};