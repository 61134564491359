module.exports = [
    {display: '', value: ''},
    {display: 'ALABAMA', value: 'AL'},
    {display: 'ALASKA', value: 'AK'},
    {display: 'ARIZONA', value: 'AZ'},
    {display: 'ARKANSAS', value: 'AR'},
    {display: 'CALIFORNIA', value: 'CA'},
    {display: 'COLORADO', value: 'CO'},
    {display: 'CONNECTICUT', value: 'CT'},
    {display: 'DELAWARE', value: 'DE'},
    {display: 'FLORIDA', value: 'FL'},
    {display: 'GEORGIA', value: 'GA'},
    {display: 'HAWAII', value: 'HI'},
    {display: 'IDAHO', value: 'ID'},
    {display: 'ILLINOIS', value: 'IL'},
    {display: 'INDIANA', value: 'IN'},
    {display: 'IOWA', value: 'IA'},
    {display: 'KANSAS', value: 'KS'},
    {display: 'KENTUCKY', value: 'KY'},
    {display: 'LOUISIANA', value: 'LA'},
    {display: 'MAINE', value: 'ME'},
    {display: 'MARYLAND', value: 'MD'},
    {display: 'MASSACHUSETTS', value: 'MA'},
    {display: 'MICHIGAN', value: 'MI'},
    {display: 'MINNESOTA', value: 'MN'},
    {display: 'MISSISSIPPI', value: 'MS'},
    {display: 'MISSOURI', value: 'MO'},
    {display: 'MONTANA', value: 'MT'},
    {display: 'NEBRASKA', value: 'NE'},
    {display: 'NEVADA', value: 'NV'},
    {display: 'NEW HAMPSHIRE', value: 'NH'},
    {display: 'NEW JERSEY', value: 'NJ'},
    {display: 'NEW MEXICO', value: 'NM'},
    {display: 'NEW YORK', value: 'NY'},
    {display: 'NORTH CAROLINA', value: 'NC'},
    {display: 'NORTH DAKOTA', value: 'ND'},
    {display: 'OHIO', value: 'OH'},
    {display: 'OKLAHOMA', value: 'OK'},
    {display: 'OREGON', value: 'OR'},
    {display: 'PENNSYLVANIA', value: 'PA'},
    {display: 'RHODE ISLAND', value: 'RI'},
    {display: 'SOUTH CAROLINA', value: 'SC'},
    {display: 'SOUTH DAKOTA', value: 'SD'},
    {display: 'TENNESSEE', value: 'TN'},
    {display: 'TEXAS', value: 'TX'},
    {display: 'UTAH', value: 'UT'},
    {display: 'VERMONT', value: 'VT'},
    {display: 'VIRGINIA', value: 'VA'},
    {display: 'WASHINGTON', value: 'WA'},
    {display: 'WEST VIRGINIA', value: 'WV'},
    {display: 'WISCONSIN', value: 'WI'},
    {display: 'WYOMING', value: 'WY'},
    {display: 'District of Columbia', value: 'DC'},
    {display: 'US VIRGIN ISLANDS', value: 'VI'}
];

