import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import FormError from '../common/warning/FormError';
import DropDownField from '../common/form/DropDownField';
import genders from '../../properties/genders';

const GenderInput = (props) => {

    return <div className={props.containerClass ? props.containerClass : 'col-md-6'}>
        <label id={props.id} style={{'width': '100%'}}
               className={props.isValid ? '' : 'has-error'}>
            Gender <RequiredFieldIndicator/>
            <DropDownField
                ariaLabel='Gender'
                options={genders}
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                disabled={props.disabled}
            />
            <FormError errorText='Please enter a valid gender'/>
        </label>
    </div>;
};

export default GenderInput;