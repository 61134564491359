import React from 'react';
import Button from '../common/form/Button';
import CoverageTable from '../review/CoverageTable';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {GROUP_TERM_LIFE_ENSPACEN, VOLUNTARY_TERM_LIFE_ENSPACEN} from '../../properties/coverages';

const Confirmation = () => {
    const memberElection = useSelector(memberElectionSelector);

    return <div className='container'>
        <div className='row col-md-8'>
            <div className='panel'>
                <div className='panel-body'>
                    <p>Congratulations! Your benefit elections have been submitted.
                        Please print this summary for your records. Contact your employer if you have any questions</p>
                    <strong>Status: </strong> {memberElection.electionStatus}
                    <CoverageTable
                        shouldShowAdditionalColumns
                        shouldShowCostData
                        shouldHavePromotionalBanner
                        enrolledCoverages={memberElection.enrolledCoverages}
                    />
                    {memberElection.enrolledCoverages
                        .find(coverage => coverage.coverageName === GROUP_TERM_LIFE_ENSPACEN
                            || coverage.coverageName === VOLUNTARY_TERM_LIFE_ENSPACEN)
                        && <div id='confirmationBeneficiaryFormDiv' className='bg-light-gray'
                            style={{marginBottom: '15px', padding: '8px'}}>
                        <p>You have elected a coverage that needs a <a href='beneficiary.pdf' target='_blank'>beneficiary
                            form</a>, please fill out and return to your employer.</p>
                    </div>}
                </div>
            </div>
        </div>
        <div className='row col-md-12'>
            <hr className='dotted'/>
            <Button
                onClick={() => window.print()}
                clickText={'Print'}
            >
                <i className='fa fa-print'/>
                {' Print'}
            </Button>
        </div>
    </div>;
};

export default Confirmation;