import axios from 'axios';
import {getBaseUrl} from './UtilRepository';

export const getBenefitSummaryData = async (accessToken, memberGroupKey, product, isOkta) => {
    const url = await getBaseUrl(isOkta);
    const response = await axios.get(
        `${url}/benefitSummary?memberGroupKey=${memberGroupKey}&product=${product}`,
        {headers: {Authorization: `Bearer ${accessToken}`}}
    );
    return response.data;
};