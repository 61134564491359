import {useDispatch, useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';

export const useDisabilityCoverageRemover = () => {
    const memberElection = useSelector(memberElectionSelector);
    const dispatch = useDispatch();

    const removeEnrolledCoverage = (benefitKey) => {
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: {
                benefitOptions: memberElection.benefitOptions
                    .filter(benefitOption => benefitOption.benefitKey !== benefitKey),
                enrolledCoverages: memberElection.enrolledCoverages
                    .filter(enrolledCoverage => enrolledCoverage.benefitKey !== benefitKey),
            }
        });
    };

    return (benefitKey) => removeEnrolledCoverage(benefitKey);
};