import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {DENTAL} from '../../properties/coverages';
import {useDependentTierPopulator} from './useDependentTierPopulator';
import {BENEFIT_TYPE, PLEASE_SEE_BENEFIT_SUMMARY} from '../../properties/benefitOptions';

export const useDentalCoveragePopulator = () => {
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentTierPopulator();

    const populateEnrolledCoverage = (selectedRate, coverage) => {
        const dependents = populateDependents(selectedRate, coverage, 'dentalBenefitExclusionKey');
        const enrolledCoverages = buildEnrolledCoverages(selectedRate, coverage);

        return {
            enrolledCoverages: enrolledCoverages,
            benefitOptions: memberElection.benefitOptions
                .filter(benefitOption => !benefitOption.labelAliasName.includes('MBR_HUSB_WF')
                    && !benefitOption.labelAliasName.includes('MBR_PRIOR_ORTO')),
            familyDemographics: {
                dependents: dependents
            },
        };
    };

    const getBenefit = (coverage) => {
        const benefitOption = coverage.benefitOptions.find(benefitOption => benefitOption.label === BENEFIT_TYPE);
        return benefitOption
            ? benefitOption.value
            : PLEASE_SEE_BENEFIT_SUMMARY;
    };

    const buildEnrolledCoverages = (selectedRate, coverage) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.coverageName !== DENTAL);

        enrolledCoverages.push({
            benefitKey: coverage.benefitKey,
            esclKey: coverage.esclKey,
            memberGroupKey: coverage.memberGroupKey,
            coverageName: DENTAL,
            tier: selectedRate.tier,
            tierDisplayName: selectedRate.displayName,
            rate: selectedRate.rate,
            benefit: getBenefit(coverage)
        });

        if (selectedRate.tier !== 'EMPLOYEE') {
            enrolledCoverages.push({
                benefitKey: coverage.dependentBenefitKey,
                memberGroupKey: coverage.memberGroupKey,
                coverageName: DENTAL,
                dependent: true
            });
        }
        return enrolledCoverages;
    };

    return (selectedRate, coverage) => populateEnrolledCoverage(selectedRate, coverage);
};