import {
    ACCIDENT,
    CRITICAL_ILLNESS_ENSPACEN,
    DENTAL,
    GROUP_TERM_LIFE_ENSPACEN,
    HOSPITAL_INDEMNITY_ENSPACEN,
    LONG_TERM_DISABILITY_DASHED,
    SHORT_TERM_DISABILITY_DASHED,
    VISION, VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN,
    VOLUNTARY_TERM_LIFE_ENSPACEN
} from '../../properties/coverages';
import {useDispatch, useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {useAccidentCoverageMapper} from '../mapper/useAccidentCoverageMapper';
import {useVisionCoverageMapper} from '../mapper/useVisionCoverageMapper';
import {combineDependentsAndCoverages} from '../../utils/mapper/dependentCoverageMapperUtil';
import {useDentalCoverageMapper} from '../mapper/useDentalCoverageMapper';
import {useVoluntaryTermLifeCoverageMapper} from '../mapper/useVoluntaryTermLifeCoverageMapper';
import {useGroupTermLifeCoverageMapper} from '../mapper/useGroupTermLifeCoverageMapper';
import {useCriticalIllnessCoverageMapper} from '../mapper/useCriticalIllnessCoverageMapper';
import {useLongTermDisabilityCoverageMapper} from '../mapper/useLongTermDisabilityCoverageMapper';
import {useShortTermDisabilityCoverageMapper} from '../mapper/useShortTermDisabilityCoverageMapper';
import {useHospitalIndemnityCoverageMapper} from '../mapper/useHospitalIndemnityCoverageMapper';

export const useExistingCoveragesDispatcher = () => {
    const memberElection = useSelector(memberElectionSelector);
    const mapExistingAccidentCoverage = useAccidentCoverageMapper();
    const mapExistingCriticalIllnessCoverage = useCriticalIllnessCoverageMapper();
    const mapExistingDentalCoverage = useDentalCoverageMapper();
    const mapExistingVisionCoverage = useVisionCoverageMapper();
    const mapExistingVoluntaryTermLifeCoverage = useVoluntaryTermLifeCoverageMapper();
    const mapExistingGroupTermLifeCoverage = useGroupTermLifeCoverageMapper();
    const mapExistingLongTermDisabilityCoverage = useLongTermDisabilityCoverageMapper();
    const mapExistingShortTermDisabilityCoverage = useShortTermDisabilityCoverageMapper();
    const mapExistingHospitalIndemnityCoverage = useHospitalIndemnityCoverageMapper();
    const dispatch = useDispatch();

    const mapExistingEnrolledCoverages = () => {
        const enrolledCoverageData = memberElection.censusMember.benefits
            .filter(benefit => !benefit.benefitTerminationDate)
            .map(benefit => mapBenefitToEnrolledCoverageData(benefit))
            .filter(enrolledData => Boolean(enrolledData))
            .reduce((allData, currentItem) => {
                return {
                    enrolledCoverages: allData.enrolledCoverages.concat(currentItem.enrolledCoverages),
                    dependents: currentItem.familyDemographics
                        ? combineDependentsAndCoverages(allData.dependents, currentItem.familyDemographics.dependents)
                        : allData.dependents
                };
            }, {enrolledCoverages: [], dependents: []});

        if (enrolledCoverageData.enrolledCoverages.length > 0) {
            dispatch({
                type: 'UPDATE_MEMBER_ELECTION',
                data: {
                    enrolledCoverages: enrolledCoverageData.enrolledCoverages,
                    familyDemographics: {
                        dependents: enrolledCoverageData.dependents
                    }
                }
            });
        }
    };

    const mapBenefitToEnrolledCoverageData = (benefit) => {
        if (benefit.product.productType === ACCIDENT && benefit.product.employeeProduct) {
            return tryToMapBenefit(mapExistingAccidentCoverage, benefit);
        }
        if (benefit.product.productType === DENTAL && benefit.product.employeeProduct) {
            return tryToMapBenefit(mapExistingDentalCoverage, benefit);
        }
        if (benefit.product.productType === VISION && benefit.product.employeeProduct) {
            return tryToMapBenefit(mapExistingVisionCoverage, benefit);
        }
        if ([HOSPITAL_INDEMNITY_ENSPACEN, VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN].includes(benefit.product.productType) && benefit.product.employeeProduct) {
            return tryToMapBenefit(mapExistingHospitalIndemnityCoverage, benefit);
        }
        if (benefit.product.productType === CRITICAL_ILLNESS_ENSPACEN) {
            return tryToMapBenefit(mapExistingCriticalIllnessCoverage, benefit);
        }
        if (benefit.product.productType === VOLUNTARY_TERM_LIFE_ENSPACEN) {
            return tryToMapBenefit(mapExistingVoluntaryTermLifeCoverage, benefit);
        }
        if (benefit.product.productType === GROUP_TERM_LIFE_ENSPACEN) {
            return tryToMapBenefit(mapExistingGroupTermLifeCoverage, benefit);
        }
        if (benefit.product.productType === LONG_TERM_DISABILITY_DASHED) {
            return tryToMapBenefit(mapExistingLongTermDisabilityCoverage, benefit);
        }
        if (benefit.product.productType === SHORT_TERM_DISABILITY_DASHED) {
            return tryToMapBenefit(mapExistingShortTermDisabilityCoverage, benefit);
        }
    };

    const tryToMapBenefit = (mapperFunction, benefit) => {
        try {
            return mapperFunction(benefit);
        } catch (error) {
            console.log(error);
        }
    };

    return () => {
        if (memberElection.censusMember.privacyId && memberElection.enrolledCoverages.length === 0) {
            mapExistingEnrolledCoverages();
        }
    };
};