import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import Button from '../common/form/Button';
import {useKeypress} from '../../hook/effect/useKeypress';
import {useAuthService} from '../../provider/AuthProvider';
import {useAddressReviewEffect} from '../../hook/effect/useAddressReviewEffect';
import Spinner from '../common/Spinner';
import {pushCallToAction} from '../../utils/googleAnalyticsUtil';

export const INVALID_ADDRESS_MESSAGE = 'We were unable to verify the address you provided.';
export const SUGGESTED_ADDRESS_MESSAGE = 'Verify your address.';

const AddressReview = (props) => {
    const authService = useAuthService();
    const memberElection = useSelector(memberElectionSelector);

    const [state, setState] = React.useState({
        loading: true,
        showSuggestedAddressReview: false,
        suggestedAddressSelected: true,
        suggestedAddress: {}
    });

    useAddressReviewEffect(props, memberElection, setState, authService);

    useKeypress('Enter',
        () => saveAndConfirm(props, memberElection.memberDemographics, state),
        [props, memberElection, state]
    );

    return <div className='container'>
        {state.loading
            ? <div className='col-md-8 panel'>
                <div className='panel-heading-dashboard'>Validating your address.</div>
                <div className='panel-body'>
                    <Spinner/>
                </div>
                <br/>
            </div>
            : <div id='addressReviewSection' className='col-md-8 form-group form-group-sm panel'>
                <div className='panel-heading-dashboard'>
                    {state.showSuggestedAddressReview
                        ? SUGGESTED_ADDRESS_MESSAGE
                        : INVALID_ADDRESS_MESSAGE}
                </div>
                <div className='panel-body'>
                    <div>
                        <div id='addressReviewSubmittedAddressDiv' className='col-md-6'>
                            <div style={{
                                border: '1px solid #616267',
                                borderRadius: '8px',
                                padding: '20px 20px'
                            }}>
                                <div style={{textAlign: 'center'}}>
                                    <label
                                        id='addressReviewSubmittedAddressLabel'
                                        style={{fontFamily: 'FS Elliot Web, FS Elliot Web Regular, Arial, arial, sans-serif'}}
                                    >
                                        <h5>Your entry</h5>
                                        <p>{getAddressLineData(memberElection.memberDemographics)}</p>
                                        <p>{`${memberElection.memberDemographics.city.toUpperCase()}, ${memberElection.memberDemographics.state.toUpperCase()} ${memberElection.memberDemographics.zipCode}`}</p>
                                        <input
                                            id='addressReviewSubmittedAddressRadioButton'
                                            type='radio'
                                            style={{height: '20px', width: '20px'}}
                                            checked={!state.suggestedAddressSelected}
                                            onClick={() => setState({...state, suggestedAddressSelected: false})}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {state.showSuggestedAddressReview
                        && <div id='addressReviewSuggestedAddressDiv' className='col-md-6'>
                            <div style={{
                                border: '1px solid #616267',
                                borderRadius: '8px',
                                padding: '20px 20px'
                            }}>
                                <div style={{textAlign: 'center'}}>
                                    <label
                                        id='addressReviewSuggestedAddressLabel'
                                        style={{fontFamily: 'FS Elliot Web, FS Elliot Web Regular, Arial, arial, sans-serif'}}>
                                        <h5>Suggested address</h5>
                                        <p>{getAddressLineData(state.suggestedAddress)}</p>
                                        <p>{`${state.suggestedAddress.city.toUpperCase()}, ${state.suggestedAddress.state.toUpperCase()} ${state.suggestedAddress.zipCode}`}</p>
                                        <input
                                            id='addressReviewSuggestedAddressRadioButton'
                                            type='radio'
                                            style={{height: '20px', width: '20px'}}
                                            checked={state.suggestedAddressSelected}
                                            onClick={() => setState({...state, suggestedAddressSelected: true})}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>}
        <div id='addressReviewNavigationButtonsDiv' className='row col-md-12'>
            <hr className='dotted'/>
            <Button
                next
                onClick={() => {
                    pushCallToAction('Next');
                    saveAndConfirm(props, memberElection.memberDemographics, state);
                }}
            >
                {'Next '}
            </Button>
            <Button
                className='pull-left'
                back
                onClick={() => {
                    pushCallToAction('Back');
                    props.onCancel();
                }}
            >
                {' Back'}
            </Button>
        </div>
    </div>;
};

const getAddressLineData = (address) => {
    return Boolean(address.addressLineTwo)
        ? `${address.addressLineOne.toUpperCase()} ${address.addressLineTwo.toUpperCase()}`
        : address.addressLineOne.toUpperCase();
};

const saveAndConfirm = (props, memberDemographics, state) => {
    if (state.suggestedAddressSelected && Boolean(state.suggestedAddress.addressLineOne)) {
        props.save({
            memberDemographics: {
                ...memberDemographics,
                addressLineOne: state.suggestedAddress.addressLineOne,
                addressLineTwo: state.suggestedAddress.addressLineTwo,
                city: state.suggestedAddress.city,
                state: state.suggestedAddress.state,
                zipCode: state.suggestedAddress.zipCode
            }
        });
    }
    props.onConfirm();
};

export default AddressReview;