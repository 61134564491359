import React from 'react';

export const CaliforniaDisclosure = () => (
    <div>
        <p> This is a disclosure and
            consent to receive certain written records electronically from Principal Life Insurance Company. By
            indicating your
            acceptance, you are agreeing that you affirmatively consent to receive certain records in an
            electronic format.
        </p>
        <p>
            <b>Scope of Consent - </b>
            Your consent applies to all written records required to be given or mailed to you by Principal
            Life Insurance Company, including offers of renewal, notices of policy change or cancellation,
            offers of coverage,
            any required disclosure and routine policy communications relating to claims, underwriting or
            administration. Your
            consent also includes transactions conducted through our website and documents signed
            electronically. If the law of
            the state of California does not permit electronic delivery of specific types of documents, you will
            receive such
            documents in paper form only.
        </p>
        <p>
            <b>Consent is voluntary - </b>
            You are not required to receive the named documents electronically. If you wish to receive
            and/or sign paper documents, you can decline to provide your consent to this disclosure.
        </p>
        <p>
            <b>Right to Withdraw Consent - </b>
            Once you provide your consent to receive the named documents electronically, you
            will have the right at any time to withdraw your consent. You must provide us with notice of your
            desire to withdraw
            your consent. The instructions for notifying us are below under the heading "Contact Information."
            Once we have
            received your notice to withdraw consent, it will be made effective as soon as reasonably possible.
            Once your
            consent is withdrawn, you will receive documents covered by this consent in paper form.
        </p>
        <p>
            <b>Updated Information – </b>
            Some records may be delivered electronically according to contact information you provide.
            If the information needed to contact you electronically should ever change, you must notify us of
            the change and
            provide updated information. The instructions for notifying us of updated contact information are
            below under the
            heading "Contact Information."
        </p>
        <p>
            <b>Computer Hardware and Software Requirements - </b>
            You will need access to a computer with a current internet
            browser which supports the HTTPS protocol, HTML, and acceptance of cookies in your security
            settings. You will
            need an operating system that allows you to save files or print web pages and documents. You will
            need Adobe
            Reader or similar software to view and retain documents in PDF format. If we should ever have a
            change in the
            hardware or software requirements needed to access or retain documents electronically, we will
            advise you of the
            revised hardware and software requirements.
        </p>
        <p>
            <b>Right to obtain paper copies - </b>
            You will have the ability to download and print any documents we send or make
            available to you electronically. You may also request delivery of paper copies by contacting us as
            outlined in the
            "Contact Information" section below. We will provide paper copies at your request, free of charge,
            on an annual
            basis.
        </p>
        <p>
            <b>Contact Information - </b>
            Please use one of the following methods to contact us to withdraw your consent to receive
            the named documents electronically, request a free paper copy of electronically delivered documents
            annually, or
            report a change in your email address:
        </p>
        <ul>
            <li>Email: <a href='mailto:groupbenefitsadmin@principal.com'>
                groupbenefitsadmin@principal.com
            </a></li>
            <li>Telephone: 1-800-843-1371 Ext 57025</li>
            <li>Paper: Principal Life Insurance Company, [Attn: SBD Administration, Des Moines, IA 50392-0002]</li>
            <li>On the Company's website at: www.principal.com</li>
        </ul>
        <p>
            <b>Agreement - </b>
            By consenting to receive the named insurance documents electronically, you understand and agree
            that you are able to access and read this consent and disclosure electronically and also were able
            to print it on
            paper or electronically save it for your future reference and access. Until or unless you notify us
            as described
            above, you consent to receive from us through electronic means the documents identified in this
            consent
            agreement.
        </p>
        <p>
            <b>Employee Agreement and Disclaimers</b> (Please read)
        </p>
        <p>
            <b>NOTE:</b>
            The state where your employer's group policy is issued will be used to determine the
            applicability of the following statements to you and/or your dependents who enroll
            for coverage.
        </p>
        <ul>
            I understand and agree with the following statements
            <li>
                If the group policy requires my contribution, I authorize my employer to
                deduct from my pay.
            </li>
            <li>
                If coverage was previously declined due to not meeting the necessary health
                underwriting guidelines, I or my spouse are not eligible to reapply for coverage
                without current proof of good health.
                <p className='util-margin-left-15'>
                    <b>For Maryland:</b>
                    If coverage was previously declined due to not meeting the necessary health
                    underwriting guidelines, I or my spouse are not eligible to reapply for
                    coverage without current evidence of insurability.
                </p>
            </li>
            <li>
                I represent all information in this enrollment is complete and true to the best
                of my knowledge. It is part of this request for coverage. I agree Principal
                Life Insurance Company is not liable for a claim before the effective date of
                coverage and all policy provisions apply. I have read, or had read to me, the
                information and my answers in this enrollment. During the first two years
                coverage is in force, fraud or intentional misrepresentations can cause changes
                in my coverage, including cancellation back to the effective date.
                <p className='util-margin-left-15'>
                    <b>For Florida:</b>
                    I represent all information in this enrollment is complete and true to the
                    best of my knowledge. It is part of this request for coverage. I agree
                    Principal Life Insurance Company is not liable for a claim before the
                    effective date of coverage and all policy provisions apply. I have read, or
                    had read to me, the information and my answers in this enrollment. During
                    the first two years coverage is in force, misrepresentations contained in
                    writing in this document can cause changes in my coverage, including
                    cancellation back to the effective date.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Kentucky:</b>
                    I represent all information in this enrollment is complete and true to the
                    best of my knowledge. It is part of this request for coverage. All
                    statements by or on behalf of the insured shall be deemed to be
                    representations and not warranties. I agree Principal Life Insurance Company
                    is not liable for a claim before the effective date of coverage and all
                    policy provisions apply. I have read, or have read to me, the information
                    and my answers in this enrollment. During the first two years coverage is in
                    force, any intentional misrepresentations of material fact can cause changes
                    in my coverage, including cancellation back to the effective date.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Maryland:</b>
                    I represent all information in this enrollment is complete and true to the
                    best of my knowledge. It is part of this request for coverage. I agree
                    Principal Life Insurance Company is not liable for a claim before the
                    effective date of coverage and all policy provisions apply. I have read, or
                    had read to me, the information and my answers in this enrollment.
                    Statements made to effectuate insurance cannot be used to contest or reduce
                    my insurance unless the statement is in writing and signed by me and a copy
                    of the form containing the statement is given to me or my beneficiary at the
                    time insurance is contested.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Virginia:</b>
                    I represent all information on in this enrollment is complete and true to
                    the best of my knowledge. It is part of this request for coverage. I agree
                    Principal Life Insurance Company is not liable for a claim before the
                    effective date of coverage and all policy provisions apply. I certify that I
                    have read, or had read to me, the information and my answers in this
                    enrollment. During the first two years coverage is in force, fraud or
                    intentional misrepresentations can cause changes in my coverage, including
                    cancellation back to the effective date.
                </p>
            </li>
            <li>
                Any person who, with intent to defraud or knowing that he or she is
                facilitating a fraud against an insurer, submits an application or files a claim
                containing a false or deceptive statement, may be guilty of insurance fraud.
                <p className='util-margin-left-15'>
                    <b>For Alabama:</b>
                    Any person who knowingly presents a false or fraudulent claim for payment of
                    a loss or benefit or who knowingly presents false information in an
                    application for insurance is guilty of a crime and may be subject to
                    restitution fines or confinement in prison, or any combination thereof.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Arizona:</b>
                    Any person who, with intent to defraud or knowing that he or she is
                    facilitating a fraud against an insurer, submits an application or files a
                    claim containing a false or deceptive statement, may be guilty of insurance
                    fraud.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Arkansas:</b>
                    Any person who knowingly presents a false or fraudulent claim for payment of
                    a loss or benefit or knowingly presents false information in an application
                    for insurance is guilty of a crime and may be subject to fines and
                    confinement in prison.
                </p>
                <p className='util-margin-left-15'>
                    <b>For California:</b>
                    Any false statement made in this enrollment will not bar the right to
                    recovery under the group policy(ies) unless such false statement was made
                    with actual intent to deceive or unless it materially affected either the
                    acceptance of the risk or the hazard assumed by Principal Life.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Colorado:</b>
                    It is unlawful to knowingly provide false, incomplete, or misleading facts
                    or information to an insurance company for the purpose of defrauding or
                    attempting to defraud the company. Penalties may include imprisonment,
                    fines, denial of insurance, and civil damages. Any insurance company or
                    agent of an insurance company who knowingly provides false, incomplete, or
                    misleading facts or information to a policyholder or claimant for the
                    purpose of defrauding or attempting to defraud the policyholder or claimant
                    with regard to a settlement or award payable from insurance proceeds shall
                    be reported to the Colorado Division of Insurance within the Department of
                    Regulatory Agencies.
                </p>
                <p className='util-margin-left-15'>
                    <b>For District of Columbia:</b>
                    Warning: It is a crime to provide false or misleading information to an
                    insurer for the purpose of defrauding the insurer or any other person.
                    Penalties include imprisonment and/or fines. In addition, an insurer may
                    deny insurance benefits if false information materially related to a claim
                    was provided by the applicant.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Kentucky:</b>
                    Any person who knowingly and with intent to defraud any insurance company or
                    other person files an application for insurance containing any materially
                    false information or conceals, for the purpose of misleading, information
                    concerning any fact material thereto commits a fraudulent insurance act,
                    which is a crime.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Louisiana:</b>
                    Any person who knowingly presents a false or fraudulent claim for payment of
                    a loss or benefit or knowingly presents false information in an application
                    for insurance is guilty of a crime and may be subject to fines and
                    confinement in prison.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Maine:</b>
                    It is a crime to knowingly provide false, incomplete or misleading
                    information to an insurance company for the purpose of defrauding the
                    company. Penalties may include imprisonment, fines or a denial of insurance
                    benefits.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Maryland:</b>
                    Any person who knowingly or willfully presents a false or fraudulent claim
                    for payment of a loss or benefit or who knowingly or willfully presents
                    false information in an application for insurance is guilty of a crime and
                    may be subject to fines and confinement in prison.
                </p>
                <p className='util-margin-left-15'>
                    <b>For New Jersey:</b>
                    Any person who includes any false or misleading information on an
                    application for an insurance policy is subject to criminal and civil
                    penalties.
                </p>
                <p className='util-margin-left-15'>
                    <b>For New Mexico:</b>
                    Any person who knowingly presents a false or fraudulent claim for payment of
                    a loss or benefit or knowingly presents false information.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Ohio:</b>
                    Any person who, with intent to defraud or knowing that he or she is
                    facilitating a fraud against an insurer, submits an application or files a
                    claim containing a false or deceptive statement, is guilty of insurance
                    fraud.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Oklahoma:</b>
                    WARNING: Any person who knowingly, and with intent to injure, defraud or
                    deceive any insurer, makes any claim for the proceeds of an insurance policy
                    containing any false, incomplete or misleading information is guilty of a
                    felony.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Pennsylvania:</b>
                    Any person who knowingly and with intent to defraud any insurance company or
                    other person files an application for insurance or statement of claim
                    containing any materially false information or conceals for the purpose of
                    misleading, information concerning any fact material thereto commits a
                    fraudulent insurance act, which is a crime and subjects such person to
                    criminal and civil penalties.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Tennessee:</b>
                    It is a crime to knowingly provide false, incomplete or misleading
                    information to an insurance company for the purposes of defrauding the
                    company. Penalties include imprisonment, fines and denial of coverage.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Virginia:</b>
                    Any person who, with the intent to defraud or knowing that he or she is
                    facilitating a fraud against an insurer, submits an application or files a
                    claim containing a false or deceptive statement may have violated state law.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Washington:</b>
                    It is a crime to knowingly provide false, incomplete, or misleading
                    information to an insurance company for the purpose of defrauding the
                    company. Penalties include imprisonment, fines, and denial of insurance
                    benefits.
                </p>
            </li>
            <li>
                I authorize Principal Life Insurance Company to release data as required by
                law. If signed in connection with an application, reinstatement or a change in
                benefits, this enrollment will be valid two years from the date signed. I may
                revoke authorization for information not yet obtained. I understand data
                obtained will be used by Principal Life Insurance Company for claims
                administration and determining eligibility for life coverage. Information will
                not be used for any purposes prohibited by law. (this bullet is not applicable
                in Virginia)
                <p className='util-margin-left-15'>
                    <b>For Arizona:</b>
                    I authorize Principal Life Insurance Company to release data as required by
                    law. When signed in connection with any application for, reinstatement of,
                    or request for change in benefits, from the date shown below, this
                    enrollment shall be valid for two years for all information except Human
                    Immunodeficiency Virus (HIV) information for which the enrollment shall be
                    valid for 180 days.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Louisiana:</b>
                    I authorize Principal Life Insurance Company to release data as required by
                    law. If signed in connection with an application, reinstatement or a change
                    in benefits, this enrollment will be valid two years from the date signed or
                    until the policyholder has cancelled this policy or until the insured is no
                    longer covered under this policy. I may revoke authorization for information
                    not yet obtained. I understand data obtained will be used by Principal Life
                    Insurance Company for claims administration and determining eligibility for
                    life coverage. Information will not be used for any purposes prohibited by
                    law.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Maine:</b>
                    I authorize Principal Life Insurance Company to release data as required by
                    law. If signed in connection with an application, reinstatement or a change
                    in benefits, this enrollment will be valid two years from the date signed. I
                    understand data obtained will be used by Principal Life Insurance Company
                    for claims administration and determining eligibility for life coverage.
                    Information will not be used for any purposes prohibited by law. I may
                    revoke authorization for information at any time by providing my request in
                    writing to Principal Life Insurance Company. I understand that failure to
                    sign this authorization may impair the ability of Principal Life Insurance
                    Company to evaluate claims or process applications and may be a basis for
                    denying an applicant for claim for benefits.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Minnesota:</b>
                    I authorize Principal Life Insurance Company to release data as required by
                    law. If signed in connection with an application, reinstatement or a change
                    in benefits, this enrollment will be valid 26 months from the date signed. I
                    may revoke authorization for information not yet obtained. I understand data
                    obtained will be used by Principal Life Insurance Company for claims
                    administration and determining eligibility for life coverage. Information
                    will not be used for any purposes prohibited by law.
                </p>
            </li>
            <li>
                I understand that as the employee, the insurance I and my dependents have
                applied for will begin on the effective date of coverage provided I am at work
                on that date. If I am not actively at work on such date, subject to the terms of
                the group policy, coverage may not go into effect until after my return to work.
                Furthermore, I understand that no insurance may become effective for any member
                of my family while he/she is in a period of limited activity.
                <p className='util-margin-left-15'>
                    <b>For Kentucky:</b>
                    The information I have provided on this enrollment may be altered only by me
                    or with my written consent.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Louisiana:</b>
                    YOUR RIGHTS REGARDING THE RELEASE AND USE OF GENETIC INFORMATION: We do not
                    request nor use genetic testing and related genetic test information from
                    you or your dependents.
                </p>
                <p className='util-margin-left-15'>
                    <b>For Washington:</b>
                    I understand that as the employee, the insurance I and my dependents have
                    applied for will begin on the effective date of coverage provided I am at
                    work on that date. If I am not actively at work on such date, subject to the
                    terms of the group policy, coverage may not go into effect until after my
                    return to work. Furthermore, for dependent group term life or voluntary term
                    life, I understand that no insurance may become effective for any member of
                    my family while he/she is confined in a hospital or skilled nursing facility
                    or home confined."
                </p>
            </li>
        </ul>
        <p>
            <b>For Maine:</b>
            You or an authorized representative have a right to receive a copy of this
            authorization.
        </p>
        <p>
            <b>For Virginia:</b>
            Upon written request, Principal Life Insurance Company will furnish to you (or
            your dependent) or authorized representative, a copy of this enrollment.
        </p>
        <p>A copy of this enrollment will be as valid as the original.</p>
        <div>
            I declare that the information I have completed on this enrollment form is
            complete and true. I understand an agent or broker cannot guarantee coverage,
            revise rates, benefits or provisions without written approval from Principal
            Life Insurance Company.
            <p className='util-margin-left-15'>
                <b>For Florida, Maryland, New Hampshire:</b>
                I declare that the information I have completed on this enrollment form is
                complete and true to the best of my knowledge and belief. I understand an agent
                or broker cannot guarantee coverage, revise rates, benefits or provisions
                without written approval from Principal Life Insurance Company.
            </p>
            <p className='util-margin-left-15'>
                <b>For Kentucky, Minnesota:</b>
                I represent that the information I have completed on this enrollment form is
                complete and true. I understand an agent or broker cannot guarantee coverage,
                revise rates, benefits or provisions without written approval from Principal
                Life Insurance Company.
            </p>
            <p className='util-margin-left-15'>
                <b>For Missouri, Washington:</b>
                I declare that the information I have completed on this enrollment form is
                complete and true. I understand an insurance producer or broker cannot guarantee
                coverage, revise rates, benefits or provisions without written approval from
                Principal Life Insurance Company.
            </p>
            <p className='util-margin-left-15'>
                <b>For New Jersey:</b>
                To the best of my knowledge and belief, I declare that the information I have
                completed on this enrollment form is complete and true. I understand an agent or
                broker cannot guarantee coverage, revise rates, benefits or provisions without
                written approval from Principal Life Insurance Company.
            </p>
        </div>
    </div>
);