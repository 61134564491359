import React from 'react';

const FormInfo = (props) => {
    return (
        <div style={props.display ? {textAlign:'center'} : {textAlign:'center',display: 'none'}}
             className='alert alert-info alert-icon col-md-12' role='alert'>
            {props.infoText}
        </div>
    );
};

export default FormInfo;
