import React from 'react';
import {getAddressValidationData} from '../../repository/AddressValidationRepository';

export const useAddressReviewEffect = (props, memberElection, setState, authService) => {
    React.useEffect(() => {
        const getAddressReview = async () => {
            try {
                const {addressLineOne, addressLineTwo, city, state, zipCode} = memberElection.memberDemographics;
                const accessToken = await authService.getAccessToken();
                const memberAddress = {
                    addressLineOne,
                    addressLineTwo: addressLineTwo ? addressLineTwo : '',
                    city,
                    state,
                    zipCode,
                };
                const result = await getAddressValidationData(accessToken, memberAddress, authService.isOkta);

                if (Boolean(result.addressLineOne)) {
                    if (result.addressLineOne.toUpperCase() === addressLineOne.toUpperCase()
                        && result.city === city
                        && result.state === state
                        && result.zipCode === zipCode) {
                        props.onConfirm();
                    } else {
                        setState({
                            loading: false,
                            showSuggestedAddressReview: true,
                            suggestedAddressSelected: true,
                            suggestedAddress: result
                        });
                    }
                } else {
                    setState({
                        loading: false,
                        showSuggestedAddressReview: false,
                        suggestedAddressSelected: false,
                    });
                }
            } catch (error) {
                props.onConfirm();
            }
        };
        getAddressReview();
    }, [props, memberElection, setState, authService]);
};