import axios from 'axios';
import {getBaseUrl} from './UtilRepository';

export const getAddressValidationData = async (accessToken, data, isOkta) => {
    const url = await getBaseUrl(isOkta);
    const response = await axios.post(
        `${url}/addressValidation`,
        data,
        {headers: {Authorization: `Bearer ${accessToken}`}}
    );
    return response.data;
};