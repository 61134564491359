import {isChild, isPartner} from '../../properties/relationships';
import {addCoverageToDependent, removeDependentCoverage, removeExclusionKey} from '../../utils/dependentCoverageUtil';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';

const FAMILY_TIERS = ['FOUR_TIER_PLUS_DEPENDENT_AND_SPOUSE', 'THREE_TIER_PLUS_TWO_OR_MORE', 'TWO_TIER'];

export const useDependentTierPopulator = () => {
    const memberElection = useSelector(memberElectionSelector);

    const populateDependents = (selectedRate, rateSelectorCoverage, exclusionKeyName) => {
        const dependents = memberElection.familyDemographics.dependents;

        if (selectedRate.tier === 'THREE_TIER_PLUS_ONE') {
            return dependents
                .map(dependent => populateDependentEnrolledCoverage(
                    dependent.guid === selectedRate.guid,
                    dependent,
                    rateSelectorCoverage,
                    exclusionKeyName
                ));
        } else if (selectedRate.tier === 'FOUR_TIER_SPOUSE') {
            return dependents
                .map(dependent => populateDependentEnrolledCoverage(
                    isPartner(dependent.relationship),
                    dependent,
                    rateSelectorCoverage,
                    exclusionKeyName
                ));
        } else if (selectedRate.tier === 'FOUR_TIER_PLUS_DEPENDENT_NO_SPOUSE') {
            return dependents
                .map(dependent =>
                    populateDependentEnrolledCoverage(
                        isChild(dependent.relationship),
                        dependent,
                        rateSelectorCoverage,
                        exclusionKeyName
                    ));
        } else if (FAMILY_TIERS.includes(selectedRate.tier)) {
            return dependents
                .map(dependent => {
                    const dependentWithoutExclusionKey = removeExclusionKey(dependent, exclusionKeyName);
                    return addCoverageToDependent(dependentWithoutExclusionKey, rateSelectorCoverage.dependentBenefitKey);
                });
        } else {
            return dependents
                .map(dependent => {
                    const dependentWithoutExclusionKey = removeExclusionKey(dependent, exclusionKeyName);
                    return removeDependentCoverage(dependentWithoutExclusionKey, rateSelectorCoverage.dependentBenefitKey);
                });
        }
    };

    const populateDependentEnrolledCoverage = (shouldRemoveExclusionKey, dependent, rateSelectorCoverage, exclusionKeyName) => {
        const dependentWithoutExclusionKey = removeExclusionKey(dependent, exclusionKeyName);
        return shouldRemoveExclusionKey
            ? addCoverageToDependent(dependentWithoutExclusionKey, rateSelectorCoverage.dependentBenefitKey)
            : ({
                ...dependent,
                [exclusionKeyName]: rateSelectorCoverage.dependentBenefitKey,
                coverages: dependent.coverages
                    .filter(coverageBenefitKey => coverageBenefitKey !== rateSelectorCoverage.dependentBenefitKey)
            });
    };

    return (selectedRate, rateSelectorCoverage, exclusionKeyName) =>
        populateDependents(selectedRate, rateSelectorCoverage, exclusionKeyName);
};