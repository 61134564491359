import {DEPENDENT_VISION_SORT_CODES} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {visionRateSelector} from '../../redux/selectors/VisionRateSelector';
import {useVisionCoveragePopulator} from '../populator/useVisionCoveragePopulator';
import {getTierDisplayName} from '../../utils/mapper/coverageMapperUtil';

export const useVisionCoverageMapper = () => {
    const visionCoverage = useSelector(visionRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateVisionCoverage = useVisionCoveragePopulator();

    const map = () => {
        const rateDisplayName = getTierDisplayName(memberElection.censusMember.dependents, DEPENDENT_VISION_SORT_CODES, visionCoverage.rates);
        const currentRate = visionCoverage.rates.find(rate => rate.displayName === rateDisplayName);
        return populateVisionCoverage(currentRate);
    };

    return () => map();
};