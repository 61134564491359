import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {
    HOSPITAL_INDEMNITY_ENSPACEN,
    VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN,
    VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES
} from '../../properties/coverages';
import {useDependentTierPopulator} from './useDependentTierPopulator';
import {CONTRIBUTORY_TYPE, PLEASE_SEE_BENEFIT_SUMMARY} from '../../properties/benefitOptions';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';
import {getVHOSDisplayName} from '../../utils/hosUtil';

export const useHospitalIndemnityCoveragePopulator = () => {
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentTierPopulator();
    const hospitalIndemnityCoverages = useSelector(hospitalIndemnityRateSelector);

    const populateEnrolledCoverage = (selectedRate, coverage) => {
        const dependents = populateDependents(selectedRate, coverage, 'hospitalIndemnityBenefitExclusionKey');
        const enrolledCoverages = buildEnrolledCoverages(selectedRate, coverage);

        return {
            enrolledCoverages: enrolledCoverages,
            benefitOptions: memberElection.benefitOptions
                .filter(benefitOption => !benefitOption.labelAliasName.includes('MEDICAL_COVERAGE')),
            familyDemographics: {
                dependents: dependents
            },
        };
    };

    const getBenefit = (coverage) => {
        const benefitOption = coverage.benefitOptions.find(benefitOption => benefitOption.label === CONTRIBUTORY_TYPE);
        return benefitOption
            ? benefitOption.value
            : PLEASE_SEE_BENEFIT_SUMMARY;
    };

    const buildEnrolledCoverages = (selectedRate, coverage) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.productType !== HOSPITAL_INDEMNITY_ENSPACEN)
            .filter(enrolledCoverage => enrolledCoverage.productType !== VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN);

        enrolledCoverages.push({
            benefitKey: coverage.benefitKey,
            esclKey: coverage.esclKey,
            memberGroupKey: coverage.memberGroupKey,
            coverageName: VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES.includes(coverage.product.compassGroupSortCode)
                ? getVHOSDisplayName(hospitalIndemnityCoverages, coverage)
                : HOSPITAL_INDEMNITY_ENSPACEN,
            tier: selectedRate.tier,
            tierDisplayName: selectedRate.displayName,
            rate: selectedRate.rate,
            benefit: getBenefit(coverage),
            productType: coverage.product.productType
        });

        if (selectedRate.tier !== 'EMPLOYEE') {
            enrolledCoverages.push({
                benefitKey: coverage.dependentBenefitKey,
                memberGroupKey: coverage.memberGroupKey,
                coverageName: coverage.product.productType,
                dependent: true,
                productType: coverage.product.productType
            });
        }
        return enrolledCoverages;
    };

    return (selectedRate, coverage) => populateEnrolledCoverage(selectedRate, coverage);
};