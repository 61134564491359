import React from 'react';
import './style/App.css';
import {AuthProvider} from './provider/AuthProvider';
import Routes from './route/Routes';
import {BrowserRouter} from 'react-router-dom';
import {ErrorBoundary} from './ErrorBoundary';
import {pushPathChange} from './utils/googleAnalyticsUtil';


const App = ({authService, oktaAuthService, history}) => {

    pushPathChange('pageViewLoad', 'HOME', 'Home');

    return (
        <ErrorBoundary history={history}>
            <AuthProvider authService={authService} oktaAuthService={oktaAuthService}>
                <BrowserRouter history={history}>
                    <Routes/>
                </BrowserRouter>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default App;
