import React from 'react';

const OutOfEnrollment = () => {
    return <div className='container'>
            <div className='text-center'>
                <div>
                    <h1>We're sorry.</h1>
                </div>
                <div>
                    <p>Our records indicate you are not in Open Enrollment. Please contact your employer if you believe this is an error.</p>
                </div>
            </div>
            <img src='https://ux.principal.com/horizon/master/examplefiles/v2/stan-the-man.svg'/>
    </div>;
};

export default OutOfEnrollment;