import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {dentalRateSelector} from '../../redux/selectors/DentalRateSelector';
import FormError from '../common/warning/FormError';
import {coverageQuestionSelector} from '../../redux/selectors/CoverageQuestionSelector';
import CoverageQuestions, {MBR_MEDICAL_COVERAGE} from '../coverage/CoverageQuestions';
import {useKeypress} from '../../hook/effect/useKeypress';
import {visionRateSelector} from '../../redux/selectors/VisionRateSelector';
import {
    amountIsNotGreaterThanBenefitOptionAmount,
    COVERAGE_ERROR_MESSAGE,
    COVERAGE_QUESTION_ERROR_MESSAGE,
    percentOfSalaryAndWageZero
} from '../coverage/ElectionUtil';
import {accidentRateSelector} from '../../redux/selectors/AccidentRateSelector';
import AccidentElection from '../coverage/AccidentElection';
import UnavailableModal from '../common/modal/UnavailableModal';
import {criticalIllnessRateSelector} from '../../redux/selectors/CriticalIllnessRateSelector';
import {lowercaseFirstLetter} from '../../utils/stringUtil';
import {voluntaryTermLifeRateSelector} from '../../redux/selectors/VoluntaryTermLifeRateSelector';
import {groupTermLifeRateSelector} from '../../redux/selectors/GroupTermLifeRateSelector';

import {
    LTDINCR,
    MBR_SPVCIINCR,
    MBR_SPVTLINCR,
    STDINCR,
    VCIEEINCR,
    VLTDINCR,
    VSTDINCR,
    VTLCHILD,
    VTLEEINCR,
    VTLEESALMULT
} from '../../properties/benefitOptions';
import {shortTermDisabilityRateSelector} from '../../redux/selectors/ShortTermDisabilityRateSelector';
import {
    ACCIDENT,
    CRITICAL_ILLNESS,
    CRITICAL_ILLNESS_ENSPACEN,
    DENTAL,
    GROUP_TERM_LIFE,
    HOSPITAL_INDEMNITY,
    INCREMENT_PLAN_TYPE,
    LONG_TERM_DISABILITY,
    LONG_TERM_DISABILITY_ENSPACEN,
    NONE,
    SHORT_TERM_DISABILITY,
    SHORT_TERM_DISABILITY_ENSPACEN,
    VISION,
    VOLUNTARY_TERM_LIFE,
    VOLUNTARY_TERM_LIFE_ENSPACEN
} from '../../properties/coverages';
import {longTermDisabilityRateSelector} from '../../redux/selectors/LongTermDisabilityRateSelector';
import ElectionPanel from '../coverage/ElectionPanel';
import VisionElection from '../coverage/VisionElection';
import CriticalIllnessElection from '../coverage/CriticalIllnessElection';
import VoluntaryTermLifeElection from '../coverage/VoluntaryTermLifeElection';
import GroupTermLifeElection from '../coverage/GroupTermLifeElection';
import ShortTermDisabilityElection from '../coverage/ShortTermDisabilityElection';
import LongTermDisabilityElection from '../coverage/LongTermDisabilityElection';
import DentalElection from '../coverage/DentalElection';
import {benefitSummaryDataSelector} from '../../redux/selectors/BenefitSummaryDataSelector';
import ShoppingCart from '../common/ShoppingCart';
import HospitalIndemnityElection from '../coverage/HospitalIndemnityElection';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';
import ListUtil from '../../utils/listUtil';

const BenefitElection = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const coverageQuestions = useSelector(coverageQuestionSelector);
    const accidentCoverage = useSelector(accidentRateSelector);
    const criticalIllnessCoverage = useSelector(criticalIllnessRateSelector);
    const groupTermLifeCoverage = useSelector(groupTermLifeRateSelector);
    const shortTermDisabilityCoverage = useSelector(shortTermDisabilityRateSelector);
    const longTermDisabilityCoverage = useSelector(longTermDisabilityRateSelector);
    const voluntaryTermLifeCoverage = useSelector(voluntaryTermLifeRateSelector);
    const dentalCoverages = useSelector(dentalRateSelector);
    const visionCoverage = useSelector(visionRateSelector);
    const hospitalIndemnityCoverages = useSelector(hospitalIndemnityRateSelector);
    const benefitSummaryData = useSelector(benefitSummaryDataSelector);
    const dispatch = useDispatch();

    const coverages = React.useMemo(() => ({
            accidentCoverage: accidentCoverage,
            criticalIllnessCoverage: criticalIllnessCoverage,
            groupTermLifeCoverage: groupTermLifeCoverage,
            voluntaryTermLifeCoverage: voluntaryTermLifeCoverage,
            dentalCoverages: dentalCoverages,
            visionCoverage: visionCoverage,
            hospitalIndemnityCoverages: hospitalIndemnityCoverages,
            shortTermDisabilityCoverage: shortTermDisabilityCoverage,
            longTermDisabilityCoverage: longTermDisabilityCoverage
        }), [accidentCoverage,
            criticalIllnessCoverage,
            groupTermLifeCoverage,
            voluntaryTermLifeCoverage,
            dentalCoverages,
            visionCoverage,
            hospitalIndemnityCoverages,
            shortTermDisabilityCoverage,
            longTermDisabilityCoverage]
    );

    const [state, setState] = React.useState({
        selectedCoverage: NONE,
        accidentElectionIsValid: true,
        criticalIllnessElectionIsValid: true,
        groupTermLifeElectionIsValid: true,
        voluntaryTermLifeElectionIsValid: true,
        dentalElectionIsValid: true,
        visionElectionIsValid: true,
        hospitalIndemnityElectionIsValid: true,
        shortTermDisabilityElectionIsValid: true,
        longTermDisabilityElectionIsValid: true,
        coverageQuestionsIsValid: true,
        healthBenefitsQuestionIsValid: true
    });

    useKeypress('Enter',
        () => saveAndConfirm(memberElection, coverages, coverageQuestions, props, state, setState),
        [memberElection, coverages, coverageQuestions, props, state]
    );

    const addBenefitOption = (benefitKey, amount, labelAliasName) => {
        memberElection.benefitOptions = [
            ...memberElection.benefitOptions.filter(benefitOption => benefitOption.benefitKey !== benefitKey
                || benefitOption.labelAliasName !== labelAliasName),
            {
                benefitKey,
                labelAliasName,
                benefitOptionData: amount
            }
        ];
    };

    const addBenefitOptionIsNotPresent = (benefitKey) => {
        return !memberElection.benefitOptions.find(benefitOption => benefitOption.benefitKey === benefitKey);
    };

    const addBenefitOptionAmountIncorrect = (benefitKey, amount) => {
        return memberElection.benefitOptions.find(benefitOption => benefitOption.benefitKey === benefitKey).amount !== amount;
    };

    const addAmountBenefitOptions = (coverageName) => {
        const employeeCoverage = memberElection.enrolledCoverages
            .find(coverage => coverage.coverageName === coverageName && coverage.tierDisplayName === 'You');
        const {
            employeeLabelAliasName,
            spouseLabelAliasName,
            childLabelAliasName,
            employeeAmount
        } = getCoverageDependentData(coverageName, employeeCoverage);
        if (shouldAddBenefitOption(employeeCoverage)) {
            addBenefitOption(employeeCoverage.benefitKey, employeeAmount, employeeLabelAliasName);
            const spouseCoverage = memberElection.enrolledCoverages
                .find(coverage => coverage.coverageName === coverageName && coverage.tierDisplayName === 'Spouse');
            const childCoverage = memberElection.enrolledCoverages
                .find(coverage => coverage.coverageName === coverageName && coverage.tierDisplayName === 'Child(ren)');
            if (shouldAddBenefitOption(spouseCoverage)) {
                addBenefitOption(spouseCoverage.benefitKey, spouseCoverage.amount, spouseLabelAliasName);
            }
            if (shouldAddBenefitOption(childCoverage) && coverageName !== CRITICAL_ILLNESS_ENSPACEN) {
                addBenefitOption(childCoverage.benefitKey, childCoverage.amount, childLabelAliasName);
            }
        }
    };

    const shouldAddBenefitOption = (coverage) => {
        return coverage && (addBenefitOptionIsNotPresent(coverage.benefitKey)
            || addBenefitOptionAmountIncorrect(coverage.benefitKey, coverage.amount));
    };

    const getCoverageDependentData = (coverageName, coverage) => {
        if (coverageName === VOLUNTARY_TERM_LIFE_ENSPACEN) {
            if (coverage && coverage.percentOfSalary) {
                return {
                    employeeLabelAliasName: VTLEESALMULT,
                    spouseLabelAliasName: MBR_SPVTLINCR,
                    childLabelAliasName: VTLCHILD,
                    employeeAmount: coverage && coverage.percentOfSalary
                };
            }
            return {
                employeeLabelAliasName: VTLEEINCR,
                spouseLabelAliasName: MBR_SPVTLINCR,
                childLabelAliasName: VTLCHILD,
                employeeAmount: coverage && coverage.amount
            };
        }
        if (coverage && coverageName === SHORT_TERM_DISABILITY_ENSPACEN) {
            return {
                employeeLabelAliasName: coverage.voluntary ? VSTDINCR : STDINCR,
                employeeAmount: coverage && coverage.amount
            };
        }
        if (coverage && coverageName === LONG_TERM_DISABILITY_ENSPACEN) {
            return {
                employeeLabelAliasName: coverage.voluntary ? VLTDINCR : LTDINCR,
                employeeAmount: coverage && coverage.amount
            };
        }

        return {
            employeeLabelAliasName: VCIEEINCR,
            spouseLabelAliasName: MBR_SPVCIINCR,
            employeeAmount: coverage && coverage.amount
        };
    };

    const unavailableOnCloseHandler = () => {
        dispatch({type: 'UPDATE_BENEFIT_SUMMARY_DATA', data: {error: false}});
    };

    const saveAndConfirm = () => {
        if (validatePage(memberElection, coverages, coverageQuestions)) {
            addAmountBenefitOptions(CRITICAL_ILLNESS_ENSPACEN);
            addAmountBenefitOptions(VOLUNTARY_TERM_LIFE_ENSPACEN);
            if (Boolean(shortTermDisabilityCoverage) && shortTermDisabilityCoverage.planType === INCREMENT_PLAN_TYPE) {
                addAmountBenefitOptions(SHORT_TERM_DISABILITY_ENSPACEN);
            }
            if (Boolean(longTermDisabilityCoverage) && longTermDisabilityCoverage.planType === INCREMENT_PLAN_TYPE) {
                addAmountBenefitOptions(LONG_TERM_DISABILITY_ENSPACEN);
            }
            props.save({
                enrolledCoverages: memberElection.enrolledCoverages,
                benefitOptions: memberElection.benefitOptions,
                familyDemographics: {
                    dependents: memberElection.familyDemographics.dependents
                }
            });
            props.onConfirm();
        } else {
            setState({
                ...state,
                accidentElectionIsValid: validateCoverageElection(memberElection, coverages.accidentCoverage),
                criticalIllnessElectionIsValid: validateIncrementElection(memberElection, coverages.criticalIllnessCoverage),
                groupTermLifeElectionIsValid: validateCoverageElection(memberElection, coverages.groupTermLifeCoverage),
                voluntaryTermLifeElectionIsValid: validateIncrementElection(memberElection, coverages.voluntaryTermLifeCoverage),
                dentalElectionIsValid: validateMultipleCoverageElection(memberElection, coverages.dentalCoverages),
                visionElectionIsValid: validateCoverageElection(memberElection, coverages.visionCoverage),
                hospitalIndemnityElectionIsValid: validateMultipleCoverageElection(memberElection, coverages.hospitalIndemnityCoverages),
                shortTermDisabilityElectionIsValid: validateCoverageElection(memberElection, coverages.shortTermDisabilityCoverage),
                longTermDisabilityElectionIsValid: validateCoverageElection(memberElection, coverages.longTermDisabilityCoverage),
                coverageQuestionsIsValid: validateQuestions(memberElection, coverageQuestions),
                healthBenefitsQuestionIsValid: validateHealthBenefitsQuestion(memberElection, coverageQuestions)
            });
        }
    };

    const isElectionValid = (coverage) => {
        return state[`${lowercaseFirstLetter(coverage)}ElectionIsValid`];
    };

    const areCoveragesValid = () => {
        return state.accidentElectionIsValid
            && state.criticalIllnessElectionIsValid
            && state.groupTermLifeElectionIsValid
            && state.voluntaryTermLifeElectionIsValid
            && state.dentalElectionIsValid
            && state.visionElectionIsValid
            && state.hospitalIndemnityElectionIsValid
            && state.shortTermDisabilityElectionIsValid
            && state.longTermDisabilityElectionIsValid;
    };

    const setSelected = (coverageName) => {
        return () => {
            const newSelectedCoverage = state.selectedCoverage === coverageName
                ? NONE
                : coverageName;
            setState({...state, selectedCoverage: newSelectedCoverage});
        };
    };

    const getHealthBenefitsQuestionWarningMessage = (coverageQuestions) => {
        const coverages = getHealthCoverages();
        const question = coverageQuestions
            .find(question => question.labelAliasName === MBR_MEDICAL_COVERAGE);
        const applicableCoverages = question && coverages.filter(coverage =>
            question.applicableBenefits.some(benefit => coverage?.benefitKey === benefit.benefitKey))
            .map(coverage => coverage.product.productType);
        return applicableCoverages?.length > 0
            ? `You must have major medical in order to obtain ${ListUtil.toReadableOrList(applicableCoverages)} coverage.`
            : '';
    };

    const getHealthCoverages = () => {
        return [coverages.criticalIllnessCoverage,
            coverages.hospitalIndemnityCoverages ? coverages.hospitalIndemnityCoverages[0] : undefined];
    };

    return <div className='container'>
        <div className='row'>
            <div className='col-md-8'>
                {benefitSummaryData.error &&
                    <UnavailableModal
                        onClose={() => unavailableOnCloseHandler()}
                    />}
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionAccidentElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.accidentCoverage, memberElection)}
                        rateSelectorCoverage={coverages.accidentCoverage}
                        coverageName={ACCIDENT}
                        selected={state.selectedCoverage === ACCIDENT}
                        isElectionValid={isElectionValid(ACCIDENT)}
                        setSelected={setSelected(ACCIDENT)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <AccidentElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionCriticalIllnessElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.criticalIllnessCoverage, memberElection)}
                        rateSelectorCoverage={coverages.criticalIllnessCoverage}
                        coverageName={CRITICAL_ILLNESS}
                        selected={state.selectedCoverage === CRITICAL_ILLNESS}
                        isElectionValid={isElectionValid(CRITICAL_ILLNESS)}
                        setSelected={setSelected(CRITICAL_ILLNESS)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <CriticalIllnessElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionDentalElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.dentalCoverages, memberElection)}
                        rateSelectorCoverage={coverages.dentalCoverages}
                        coverageName={DENTAL}
                        selected={state.selectedCoverage === DENTAL}
                        isElectionValid={isElectionValid(DENTAL)}
                        setSelected={setSelected(DENTAL)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <DentalElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionGroupTermLifeElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.groupTermLifeCoverage, memberElection)}
                        rateSelectorCoverage={coverages.groupTermLifeCoverage}
                        coverageName={GROUP_TERM_LIFE}
                        selected={state.selectedCoverage === GROUP_TERM_LIFE}
                        isElectionValid={isElectionValid(GROUP_TERM_LIFE)}
                        setSelected={setSelected(GROUP_TERM_LIFE)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <GroupTermLifeElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionHospitalIndemnityElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.hospitalIndemnityCoverages, memberElection)}
                        rateSelectorCoverage={coverages.hospitalIndemnityCoverages}
                        coverageName={HOSPITAL_INDEMNITY}
                        selected={state.selectedCoverage === HOSPITAL_INDEMNITY}
                        isElectionValid={isElectionValid(HOSPITAL_INDEMNITY)}
                        setSelected={setSelected(HOSPITAL_INDEMNITY)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <HospitalIndemnityElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionLongTermDisabilityElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.longTermDisabilityCoverage, memberElection)}
                        rateSelectorCoverage={coverages.longTermDisabilityCoverage}
                        coverageName={LONG_TERM_DISABILITY}
                        selected={state.selectedCoverage === LONG_TERM_DISABILITY}
                        isElectionValid={isElectionValid(LONG_TERM_DISABILITY)}
                        setSelected={setSelected(LONG_TERM_DISABILITY)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <LongTermDisabilityElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionShortTermDisabilityElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.shortTermDisabilityCoverage, memberElection)}
                        rateSelectorCoverage={coverages.shortTermDisabilityCoverage}
                        coverageName={SHORT_TERM_DISABILITY}
                        selected={state.selectedCoverage === SHORT_TERM_DISABILITY}
                        isElectionValid={isElectionValid(SHORT_TERM_DISABILITY)}
                        setSelected={setSelected(SHORT_TERM_DISABILITY)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <ShortTermDisabilityElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionVisionElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.visionCoverage, memberElection)}
                        rateSelectorCoverage={coverages.visionCoverage}
                        coverageName={VISION}
                        selected={state.selectedCoverage === VISION}
                        isElectionValid={isElectionValid(VISION)}
                        setSelected={setSelected(VISION)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <VisionElection/>
                    </ElectionPanel>
                </div>
                <div className='col-md-12'>
                    <ElectionPanel
                        id='benefitElectionVoluntaryTermLifeElection'
                        percentOfSalaryAndWageZero={percentOfSalaryAndWageZero(coverages.voluntaryTermLifeCoverage, memberElection)}
                        rateSelectorCoverage={coverages.voluntaryTermLifeCoverage}
                        coverageName={VOLUNTARY_TERM_LIFE}
                        selected={state.selectedCoverage === VOLUNTARY_TERM_LIFE}
                        isElectionValid={isElectionValid(VOLUNTARY_TERM_LIFE)}
                        setSelected={setSelected(VOLUNTARY_TERM_LIFE)}
                        hoursWorked={memberElection.hoursWorked}
                    >
                        <VoluntaryTermLifeElection/>
                    </ElectionPanel>
                </div>
                <div id='benefitElectionCoverageQuestions' className='col-md-12'>
                    <CoverageQuestions
                        coverages={getHealthCoverages()}
                    />
                </div>
                <div id='coverageQuestionErrorDiv' style={{paddingTop: '15px', textAlign: 'center'}}
                     className={state.coverageQuestionsIsValid ? '' : 'has-error col-md-12'}>
                    <FormError
                        errorText={COVERAGE_QUESTION_ERROR_MESSAGE}
                    />
                </div>
                <div id='coverageErrorDiv' style={{paddingTop: '15px', textAlign: 'center'}}
                     className={areCoveragesValid() ? '' : 'has-error col-md-12'}>
                    <FormError
                        errorText={COVERAGE_ERROR_MESSAGE}
                    />
                </div>
                <div id='healthBenefitsQuestionErrorDiv' style={{paddingTop: '15px', textAlign: 'center'}}
                     className={state.healthBenefitsQuestionIsValid ? '' : 'has-error col-md-12'}>
                    <FormError
                        errorText={getHealthBenefitsQuestionWarningMessage(coverageQuestions)}
                    />
                </div>
            </div>
            <div className='col-md-4'>
                <ShoppingCart/>
            </div>
        </div>
        <div className='row col-md-12'>
            <hr className='dotted'/>
            <Button
                id='benefitElectionNextBtn'
                next
                clickText={'Review'}
                onClick={() => saveAndConfirm()}
            >
                {'Review '}
            </Button>
            <Button
                id='benefitElectionBackBtn'
                className='pull-left'
                back
                clickText={'Back'}
                onClick={() => props.onCancel()}
            >
                {' Back'}
            </Button>
        </div>
    </div>;
};

const validateCoverageElection = (memberElection, coverage) => {
    if (!coverage || (coverage && coverage.optional)) {
        return true;
    }
    return memberElection.enrolledCoverages.some(enrolledCoverage => coverage.benefitKey === enrolledCoverage.benefitKey);
};

const validateIncrementElection = (memberElection, coverage) => {
    if (!coverage) {
        return true;
    } else if (coverage.optional) {
        if (memberElection.enrolledCoverages.some(enrolledCoverage => coverage.benefitKey === enrolledCoverage.benefitKey)) {
            return amountIsNotGreaterThanBenefitOptionAmount(memberElection, coverage, coverage.spouseBenefitKey, coverage.spouseCannotExceedPercent);
        } else {
            return true;
        }
    }
    return memberElection.enrolledCoverages.some(enrolledCoverage => coverage.benefitKey === enrolledCoverage.benefitKey)
        && amountIsNotGreaterThanBenefitOptionAmount(memberElection, coverage, coverage.spouseBenefitKey, coverage.spouseCannotExceedPercent);
};

const validateMultipleCoverageElection = (memberElection, coverages) => {
    if (!coverages || (coverages[0] && coverages[0].optional)) {
        return true;
    }
    return memberElection.enrolledCoverages.some(
        enrolledCoverage => coverages.find(coverage => coverage.benefitKey === enrolledCoverage.benefitKey));
};

const validatePage = (memberElection, coverages, coverageQuestions) => {
    return validateMultipleCoverageElection(memberElection, coverages.dentalCoverages)
        && validateIncrementElection(memberElection, coverages.criticalIllnessCoverage)
        && validateIncrementElection(memberElection, coverages.voluntaryTermLifeCoverage)
        && validateCoverageElection(memberElection, coverages.groupTermLifeCoverage)
        && validateCoverageElection(memberElection, coverages.visionCoverage)
        && validateCoverageElection(memberElection, coverages.accidentCoverage)
        && validateCoverageElection(memberElection, coverages.shortTermDisabilityCoverage)
        && validateCoverageElection(memberElection, coverages.longTermDisabilityCoverage)
        && validateMultipleCoverageElection(memberElection, coverages.hospitalIndemnityCoverages)
        && validateQuestions(memberElection, coverageQuestions)
        && validateHealthBenefitsQuestion(memberElection);
};

const validateQuestions = (memberElection, coverageQuestions) => {
    return coverageQuestions && coverageQuestions
        .filter(question => !question.labelAliasName.includes('NICOTINE'))
        .every(coverageQuestion => coverageQuestion.applicableBenefits.filter(applicableBenefit =>
            memberElection.enrolledCoverages.find(coverage => coverage.benefitKey === applicableBenefit.benefitKey))
            .map(applicableBenefit => {
                return {labelAliasName: `${applicableBenefit.prefix}${coverageQuestion.labelAliasName}`};
            })
            .every(enrolledApplicableBenefit =>
                memberElection.benefitOptions.find(benefitOption => benefitOption.labelAliasName === enrolledApplicableBenefit.labelAliasName))
        );
};

const validateHealthBenefitsQuestion = (memberElection) => {
    return memberElection && memberElection.benefitOptions
        .filter(benefitOption => benefitOption.labelAliasName.includes('MEDICAL_COVERAGE'))
        .every(benefitOption => benefitOption.benefitOptionData === 'Yes');
};

export default BenefitElection;