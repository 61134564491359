import {isChild, isPartner} from '../../properties/relationships';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';

export const useDependentPopulator = () => {
    const memberElection = useSelector(memberElectionSelector);

    const populateDependents = (selectedBenefitKey, coverage) => {
        return memberElection.familyDemographics.dependents.map(dependent => {
            const isSpouseCoverage = selectedBenefitKey === coverage.spouseBenefitKey;
            const isChildCoverage = selectedBenefitKey === coverage.childBenefitKey;
            const isDependentCoverage = selectedBenefitKey === coverage.dependentBenefitKey;

            const newDependent = JSON.parse(JSON.stringify(dependent));
            if (dependentDoesNotHaveCoverage(newDependent, selectedBenefitKey)) {
                if (isPartner(newDependent.relationship) && isSpouseCoverage) {
                    newDependent.coverages.push(coverage.spouseBenefitKey);
                    if (coverage.spouseADnDBenefitKey) {
                        newDependent.coverages.push(coverage.spouseADnDBenefitKey);
                    }
                }

                if (isChild(newDependent.relationship) && isChildCoverage) {
                    newDependent.coverages.push(coverage.childBenefitKey);
                }

                if (isDependentCoverage) {
                    newDependent.coverages.push(coverage.dependentBenefitKey);
                }
            }
            return newDependent;
        });
    };

    const dependentDoesNotHaveCoverage = (dependent, benefitKey) => {
        return !dependent.coverages.find(existingCoverage => existingCoverage === benefitKey);
    };

    return (selectedBenefitKey, coverage) => populateDependents(selectedBenefitKey, coverage);
};