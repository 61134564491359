import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import Button from '../common/form/Button';
import {
    hasChildDependent,
    hasSpouseDependent,
    NON_WAIVABLE_COVERAGE_MESSAGE,
    WAIVABLE_COVERAGE_MESSAGE
} from './ElectionUtil';
import {accidentRateSelector} from '../../redux/selectors/AccidentRateSelector';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeader from '../common/header/CoverageTableHeader';
import {ACCIDENT_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useTieredCoverageRemover} from '../../hook/remover/useTieredCoverageRemover';
import {useAccidentCoverageDispatcher} from '../../hook/dispatch/useAccidentCoverageDispatcher';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const AccidentElection = (props) => {
    const accidentCoverage = useSelector(accidentRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const downloadBenefitSummary = useBenefitSummaryService();
    const dispatchAccidentCoverage = useAccidentCoverageDispatcher();
    const removeAccidentCoverage = useTieredCoverageRemover();

    const buildCoverageSummary = (coverage) => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Provides an additional layer of protection beyond what's covered by medical and income protection
                    (disability) insurance,
                    providing money you can use however you need it while you focus on recovery.
                </li>
                <li>Helps you pay for items not covered by other insurance after an accidental injury, such as medical
                    deductibles and copayments, lost income from missing work, travel, or child care.
                </li>
                <li>Pays cash directly to you, regardless of insurance payments or actual expenses, on injuries like a
                    dental or eye injury; dislocation; fracture, disc, knee cartilage, tendon, ligament or rotator cuff
                    injury; and more.
                </li>
                <li>Includes an additional benefit paid when there's an accident that causes death or dismemberment.
                </li>
                <li>Includes a wellness benefit to encourage preventive care.</li>
                <li>To learn more about what your accident insurance benefit provides you, please download the
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(coverage.memberGroupKey, 'ACCIDENT')}
                    >
                        {' benefit summary'}
                    </a>
                    {' and watch this '}
                    <a target='_blank'
                        href={ACCIDENT_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
                <li>Accident insurance provides limited benefits.</li>
            </ul>
        </div>;
    };

    const buildRow = (selectedRate, coverage, index) => {
        const activeTier = isActiveTier(coverage, selectedRate);
        return <tr key={`${selectedRate.tier}-row-${index}`} id={`${selectedRate.tier}-row`}
                   className={activeTier ? 'bg-light-blue' : ''}>
            <td id={`${selectedRate.tier}-displayName-${index}`}>{selectedRate.displayName}</td>
            <td id={`${selectedRate.tier}-rate`}>{CurrencyUtil.formatUSD(selectedRate.rate)}</td>
            <td>
                <Button
                    id={`${selectedRate.tier}-coverageButton-${index}`}
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={activeTier}
                    clickText={activeTier ? 'Remove' : 'Add'}
                    onClick={() => {
                        activeTier
                            ? removeAccidentCoverage(coverage, 'accidentBenefitExclusionKey')
                            : dispatchAccidentCoverage(selectedRate);
                    }}
                >
                    <i style={{paddingRight: '5px'}} className={activeTier ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {activeTier ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const buildTable = (coverage) => {
        return <div key={`accidentcoverage-rate${coverage.esclKey}`}>
            {buildCoverageSummary(coverage, props)}
            <div>
                {<table className='table'>
                    <CoverageTableHeader/>
                    {buildTableBody(coverage)}
                </table>}
                <div id='accidentElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {coverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = (coverage) => {
        let rateList = coverage.rates;
        return <tbody>
        {rateList
            .filter(rates => shouldDisplayRow(rates, memberElection.familyDemographics.dependents, coverage.benefitOptions))
            .map((rate, index) => buildRow(rate, coverage, index))}
        </tbody>;
    };

    const isActiveTier = (coverage, rate) => {
        return memberElection.enrolledCoverages
            && memberElection.enrolledCoverages
                .some(electedCoverage => electedCoverage.esclKey === coverage.esclKey
                    && electedCoverage.tier === rate.tier
                    && electedCoverage.tierDisplayName === rate.displayName);
    };

    const shouldDisplayRow = (rate, dependents, benefitOptions) => {
        if (rate.displayName === 'You and your spouse') {
            return hasSpouseDependent(dependents, benefitOptions);
        } else if (rate.displayName === 'You and your children') {
            return hasChildDependent(dependents);
        } else if (rate.displayName === 'You and your family') {
            return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents);
        } else {
            return rate.displayName === 'You';
        }
    };

    return <div className='col-md-12'>
        {buildTable(accidentCoverage)}
    </div>;
};

export default AccidentElection;