import {numeric} from '../stringUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import {BENEFIT_MINIMUM, BENEFIT_PERCENT} from '../../properties/benefitOptions';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import moment from 'moment';
import {getRateFactor} from './getRateFactorUtil';
import CurrencyUtil from '../currencyUtil';
import {getBenefitMaximum} from './getBenefitMaximumUtil';

export const buildLongTermDisabilityRates = (state, rate, benefitOptions, dateOfBirth, smoker, gender, coveredMonthlyEarnings, benefitKey, sortCode) => {
    const benefitMinimum = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_MINIMUM)));
    const benefitMaximum = getBenefitMaximum(benefitOptions);
    const benefitPercentOfSalary = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_PERCENT))) / 100;

    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const age = moment().diff(moment(dateOfBirth, 'MM/DD/YYYY'), 'years');
    const rateFactor = getRateFactor(rate, age, smoker, gender, sortCode);

    const roundedEarnings = Math.round(coveredMonthlyEarnings * 100) / 100;
    const benefitAmount = Math.round(Math.min(Math.max((roundedEarnings * benefitPercentOfSalary), benefitMinimum), benefitMaximum));
    const calculatedMonthlyCompensation = Math.min(Math.max(roundedEarnings, benefitMinimum), (benefitMaximum / benefitPercentOfSalary));

    return [{
        display: CurrencyUtil.formatUSD(benefitAmount, {minimumFractionDigits: 2}),
        value: benefitAmount,
        rate: getRate(calculatedMonthlyCompensation, rate.rateVolumeUnit, rateFactor, contributionDollar, contributionPercent)
    }];
};

const getRate = (benefitAmount, rateVolumeUnit, rateFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round(((benefitAmount / rateVolumeUnit * rateFactor) - contributionDollar) * 100) / 100
        : Math.round(((benefitAmount / rateVolumeUnit * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};