import {useDentalCoveragePopulator} from '../populator/useDentalCoveragePopulator';
import {useDispatch} from 'react-redux';

export const useDentalCoverageDispatcher = () => {
    const populateDentalCoverage = useDentalCoveragePopulator();
    const dispatch = useDispatch();

    const dispatchCoverage = (selectedRate, coverage) => {
        const data = populateDentalCoverage(selectedRate, coverage);
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
    };

    return (selectedRate, coverage) => dispatchCoverage(selectedRate, coverage);
};