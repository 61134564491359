import moment from 'moment';

export const isAdultAgeValid = (dateOfBirth) => {
    return isAgeInRange(dateOfBirth, 10, 100);
};

export const isChildAgeValid = (dateOfBirth) => {
    const dateOfBirthMoment = moment(dateOfBirth);
    return dateOfBirthMoment.isBefore(moment.now()) && isAgeInRange(dateOfBirth, 0, 26);
};

export const isHandicappedChildAgeValid = (dateOfBirth) => {
    const dateOfBirthMoment = moment(dateOfBirth);
    return dateOfBirthMoment.isBefore(moment.now()) && isAgeInRange(dateOfBirth, 0, 100);
};

export const getAge = (dateOfBirthMoment) => {
    return moment().diff(dateOfBirthMoment, 'years');
};

const isAgeInRange = (dateOfBirth, min, max) => {
    const dateOfBirthMoment = moment(dateOfBirth);
    const age = getAge(dateOfBirthMoment);
    return age >= min && age < max;
};
