import {VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN, VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES} from '../properties/coverages';

const hasMultipleVHOS = (hospitalIndemnityRates) =>
    hospitalIndemnityRates
        .filter(coverage => VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES.includes(coverage.product.compassGroupSortCode))
        .length > 1;

export const getVHOSDisplayName = (hosCoverages, coverage) => {
    return hasMultipleVHOS(hosCoverages)
        ? `${VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN} - ${coverage.choicePlanDescription}`
        : VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN;
};