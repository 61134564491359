import React from 'react';
import {useSelector} from 'react-redux';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {useKeypress} from '../../hook/effect/useKeypress';
import {hasNicotineUsageQuestionSelector} from '../../redux/selectors/HasNicotineUsageQuestionSelector';
import SmokerQuestion from '../common/form/SmokerQuestion';
import DateOfBirthInput from '../demographics/DateOfBirthInput';
import GenderInput from '../demographics/GenderInput';
import {validate} from '../../validation/DemographicsValidator';
import FirstLastNameInput from '../demographics/FirstLastNameInput';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const OpenEnrollmentMemberDemographics = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const showSmokerQuestion = useSelector(hasNicotineUsageQuestionSelector);

    const [state, setState] = React.useState({
        firstNameIsValid: true,
        lastNameIsValid: true,
        dateOfBirthIsValid: true,
        genderIsValid: true,
        smokerIsValid: true
    });

    React.useEffect(() => {
        setState(state => ({
            ...state,
            firstName: memberElection.memberDemographics.firstName,
            lastName: memberElection.memberDemographics.lastName,
            gender: memberElection.memberDemographics.gender,
            dateOfBirth: memberElection.memberDemographics.dateOfBirth,
            smoker: memberElection.memberDemographics.smoker
        }));
    }, [memberElection]);

    const useKeypressAction = () => {
        if (validate(state, setState, {checkSmokerQuestion: showSmokerQuestion})) {
            saveAndConfirm(props, memberElection, state);
        }
    };

    useKeypress('Enter',
        useKeypressAction,
        [state, setState, props, showSmokerQuestion]
    );

    const render = () => {
        return <div className='container'>
            <div id='memberNameSection' className='col-md-8 form-group form-group-sm panel' style={{padding: '15px'}}>
                <div>
                    <div className='row' style={{paddingBottom: '15px'}}>
                        <FirstLastNameInput
                            id='FirstNameLabel'
                            labelText='First name'
                            value={state.firstName}
                            isValid={state.firstNameIsValid}
                            required={true}
                            setValue={(name) => setState({...state, firstName: name})}
                            containerClass='col-md-6'
                        />
                        <FirstLastNameInput
                            id='LastNameLabel'
                            labelText='Last name'
                            value={state.lastName}
                            isValid={state.lastNameIsValid}
                            required={true}
                            setValue={(name) => setState({...state, lastName: name})}
                            containerClass='col-md-6'
                        />
                    </div>
                    <div className='row' style={{paddingBottom: '15px'}}>
                        <DateOfBirthInput
                            id='DateOfBirthLabel'
                            value={state.dateOfBirth}
                            isValid={state.dateOfBirthIsValid}
                            setValue={(dateOfBirth) => setState({...state, dateOfBirth})}
                            containerClass='col-md-6'
                        />
                        <GenderInput
                            id='GenderLabel'
                            value={state.gender}
                            isValid={state.genderIsValid}
                            setValue={(gender) => setState({...state, gender})}
                        />
                    </div>
                    <a
                        className='pull-right'
                        target={'_blank'}
                        onClick={() => pushTextLink('Update your information')}
                        href={window.location.href.includes('benefitenrollment.individuals.principal')
                            ? 'https://secure05.principal.com/yourprofile/isea/member/yourprofile.jsp'
                            : 'https://secure05.pilot.principal.com/yourprofile/isea/member/yourprofile.jsp'}
                    >
                        {'Update your information '}
                        <i className='fa fa-external-link'/>
                    </a>
                </div>
                <SmokerQuestion
                    style={{paddingLeft: '0'}}
                    visible={showSmokerQuestion}
                    smoker={state.smoker}
                    smokerIsValid={state.smokerIsValid}
                    handleButtonClick={(smoker) => setState({...state, smoker: smoker})}
                />
            </div>
            <div className='row col-md-12'>
                <hr className='dotted'/>
                <Button
                    next
                    clickText={'Next'}
                    onClick={() => {
                        if (validate(state, setState, {checkSmokerQuestion: showSmokerQuestion})) {
                            saveAndConfirm();
                        }
                    }}
                >
                    {'Next '}
                </Button>
            </div>
        </div>;
    };

    const saveAndConfirm = () => {
        props.save({
            memberDemographics: {
                firstName: state.firstName.trim().toUpperCase(),
                lastName: state.lastName.trim().toUpperCase(),
                gender: state.gender,
                dateOfBirth: state.dateOfBirth,
                smoker: state.smoker
            }
        });
        props.onConfirm();
    };

    return render();
};

export default OpenEnrollmentMemberDemographics;