import {childRelationships, DOMESTIC_PARTNER, partnerRelationships} from '../../properties/relationships';
import {getBenefitSummaryData} from '../../repository/BenefitSummaryRepository';
import {saveAs} from '../../utils/downloadUtil';
import {Buffer} from 'buffer';
import {PERCENT_OF_SALARY_PLAN_TYPE} from '../../properties/coverages';

export const WAIVABLE_COVERAGE_MESSAGE = 'Not electing this coverage means you are waiving it for this benefit period.';
export const NON_WAIVABLE_COVERAGE_MESSAGE = 'This coverage in not allowed to be waived due to policy provisions.';
export const COVERAGE_ERROR_MESSAGE = 'Please verify your coverage selections.';
export const COVERAGE_QUESTION_ERROR_MESSAGE = 'Make sure all questions have been answered.';

export const amountIsGreaterThanGuaranteedIssueAmount = (memberElection, coverage) => {
    const employeeEnrolledCoverage = memberElection.enrolledCoverages
        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverage.benefitKey);
    const spouseEnrolledCoverage = memberElection.enrolledCoverages
        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverage.spouseBenefitKey);

    return (employeeEnrolledCoverage && parseInt(employeeEnrolledCoverage.amount) > coverage.employeeGuaranteedIssueAmount)
        || (spouseEnrolledCoverage && parseInt(spouseEnrolledCoverage.amount) > coverage.spouseGuaranteedIssueAmount);
};

export const hasSpouseDependent = (dependents, benefitOptions) => {
    const hasAnySpouseDependent = dependents.some(dependent => partnerRelationships.includes(dependent.relationship));
    if (hasAnySpouseDependent) {
        let spouseIsDomesticPartner = dependents.some(dependent => dependent.relationship === DOMESTIC_PARTNER);
        if (spouseIsDomesticPartner) {
            const domesticPartnerRestrictionBenefitOptionExists = benefitOptions.some(benefitOption => benefitOption.label.toUpperCase() === DOMESTIC_PARTNER);
            if (domesticPartnerRestrictionBenefitOptionExists) {
                const domesticPartnerRestrictionBenefitOptionValue = benefitOptions.find(benefitOption => benefitOption.label.toUpperCase() === DOMESTIC_PARTNER).value;
                const domesticPartnersAreNotAllowed = domesticPartnerRestrictionBenefitOptionValue.toLowerCase().includes('no');
                return !domesticPartnersAreNotAllowed;
            }
            return true;
        }
        return true;
    }
    return false;
};

export const hasChildDependent = (dependents) => {
    return dependents.some(dependent => childRelationships.includes(dependent.relationship));
};

export const getBenefitSummaryPdf = async (authService, memberGroupKey, product) => {
    const accessToken = await authService.getAccessToken();
    const data = await getBenefitSummaryData(accessToken, memberGroupKey, product, authService.isOkta);
    saveAs(new Blob([Buffer.from(data.pdfData, 'base64')], {type: 'application/pdf'}), `${product}_BENEFIT_SUMMARY.pdf`);
};

export const amountIsNotGreaterThanBenefitOptionAmount = (memberElection, coverage, dependentBenefitKey, dependentCannotExceedPercentage) => {
    const employeeEnrolledCoverage = memberElection.enrolledCoverages
        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverage.benefitKey);
    const spouseEnrolledCoverage = memberElection.enrolledCoverages
        .find(enrolledCoverage => enrolledCoverage.benefitKey === dependentBenefitKey);
    const dependentCannotExceedPercent = dependentCannotExceedPercentage ? dependentCannotExceedPercentage : .5;

    if (!employeeEnrolledCoverage || !spouseEnrolledCoverage) {
        return true;
    }
    return parseInt(spouseEnrolledCoverage.amount) <= (parseInt(employeeEnrolledCoverage.amount) * dependentCannotExceedPercent);
};

export const percentOfSalaryAndWageZero = (coverage, memberElection) => {
    return memberElection?.wage === 0 && coverage?.planType === PERCENT_OF_SALARY_PLAN_TYPE;
};
