const defaultState = {error: false};

const benefitSummaryDataReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_BENEFIT_SUMMARY_DATA':
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default benefitSummaryDataReducer;