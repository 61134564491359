import React from 'react';
import {useSelector} from 'react-redux';
import EConsentDisclosure from '../e-consent/EConsentDisclosure';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {CREATED, STARTED} from '../../properties/electionStatuses';
import FormError from '../common/warning/FormError';
import {useKeypress} from '../../hook/effect/useKeypress';
import {useExistingCoveragesDispatcher} from '../../hook/dispatch/useExistingCoveragesDispatcher';

const EConsent = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const dispatchExistingCoverages = useExistingCoveragesDispatcher();

    const [checked, setChecked] = React.useState(false);
    const [checkIsValid, setCheckIsValid] = React.useState(true);

    useKeypress('Enter',
        () => validatePage(checked, setCheckIsValid) && saveAndConfirm(props, memberElection),
        [checked, setCheckIsValid, props]
    );

    return <div className='container'>
        <div className='panel panel-default'>
            <div id='consentHeader' className='panel-heading-dashboard'>
                Sign or update your electronic consent
            </div>
            <div id='consentBody' className='panel-body' style={{padding: '15px 30px'}}>
                <EConsentDisclosure/>
                <div className='checkbox col-md-12 noPrint'>
                    <label id='consentAcknowledgementLabel' className={checkIsValid ? '' : 'has-error'}>
                        <input
                            id='consentAcknowledgementCheckbox'
                            type='checkbox'
                            value={checked}
                            onClick={() => setChecked(!checked)}
                        />
                        I acknowledge that I have read or had the opportunity to read the agreement and by
                        selecting this agree to the terms and conditions of the documents.<br/>
                        <FormError errorText='Please check the box above to acknowledge the electronic consent'/>
                    </label>
                </div>
            </div>
        </div>
        <div id='eConsentButtons' className='row col-md-12 noPrint' style={{width: '103%'}}>
            <hr className='dotted'/>
            <Button
                next
                clickText={'Next'}
                onClick={() => {
                    validatePage(checked, setCheckIsValid) &&
                    saveAndConfirm(props, memberElection);
                    dispatchExistingCoverages();
                }}>
                {'Next '}
            </Button>
            <Button
                className='pull-left'
                clickText={'Print eConsent'}
                onClick={() => window.print()}
            >
                <i className='fa fa-print'/>
                {' Print eConsent'}
            </Button>
        </div>
    </div>;
};

const validatePage = (checked, setCheckIsValid) => {
    setCheckIsValid(checked);
    return checked;
};

const saveAndConfirm = (props, memberElection) => {
    const electionStatus = memberElection.electionStatus === CREATED ? STARTED : memberElection.electionStatus;
    props.save({
        eConsentAcknowledged: true,
        electionStatus
    });
    props.onConfirm();
};

export default EConsent;