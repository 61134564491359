import {getCoverageBySortCode} from '../../utils/rates/getCoverageBySortCodeUtil';
import {getCoverage} from '../../utils/rates/getCoverageUtil';
import {buildFlatRates} from '../../utils/rates/buildFlatRatesUtil';
import {buildPercentOfSalaryRates} from '../../utils/rates/buildPercentOfSalaryRatesUtil';
import {getWeeklyEmployeeSalary} from '../../utils/rates/getEmployeeSalaryUtil';
import {
    INCREMENT_PLAN_TYPE,
    PERCENT_OF_SALARY_PLAN_TYPE,
    SHORT_TERM_DISABILITY_SORT_CODES
} from '../../properties/coverages';
import {buildTermDisabilityIncrementRates} from '../../utils/rates/buildDisabilityIncrementRatesUtil';
import {getGuaranteedIssueAmount} from '../../utils/rates/getGuaranteedIssueAmountUtil';

export const shortTermDisabilityRateSelector = (state) => {
    const firstAvailableMemberGroupKey = state.memberElection.memberGroups[0];

    const convertToRateTranche = (rate, memberGroupKey) => {
        const employeeCoverage = getCoverageBySortCode(state, memberGroupKey, rate.rateProduct.compassGroupSortCode);

        return {
            memberGroupKey: rate.memberGroupKey,
            esclKey: rate.esclKey,
            employeeGuaranteedIssueAmount: getGuaranteedIssueAmount(employeeCoverage.benefitOptions, state.memberElection.memberDemographics.dateOfBirth),
            ...getCoverage(state, rate.memberGroupKey, rate.bntpKey),
            employeeRates: employeeCoverage.planType === INCREMENT_PLAN_TYPE
                ? buildTermDisabilityIncrementRates(
                    state,
                    rate,
                    employeeCoverage.benefitOptions,
                    state.memberElection.memberDemographics.dateOfBirth,
                    state.memberElection.memberDemographics.smoker,
                    state.memberElection.memberDemographics.gender,
                    getWeeklyEmployeeSalary(state.memberElection.wage, state.memberElection.wageFrequency),
                    employeeCoverage.benefitKey,
                    rate.rateProduct.compassGroupSortCode)
                : employeeCoverage.planType === PERCENT_OF_SALARY_PLAN_TYPE
                    ? buildPercentOfSalaryRates(
                        state,
                        rate,
                        employeeCoverage.benefitOptions,
                        state.memberElection.memberDemographics.dateOfBirth,
                        state.memberElection.memberDemographics.smoker,
                        state.memberElection.memberDemographics.gender,
                        getWeeklyEmployeeSalary(state.memberElection.wage, state.memberElection.wageFrequency),
                        employeeCoverage.benefitKey,
                        rate.rateProduct.compassGroupSortCode)
                    : buildFlatRates(
                        state,
                        rate,
                        employeeCoverage.benefitOptions,
                        state.memberElection.memberDemographics.smoker,
                        state.memberElection.memberDemographics.gender,
                        employeeCoverage.benefitKey,
                        rate.rateProduct.compassGroupSortCode)
        };
    };

    if (!Boolean(state.memberElection.memberDemographics.dateOfBirth)) {
        return undefined;
    }
    return state.policyData.rates
        .filter(rate => SHORT_TERM_DISABILITY_SORT_CODES.includes(rate.rateProduct.compassGroupSortCode)
            && rate.memberGroupKey === firstAvailableMemberGroupKey)
        .map(rate => convertToRateTranche(rate, firstAvailableMemberGroupKey))[0];

};