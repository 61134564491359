export const BENEFIT_AMOUNT = ['Benefit Amount'];
export const BENEFIT_MAXIMUM = ['Benefit Maximum', 'Maximum Benefit'];
export const BENEFIT_MINIMUM = ['Benefit Minimum', 'Minimum Benefit'];
export const BENEFIT_PERCENT = 'Benefit Percent';
export const BENEFIT_TYPE = 'Benefit Type';
export const CONTRIBUTORY_TYPE = 'Contributory Type';
export const MULTIPLE_OF_PAY = ['Multiple of Pay'];
export const CANNOT_EXCEED_AMOUNT = 'Cannot Exceed Amount';
export const FLAT_AMOUNT = ['Flat Amount', 'Flat Benefit'];
export const GUARANTEED_ISSUE_AMOUNT = ['Guaranteed Issue Amount'];
export const GUARANTEED_ISSUE_AMOUNT_UNDER_70 = ['Guaranteed Issue Under 70'];
export const GUARANTEED_ISSUE_AMOUNT_70_PLUS = ['Guaranteed Issue 70+'];
export const INCREMENT_AMOUNT = ['Increment Amount', 'Increment Of'];
export const MAXIMUM = ['Maximum'];
export const MINIMUM_ELECTION = ['Minimum Election'];
export const VCIEEINCR = 'VCIEEINCR';
export const VTLCHILD = 'VTLCHILD';
export const VTLEEINCR = 'VTLEEINCR';
export const VTLEESALMULT = 'VTLEESALMULT';
export const MBR_SPVCIINCR = 'MBR_SPVCIINCR';
export const MBR_SPVTLINCR = 'MBR_SPVTLINCR';
export const LTDINCR = 'LTDINCR';
export const STDINCR = 'STDINCR';
export const VLTDINCR = 'VLTDINCR';
export const VSTDINCR = 'VSTDINCR';

export const VSP_PLAN_YEAR_CODE = '131';
export const VSP_CHOICE = 'VSP Choice';
export const SCHEDULED_PLAN_YEAR_CODE = '061';
export const SCHEDULED = 'Scheduled';
export const PLEASE_SEE_BENEFIT_SUMMARY = 'Please check your benefit summary';