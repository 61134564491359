import {isChild, isPartner} from '../../properties/relationships';

const YOU_AND_YOUR_FAMILY = 'You and your family';

export const getTierDisplayName = (dependents, dependentSortCodes, rates) => {
    const hasThreeTier = rates.some(rate => rate.tier === 'THREE_TIER_PLUS_ONE');
    if (hasThreeTier) {
        const dependentsWithCoverage = dependents
            .filter(dependent => hasMatchingActiveCoverage(dependent, dependentSortCodes));
        if (dependentsWithCoverage.length > 1) {
            return YOU_AND_YOUR_FAMILY;
        } else if (dependentsWithCoverage.length === 1) {
            return rates
                .find(rate => rate.guid === dependentsWithCoverage[0].caseMemberRelationshipKey)
                .displayName;
        }
    } else {
        const hasMatchingSpouseCoverage = dependents
            .filter(dependent => isPartner(dependent.relationship))
            .some(dependent => hasMatchingActiveCoverage(dependent, dependentSortCodes));
        const hasMatchingChildCoverage = dependents
            .filter(dependent => isChild(dependent.relationship))
            .some(dependent => hasMatchingActiveCoverage(dependent, dependentSortCodes));
        const hasFamily = hasMatchingSpouseCoverage && hasMatchingChildCoverage;
        if (hasFamily) {
            return YOU_AND_YOUR_FAMILY;
        } else if (hasMatchingChildCoverage) {
            return 'You and your children';
        } else if (hasMatchingSpouseCoverage) {
            return 'You and your spouse';
        }
    }
    return 'You';
};

const hasMatchingActiveCoverage = (dependent, dependentSortCodes) => {
    return dependent.dependentBenefits
        .filter(dependentBenefit => !dependentBenefit.benefitTerminationDate)
        .some(dependentBenefit => dependentSortCodes.includes(dependentBenefit.product.compassGroupSortCode));
};