import {useGroupTermLifeCoveragePopulator} from '../populator/useGroupTermLifeCoveragePopulator';
import {useDispatch} from 'react-redux';

export const useGroupTermLifeCoverageDispatcher = () => {
    const populateGroupTermLifeCoverage = useGroupTermLifeCoveragePopulator();
    const dispatch = useDispatch();

    const dispatchCoverage = (selectedRate, selectedBenefitKey) => {
        const data = populateGroupTermLifeCoverage(selectedRate, selectedBenefitKey);
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
    };

    return (selectedRate, selectedBenefitKey) => dispatchCoverage(selectedRate, selectedBenefitKey);
};