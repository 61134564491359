import React from 'react';
import {useBothAuthService} from '../../provider/AuthProvider';
import {useHistory} from 'react-router';
import {AUTH_TYPE_COGNITO} from '../../properties/auth';

const AuthCallback = (props) => {
    const history = useHistory();
    const {authService, oktaAuthService} = useBothAuthService();

    React.useEffect(() => {
        const authCallback = async () => {
            try {
                const service = props.auth === AUTH_TYPE_COGNITO ? authService : oktaAuthService;
                await service.signinRedirectCallback();
                if (service.isAuthenticated()) {
                    props.auth === 'cognito'
                        ? history.push('/new')
                        : history.push('/existing');
                } else {
                    history.push('/error');
                }
            } catch (error) {
                history.push('/error');
            }
        };
        authCallback();
    }, [authService, oktaAuthService, history, props.auth]);

    return <span/>;
};

export default AuthCallback;