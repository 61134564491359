import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import {useDispatch, useSelector} from 'react-redux';
import {coverageQuestionSelector} from '../../redux/selectors/CoverageQuestionSelector';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {pushCallToAction} from '../../utils/googleAnalyticsUtil';
import ListUtil from '../../utils/listUtil';

export const MBR_PRIOR_ORTO = 'MBR_PRIOR_ORTO';
export const MBR_HUSB_WF = 'MBR_HUSB_WF';
export const MBR_MEDICAL_COVERAGE = 'MBR_MEDICAL_COVERAGE';
export const DEP_MEDICAL_COVERAGE = 'DEP_MEDICAL_COVERAGE';

const CoverageQuestions = (props) => {
    const coverageQuestions = useSelector(coverageQuestionSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatch = useDispatch();

    const priorOrthoQuestion = getPriorOrthoQuestion(coverageQuestions);
    const sameCompanySpouseQuestion = getSameCompanySpouseQuestion(coverageQuestions);
    const hasMbrHealthBenefitsQuestion = getMbrHasHealthBenefitsQuestion(coverageQuestions);
    const hasDepHealthBenefitsQuestion = getDepHasHealthBenefitsQuestion(coverageQuestions);

    return (priorOrthoQuestion || sameCompanySpouseQuestion || hasMbrHealthBenefitsQuestion) ?
        <div id='coverageQuestions' style={{padding: '15px', backgroundColor: 'white'}}>
            <b>Answer a couple of questions to confirm eligibility.</b>
            <br/>
            {priorOrthoQuestion && buildPriorOrthoQuestion(memberElection, priorOrthoQuestion, dispatch)}
            {sameCompanySpouseQuestion && buildSameCompanySpouseQuestion(memberElection, sameCompanySpouseQuestion, dispatch)}
            {hasMbrHealthBenefitsQuestion
                && buildHasHealthBenefitsQuestion(memberElection, props.coverages,
                    hasMbrHealthBenefitsQuestion, hasDepHealthBenefitsQuestion, dispatch)}
        </div> : <div/>;
};

const buildPriorOrthoQuestion = (memberElection, coverageQuestion, dispatch) => {
    return <div id='CoverageQuestions-priorOrthoQuestion'>
        <span>
            In the past 12 months have you or your dependents had continuous Group Orthodontia with a prior carrier?
            <RequiredFieldIndicator/>
        </span>
        <div className='btn-group btn-group-sm' role='group' style={{margin: '10px'}}>
            <button
                id='CoverageQuestions-priorOrthoQuestion-yesInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'Yes', MBR_PRIOR_ORTO) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('Yes');
                    dispatchToRedux(memberElection, coverageQuestion, dispatch, 'Yes', MBR_PRIOR_ORTO);
                }}
            >
                {'Yes'}
            </button>
            <button
                id='CoverageQuestions-priorOrthoQuestion-noInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'No', MBR_PRIOR_ORTO) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('No');
                    dispatchToRedux(memberElection, coverageQuestion, dispatch, 'No', MBR_PRIOR_ORTO);
                }}
            >
                {'No'}
            </button>
            <button
                id='CoverageQuestions-priorOrthoQuestion-unknownInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'Unknown', MBR_PRIOR_ORTO) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('I don\'t know');
                    dispatchToRedux(memberElection, coverageQuestion, dispatch, 'Unknown', MBR_PRIOR_ORTO);
                }}
            >
                {'I don\'t know'}
            </button>
        </div>
        <br/>
    </div>;
};

const buildSameCompanySpouseQuestion = (memberElection, coverageQuestion, dispatch) => {
    return <div id='CoverageQuestions-sameCompanySpouse'>
        <span>
            Do you have a spouse working for the same company?
            <RequiredFieldIndicator/>
        </span>
        <div className='btn-group btn-group-sm' role='group' style={{margin: '10px'}}>
            <button
                id='CoverageQuestions-sameCompanySpouse-yesInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'Yes', MBR_HUSB_WF) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('Yes');
                    dispatchToRedux(memberElection, coverageQuestion, dispatch, 'Yes', MBR_HUSB_WF);
                }}
            >
                {'Yes'}
            </button>
            <button
                id='CoverageQuestions-sameCompanySpouse-noInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'No', MBR_HUSB_WF) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('No');
                    dispatchToRedux(memberElection, coverageQuestion, dispatch, 'No', MBR_HUSB_WF);
                }}
            >
                {'No'}
            </button>
        </div>
    </div>;
};

const buildHasHealthBenefitsQuestion = (memberElection, coverages, coverageQuestion, depCoverageQuestion, dispatch) => {
    return <div id='CoverageQuestions-hasHealthBenefits'>
        <span>
            {getHealthBenefitsQuestion(coverages, coverageQuestion)}
            <RequiredFieldIndicator/>
        </span>
        <div className='btn-group btn-group-sm' role='group' style={{margin: '10px'}}>
            <button
                id='CoverageQuestions-hasHealthBenefits-yesInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'Yes', MBR_MEDICAL_COVERAGE) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('Yes');
                    dispatchHealthBenefitsToRedux(memberElection, coverageQuestion, depCoverageQuestion, dispatch, 'Yes');
                }}
            >
                {'Yes'}
            </button>
            <button
                id='CoverageQuestions-hasHealthBenefits-noInput'
                className={isMatchingBenefitOptionValue(memberElection.benefitOptions, 'No', MBR_MEDICAL_COVERAGE) ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    pushCallToAction('No');
                    dispatchHealthBenefitsToRedux(memberElection, coverageQuestion, depCoverageQuestion, dispatch, 'No');
                }}
            >
                {'No'}
            </button>
        </div>
    </div>;
};

const getPriorOrthoQuestion = (coverageQuestions) => {
    return coverageQuestions
        && coverageQuestions.find(coverageQuestion => coverageQuestion.labelAliasName === MBR_PRIOR_ORTO);
};

const getSameCompanySpouseQuestion = (coverageQuestions) => {
    return coverageQuestions
        && coverageQuestions.find(coverageQuestion => coverageQuestion.labelAliasName === MBR_HUSB_WF);
};

const getMbrHasHealthBenefitsQuestion = (coverageQuestions) => {
    return coverageQuestions
        && coverageQuestions.find(coverageQuestion => coverageQuestion.labelAliasName === MBR_MEDICAL_COVERAGE);
};

const getDepHasHealthBenefitsQuestion = (coverageQuestions) => {
    return coverageQuestions
        && coverageQuestions.find(coverageQuestion => coverageQuestion.labelAliasName === DEP_MEDICAL_COVERAGE);
};

const isMatchingBenefitOptionValue = (benefitOptions, value, labelAliasName) => {
    return Boolean(benefitOptions) && benefitOptions
        .some(benefitOption => benefitOption.labelAliasName.includes(labelAliasName)
            && benefitOption.benefitOptionData === value);
};

const dispatchToRedux = (memberElection, coverageQuestion, dispatch, value, labelAliasName) => {
    let benefitOptionsToSubmit = coverageQuestion.applicableBenefits
        .filter(filterEnrolledCoverages(memberElection.enrolledCoverages))
        .map(mapToBenefitOptions(labelAliasName, value));

    benefitOptionsToSubmit = benefitOptionsToSubmit
        .concat(filterOldBenefitOptions(memberElection.benefitOptions, benefitOptionsToSubmit));

    dispatch({
        type: 'UPDATE_MEMBER_ELECTION',
        data: {
            benefitOptions: benefitOptionsToSubmit
        }
    });
};

const dispatchHealthBenefitsToRedux = (memberElection, coverageQuestion, depCoverageQuestion, dispatch, value) => {
    let healthBenefitOptions = coverageQuestion &&  coverageQuestion.applicableBenefits
        .filter(filterEnrolledCoverages(memberElection.enrolledCoverages))
        .map(mapToBenefitOptions(MBR_MEDICAL_COVERAGE, value));
    let depBenefitOption = depCoverageQuestion && depCoverageQuestion.applicableBenefits
        .filter(filterEnrolledCoverages(memberElection.enrolledCoverages))
        .map(mapToBenefitOptions(DEP_MEDICAL_COVERAGE, value));
    if (depBenefitOption) {
        healthBenefitOptions = healthBenefitOptions.concat(depBenefitOption);
    }
    const benefitOptionsToSubmit = healthBenefitOptions
        .concat(filterOldBenefitOptions(memberElection.benefitOptions, healthBenefitOptions));

    dispatch({
        type: 'UPDATE_MEMBER_ELECTION',
        data: {
            benefitOptions: benefitOptionsToSubmit
        }
    });
};

const filterEnrolledCoverages = (enrolledCoverages) => {
    return applicableBenefit => enrolledCoverages
        .some(enrolledCoverage => enrolledCoverage.benefitKey === applicableBenefit.benefitKey);
};

const mapToBenefitOptions = (labelAliasName, value) => {
    return applicableBenefit => ({
        benefitKey: applicableBenefit.benefitKey,
        labelAliasName: `${applicableBenefit.prefix}${labelAliasName}`,
        benefitOptionData: value
    });
};

const filterOldBenefitOptions = (existingBenefitOptions, newBenefitOptions) => {
    if (existingBenefitOptions) {
        return existingBenefitOptions
            .filter(benefitOption => !newBenefitOptions
                .some(newBenefitOption => benefitOption.benefitKey === newBenefitOption.benefitKey
                    && benefitOption.labelAliasName === newBenefitOption.labelAliasName));
    }
    return [];
};

const getHealthBenefitsQuestion = (coverages, question) => {
    const applicableCheckedCoverages = coverages
        .filter(coverage => coverage && question.applicableBenefits.some(benefit => coverage.benefitKey === benefit.benefitKey))
        .map(coverage => coverage.product.productType);
    return applicableCheckedCoverages.length > 0
        ? <>
            {`If electing ${ListUtil.toReadableOrList(applicableCheckedCoverages)} coverage, I declare that I and my eligible ` +
                'dependents have other coverage providing comprehensive health benefits from an insurance policy, an HMO plan, or an employer health benefit plan.'}
            <br/><br/>
            {`NOTE: ${ListUtil.toReadableOrList(applicableCheckedCoverages)} coverage cannot be issued to a person ` +
            'who does not have comprehensive health benefits coverage in place.'}
        </>
        : undefined;
};

export default CoverageQuestions;