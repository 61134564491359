export const SPOUSE = 'SPOUSE';
export const COMMON_LAW_SPOUSE = 'COMMON LAW SPOUSE';
export const DISPLAY_COMMON_LAW_SPOUSE = 'COMMON-LAW SPOUSE';
export const CIVIL_UNION_PARTNER = 'CIVIL UNION PARTNER';
export const DOMESTIC_PARTNER = 'DOMESTIC PARTNER';
export const STATE_REGISTERED_DOMESTIC_PARTNER = 'STATE REGISTERED DOMESTIC PARTNER';
export const RECIPROCAL_BENEFICIARY = 'RECIPROCAL BENEFICIARY';
export const DEPENDENT_CHILD = 'CHILD';
export const CENSUS_DEPENDENT_CHILD = 'DEPENDENT CHILD';
export const FOSTER_CHILD = 'FOSTER CHILD';
export const GRAND_CHILD = 'GRAND CHILD';
export const DISPLAY_HANDICAPPED_CHILD = 'DISABLED CHILD';
export const HANDICAPPED_CHILD = 'HANDICAPPED CHILD';
export const NON_TRADITIONAL_CHILD = 'NON TRADITIONAL CHILD';

export const relationships = [
    {display: '', value: ''},
    {display: SPOUSE, value: SPOUSE},
    {display: DISPLAY_COMMON_LAW_SPOUSE, value: COMMON_LAW_SPOUSE},
    {display: CIVIL_UNION_PARTNER, value: CIVIL_UNION_PARTNER},
    {display: DOMESTIC_PARTNER, value: DOMESTIC_PARTNER},
    {display: STATE_REGISTERED_DOMESTIC_PARTNER, value: STATE_REGISTERED_DOMESTIC_PARTNER},
    {display: RECIPROCAL_BENEFICIARY, value: RECIPROCAL_BENEFICIARY},
    {display: DEPENDENT_CHILD, value: DEPENDENT_CHILD},
    {display: FOSTER_CHILD, value: FOSTER_CHILD},
    {display: GRAND_CHILD, value: GRAND_CHILD},
    {display: DISPLAY_HANDICAPPED_CHILD, value: HANDICAPPED_CHILD},
    {display: NON_TRADITIONAL_CHILD, value: NON_TRADITIONAL_CHILD}
];

export const partnerRelationships = [
    SPOUSE,
    COMMON_LAW_SPOUSE,
    CIVIL_UNION_PARTNER,
    DOMESTIC_PARTNER,
    STATE_REGISTERED_DOMESTIC_PARTNER,
    RECIPROCAL_BENEFICIARY
];

export const childRelationships = [
    DEPENDENT_CHILD,
    CENSUS_DEPENDENT_CHILD,
    FOSTER_CHILD,
    GRAND_CHILD,
    HANDICAPPED_CHILD,
    NON_TRADITIONAL_CHILD
];

export const isPartner = (relationship) => {
    return relationship && partnerRelationships.includes(relationship.toUpperCase());
};

export const isChild = (relationship) => {
    return relationship && childRelationships.includes(relationship.toUpperCase());
};