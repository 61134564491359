import React from 'react';
import {Route} from 'react-router-dom';
import {useBothAuthService} from '../provider/AuthProvider';
import {AUTH_TYPE_COGNITO} from '../properties/auth';

const ProtectedRoute = ({component, ...otherProps}) => {
    const {authService, oktaAuthService} = useBothAuthService();
    const service = otherProps.auth === AUTH_TYPE_COGNITO ? authService : oktaAuthService;

    const renderHandler = (Component) => (props) => {
        if (!!Component && service.isAuthenticated()) {
            return <Component {...props} />;
        } else {
            service.signinRedirect();
            return <span/>;
        }
    };
    return <Route {...otherProps} render={renderHandler(component)} />;
};

export default ProtectedRoute;