import {DEPENDENT_GROUP_TERM_LIFE_SORT_CODE, EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE} from '../../properties/coverages';
import {useSelector} from 'react-redux';
import {groupTermLifeRateSelector} from '../../redux/selectors/GroupTermLifeRateSelector';
import {useGroupTermLifeCoveragePopulator} from '../populator/useGroupTermLifeCoveragePopulator';

export const useGroupTermLifeCoverageMapper = () => {
    const groupTermLifeCoverage = useSelector(groupTermLifeRateSelector);
    const populateGroupTermLifeCoverage = useGroupTermLifeCoveragePopulator();

    const map = (coverage) => {
        if (coverage.product.compassGroupSortCode === EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE) {
            const matchingRate = groupTermLifeCoverage.employeeRates[0];
            return populateGroupTermLifeCoverage(matchingRate, groupTermLifeCoverage.benefitKey);
        }
        if (coverage.product.compassGroupSortCode === DEPENDENT_GROUP_TERM_LIFE_SORT_CODE) {
            const matchingRate = groupTermLifeCoverage.dependentRates[0];
            return populateGroupTermLifeCoverage(matchingRate, groupTermLifeCoverage.dependentBenefitKey);
        }
    };

    return (coverage) => map(coverage);
};