import React from 'react';
import Footer from '../common/Footer';
import Header from '../common/header/Header';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const LoggedOut = () => {
    return <div className='container' style={{position: 'relative', minHeight: '75vh'}}>
        <Header showLogout={false}/>
        <div className='row text-center'>
            <div className='col-md-12'>
                <h1>You're logged out.</h1>
            </div>
        </div>
        <div className='row col-md-offset-3 text-center' style={{paddingBottom: '90px'}}>
            <div className='col-md-8'>
                <p>Not what you wanted to do?
                    <br/>
                    {'Click '}
                    <a
                        href={'/new'}
                        onClick={() => pushTextLink('here')}
                    >
                        {'here'}
                    </a>
                    {' to log in again.'}
                </p>
            </div>
        </div>
        <Footer/>
    </div>;
};

export default LoggedOut;