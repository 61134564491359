import React from 'react';
import FormWarning from './FormWarning';
import {EOI_LINK, LINK_TEXT} from './EoiInfo';
import {pushTextLink} from '../../../utils/googleAnalyticsUtil';

export const EOI_MESSAGE = 'The benefit amount you’ve elected requires you to provide evidence of insurability. ' +
    'You’ll receive an email soon.  ';

export const EoiWarning = (props) => {
    const render = () => {
        return <FormWarning
            display={Boolean(props.display)}
            style={{textAlign: 'center'}}
            warningText={
                <span
                    data-gtm={props.shouldHavePromotionalBanner ? 'promotional-banner' : ''}
                    hero-title={props.shouldHavePromotionalBanner ? 'EOI Required' : ''}
                >
                    {EOI_MESSAGE}
                    <a
                        href={EOI_LINK}
                        target='_blank'
                        onClick={() => pushTextLink(LINK_TEXT)}
                    >
                        {LINK_TEXT}
                        <i className='fa fa-external-link'/>
                    </a>
            </span>}
        />;
    };

    return render();
};
