import React from 'react';
import {pushCallToAction} from '../../../utils/googleAnalyticsUtil';

const Button = (props) => {
    return <button
        className={`btn ${props.default ? 'btn-default' : 'btn-primary'} ${props.className ? props.className : 'pull-right'} noPrint`}
        onClick={() => {
            props.onClick();
            pushCallToAction(props.clickText);
        }}
        style={{...props.style, outline: 'none'}}
    >
        {props.back && <i className='fa fa-angle-left'/>}
        {props.children}
        {props.next && <i className='fa fa-angle-right'/>}
    </button>;
};

export default Button;