import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {useDependentTierPopulator} from './useDependentTierPopulator';
import {visionRateSelector} from '../../redux/selectors/VisionRateSelector';
import {VISION} from '../../properties/coverages';
import {
    PLEASE_SEE_BENEFIT_SUMMARY,
    SCHEDULED,
    SCHEDULED_PLAN_YEAR_CODE,
    VSP_CHOICE,
    VSP_PLAN_YEAR_CODE
} from '../../properties/benefitOptions';

export const useVisionCoveragePopulator = () => {
    const visionCoverage = useSelector(visionRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentTierPopulator();

    const populateEnrolledCoverage = (selectedRate) => {
        const dependents = populateDependents(selectedRate, visionCoverage, 'visionBenefitExclusionKey');
        const enrolledCoverages = buildEnrolledCoverages(selectedRate);

        return {
            enrolledCoverages: enrolledCoverages,
            familyDemographics: {
                dependents: dependents
            },
            benefitOptions: memberElection.benefitOptions
                .filter(benefitOption => !benefitOption.labelAliasName.includes('MBR_HUSB_WF')
                    && !benefitOption.labelAliasName.includes('MBR_PRIOR_ORTO')),
        };
    };

    const buildEnrolledCoverages = (selectedRate) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.coverageName !== VISION);

        enrolledCoverages.push({
            benefitKey: visionCoverage.benefitKey,
            esclKey: visionCoverage.esclKey,
            memberGroupKey: visionCoverage.memberGroupKey,
            coverageName: VISION,
            tier: selectedRate.tier,
            tierDisplayName: selectedRate.displayName,
            rate: selectedRate.rate,
            benefit: getBenefit()
        });

        if (selectedRate.tier !== 'EMPLOYEE') {
            enrolledCoverages.push({
                benefitKey: visionCoverage.dependentBenefitKey,
                memberGroupKey: visionCoverage.memberGroupKey,
                coverageName: VISION,
                dependent: true
            });
        }
        return enrolledCoverages;
    };

    const getBenefit = () => {
        const planYearBenefitOption = visionCoverage.benefitOptions
            .find(benefitOption => benefitOption.label.toUpperCase() === 'BENEFIT PLAN YEAR');
        if (Boolean(planYearBenefitOption)) {
            if (planYearBenefitOption.value === VSP_PLAN_YEAR_CODE) {
                return VSP_CHOICE;
            } else if (planYearBenefitOption.value === SCHEDULED_PLAN_YEAR_CODE) {
                return SCHEDULED;
            }
        }
        return PLEASE_SEE_BENEFIT_SUMMARY;
    };

    return (selectedRate) => populateEnrolledCoverage(selectedRate);
};