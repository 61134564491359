export const waivedCoverageSelector = (state) => {
    let aMemberGroupKey = state.memberElection.memberGroups[0];
    return state.policyData.billGroupData.memberGroups
        .find(policyDataMemberGroup => policyDataMemberGroup.memberGroupKey === aMemberGroupKey).coverages
        .filter(memberGroupCoverage => coverageNotAlreadyEnrolled(state, memberGroupCoverage))
        .filter(coverage => !coverage.product.accidentalDeathAndDismemberment)
        .filter(coverage => state.memberElection.hoursWorked >= coverage.minimumPeriodicHours)
        .filter(coverage => coverage.product.employeeProduct);
};

const coverageNotAlreadyEnrolled = (state, memberGroupCoverage) => {
    return state.memberElection.enrolledCoverages
        ? !state.memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === memberGroupCoverage.benefitKey)
        : true;
};