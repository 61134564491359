import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {accidentRateSelector} from '../../redux/selectors/AccidentRateSelector';
import {ACCIDENT} from '../../properties/coverages';
import {useDependentTierPopulator} from './useDependentTierPopulator';

export const useAccidentCoveragePopulator = () => {
    const accidentCoverage = useSelector(accidentRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentTierPopulator();

    const populateEnrolledCoverage = (selectedRate) => {
        const dependents = populateDependents(selectedRate, accidentCoverage, 'accidentBenefitExclusionKey');
        const enrolledCoverages = buildEnrolledCoverages(selectedRate);

        return {
            enrolledCoverages: enrolledCoverages,
            familyDemographics: {
                dependents: dependents
            },
        };
    };

    const buildEnrolledCoverages = (selectedRate) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.coverageName !== ACCIDENT);

        enrolledCoverages.push({
            benefitKey: accidentCoverage.benefitKey,
            esclKey: accidentCoverage.esclKey,
            memberGroupKey: accidentCoverage.memberGroupKey,
            coverageName: ACCIDENT,
            tier: selectedRate.tier,
            tierDisplayName: selectedRate.displayName,
            rate: selectedRate.rate,
            benefit: 'Payment of injuries'
        });

        if (selectedRate.tier !== 'EMPLOYEE') {
            enrolledCoverages.push({
                benefitKey: accidentCoverage.dependentBenefitKey,
                memberGroupKey: accidentCoverage.memberGroupKey,
                coverageName: ACCIDENT,
                dependent: true
            });
        }
        return enrolledCoverages;
    };

    return (selectedRate) => populateEnrolledCoverage(selectedRate);
};