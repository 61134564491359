import React from 'react';
import CurrencyUtil from '../../../utils/currencyUtil';
import FormInfo from './FormInfo';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../../redux/selectors/MemberElectionSelector';
import {pushTextLink} from '../../../utils/googleAnalyticsUtil';

export const LINK_TEXT = 'Learn what to expect from the online EOI process  ';
export const EOI_LINK = 'https://secure02.principal.com/publicvsupply/GetFile?fm=GP60831&ty=VOP';

export const EoiInfo = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const render = () => {
        return !Boolean(memberElection.censusMember.privacyId) &&
            <FormInfo
                display={Boolean(props.coverage.employeeGuaranteedIssueAmount)}
                style={{textAlign: 'center'}}
                infoText={
                    <span>
                    {eoiMessage()}
                        <a
                            href={EOI_LINK}
                            target='_blank'
                            onClick={() => pushTextLink(LINK_TEXT)}
                        >
                            {LINK_TEXT}
                            <i className='fa fa-external-link'/>
                    </a>
                </span>}
        />;
    };

    const eoiMessage = () => {
        const employeeGiaMessage = `${formatAmount(props.coverage.employeeGuaranteedIssueAmount)} for employee`;
        const spouseGiaMessage = props.coverage.spouseGuaranteedIssueAmount
            ? `and ${formatAmount(props.coverage.spouseGuaranteedIssueAmount)} for spouse `
            : '';
        return `${employeeGiaMessage} ${spouseGiaMessage}is the maximum amount before an evidence of insurability may be required. `;
    };

    const formatAmount = (amount) => {
        return CurrencyUtil.formatUSD(amount, {minimumFractionDigits: 0});
    };

    return render();
};