import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {NON_WAIVABLE_COVERAGE_MESSAGE, WAIVABLE_COVERAGE_MESSAGE} from './ElectionUtil';
import Button from '../common/form/Button';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeaderWithAmount from '../common/header/CoverageTableHeaderWithAmount';
import {groupTermLifeRateSelector} from '../../redux/selectors/GroupTermLifeRateSelector';
import {TERM_LIFE_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {PLEASE_SEE_BENEFIT_SUMMARY} from '../../properties/benefitOptions';
import {useGroupTermLifeCoverageRemover} from '../../hook/remover/useGroupTermLifeCoverageRemover';
import {useGroupTermLifeCoverageDispatcher} from '../../hook/dispatch/useGroupTermLifeCoverageDispatcher';
import {EoiInfo} from '../common/warning/EoiInfo';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const GroupTermLifeElection = () => {
    const groupTermLifeCoverage = useSelector(groupTermLifeRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatchGroupTermLifeCoverage = useGroupTermLifeCoverageDispatcher();
    const removeGroupTermLifeCoverage = useGroupTermLifeCoverageRemover();
    const downloadBenefitSummary = useBenefitSummaryService();

    const render = () => {
        return <div className='col-md-12'>
            {buildTable()}
        </div>;
    };

    const buildCoverageSummary = () => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>{'Provides a life insurance benefit which is paid to your beneficiaries at the time of your death.'}</li>
                <li>{'Helps ease the financial burden your family may face without your income.'}</li>
                <li>{'Includes an additional benefit which is paid when there is an accident that causes death or dismemberment.'}</li>
                <li>{'To learn more about what your group term life insurance benefit provides you, please download the'}
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(groupTermLifeCoverage.memberGroupKey, 'BASIC_LIFE')}
                    >
                        {' benefit summary'}
                    </a>
                    {' and watch this '}
                    <a
                        target='_blank'
                        href={TERM_LIFE_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildTable = () => {
        return <div key={`groupTermLifeElection-rate${groupTermLifeCoverage.esclKey}`}>
            {buildCoverageSummary()}
            <div>
                {<table className='table'>
                    <CoverageTableHeaderWithAmount/>
                    {buildTableBody()}
                </table>}
                <EoiInfo coverage={groupTermLifeCoverage}/>
                <div id='groupTermLifeElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {groupTermLifeCoverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = () => {
        return <tbody>
        {buildRow(groupTermLifeCoverage.employeeRates, groupTermLifeCoverage.benefitKey)}
        {
            Boolean(groupTermLifeCoverage.dependentBenefitKey) && Boolean(memberElection.familyDemographics.dependents.length)
            && buildRow(groupTermLifeCoverage.dependentRates, groupTermLifeCoverage.dependentBenefitKey)
        }
        </tbody>;
    };

    const shouldShowButton = (benefitKey) => {
        if (benefitKey === groupTermLifeCoverage.dependentBenefitKey || benefitKey === groupTermLifeCoverage.dependentBenefitKey) {
            return hasElectedCoverage(groupTermLifeCoverage.benefitKey);
        }
        return true;
    };

    const buildRow = (rates, coverageBenefitKey) => {
        return <tr key={`groupTermLifeElection${coverageBenefitKey}-row`}
                   id={`groupTermLifeElection${coverageBenefitKey}-row`}
                   className={hasElectedCoverage(coverageBenefitKey) ? 'bg-light-blue' : ''}>
            <td id={`groupTermLifeElection${coverageBenefitKey}-displayName`}>{coverageBenefitKey === groupTermLifeCoverage.benefitKey
                ? 'You'
                : 'Family'}</td>
            <td>{coverageBenefitKey === groupTermLifeCoverage.benefitKey
                ?
                <span>{memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount)
                    : CurrencyUtil.formatUSD(rates[0].value)}
                </span>
                : <span id='groupTermLifeElectionDependentMessage'>{PLEASE_SEE_BENEFIT_SUMMARY}</span>
            }
            </td>
            <td id={`groupTermLifeElection${coverageBenefitKey}-rate`}>{
                memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages
                        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).rate)
                    : CurrencyUtil.formatUSD(rates[0].rate)
            }</td>
            <td>
                {shouldShowButton(coverageBenefitKey) &&
                    <Button
                        style={{fontSize: '13px', minWidth: '140px'}}
                        default={hasElectedCoverage(coverageBenefitKey)}
                        clickText={hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                        onClick={() => hasElectedCoverage(coverageBenefitKey)
                            ? removeGroupTermLifeCoverage(coverageBenefitKey)
                            : dispatchGroupTermLifeCoverage(rates[0], coverageBenefitKey)}
                    >
                        <i
                            style={{paddingRight: '5px'}}
                            className={hasElectedCoverage(coverageBenefitKey) ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}
                        />
                        {hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                </Button>}
            </td>
        </tr>;
    };

    const hasElectedCoverage = (benefitKey) => memberElection.enrolledCoverages
        && memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === benefitKey);

    return render();
};

export default GroupTermLifeElection;