import React from 'react';
import Button from '../form/Button';
import {modalView} from '../../../utils/googleAnalyticsUtil';

const UnavailableModal = (props) => {
    modalView('UnavailableModal');

    return (
        <div>
            <div className='modal'
                 style={{display: 'block'}}>
                <div className='modal-dialog modal-std'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <span style={{'fontSize': '125%'}}><p className='text-center'><b>We're sorry. It looks like something went wrong on our end. We'll work on getting that fixed.</b></p></span>
                        </div>
                        {errorModalFooter(props.onClose)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const errorModalFooter = (onClose) => {
    return (
        <div className='modal-footer' style={{
            'backgroundImage': 'url(http://ux.principal.com/horizon/master/examplefiles/v2/caleb-the-plumber.svg)',
            'backgroundRepeatX': 'no-repeat',
            'backgroundRepeatY': 'no-repeat',
            'backgroundPositionX': 'center',
            'backgroundColor': '#bddef0'
        }}>
            <br/><br/><br/><br/>
            <Button onClick={onClose}>
                OK
            </Button>
        </div>
    );
};

export default UnavailableModal;