import React from 'react';
import MemberDemographics from './view/MemberDemographics';
import {useDispatch, useSelector} from 'react-redux';
import Review from './view/Review';
import {saveMemberElection} from '../redux/action/DataAction';
import {useAuthService} from '../provider/AuthProvider';
import EConsent from './view/EConsent';
import ProgressIndicator from './common/indicator/ProgressIndicator';
import Indicator from './common/indicator/Indicator';
import FamilyDemographics from './view/FamilyDemographics';
import Confirmation from './view/Confirmation';
import BenefitElection from './view/BenefitElection';
import ContentHeader from './common/header/ContentHeader';
import Footer from './common/Footer';
import Header from './common/header/Header';
import {memberElectionSelector} from '../redux/selectors/MemberElectionSelector';
import Error from './view/Error';
import Spinner from './common/Spinner';
import {useHomeEffect} from '../hook/effect/useHomeEffect';
import AddressReview from './view/AddressReview';
import {ERRORED} from '../properties/electionStatuses';
import OutOfEnrollment from './view/OutOfEnrollment';
import {hasDependentCoveragesSelector} from '../redux/selectors/HasDependentCoveragesSelector';
import OpenEnrollmentMemberDemographics from './view/OpenEnrollmentMemberDemographics';
import {pushPathChange, pushPolicyInformation} from '../utils/googleAnalyticsUtil';

export const OUT_OF_ENROLLMENT_PAGE = -3;
export const ERROR_PAGE = -2;
export const SPINNER_PAGE = -1;
export const ECONSENT_PAGE = 0;
export const MEMBER_DEMOGRAPHICS_PAGE = .5;
export const ADDRESS_REVIEW_PAGE = .75;
export const FAMILY_DEMOGRAPHICS_PAGE = 1;
export const BENEFITS_PAGE = 2;
export const REVIEW_PAGE = 3;
export const CONFIRMATION_PAGE = 4;

const Home = () => {
    const memberElection = useSelector(memberElectionSelector);
    const hasDependentCoverages = useSelector(hasDependentCoveragesSelector);
    let [page, setPage] = React.useState(SPINNER_PAGE);
    const dispatch = useDispatch();
    const authService = useAuthService();
    const pageChangeEvent = 'virtualPageChange';

    useHomeEffect(page, setPage);

    const save = (data) => {
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
        dispatch(saveMemberElection(authService, data, setPage));
    };

    const shouldDisplayProgressIndicator = () => {
        return page !== ERROR_PAGE && page !== SPINNER_PAGE && page !== OUT_OF_ENROLLMENT_PAGE;
    };

    const switchPage = (newPage, path, pageTitle) => {
        setPage(newPage);
        pushPathChange(pageChangeEvent, path, pageTitle);
    };

    return <div style={{position: 'relative', minHeight: '75vh'}}>
        <div className='bg-white'>
            <Header
                showLogout={true}/>
            {shouldDisplayProgressIndicator() &&
                <div className='row' style={{marginLeft: '10%', marginRight: '10%'}}>
                    <ProgressIndicator page={page}>
                        <Indicator
                            indicatorText='About you'
                            activeOnPages={[ECONSENT_PAGE, MEMBER_DEMOGRAPHICS_PAGE, ADDRESS_REVIEW_PAGE, FAMILY_DEMOGRAPHICS_PAGE]}
                        />
                        <Indicator
                            indicatorText='Benefits'
                            activeOnPages={[BENEFITS_PAGE]}
                        />
                        <Indicator
                            indicatorText='Review and submit'
                            activeOnPages={[REVIEW_PAGE]}
                        />
                        <Indicator
                            indicatorText='Summary'
                            activeOnPages={[CONFIRMATION_PAGE]}
                        />
                    </ProgressIndicator>
                </div>
            }
            {shouldDisplayProgressIndicator() && <ContentHeader page={page}/>}
        </div>
        <br/>
        <div style={{paddingBottom: '15px'}}>
            {page === ECONSENT_PAGE &&
                <EConsent
                    onConfirm={() => {
                        switchPage(MEMBER_DEMOGRAPHICS_PAGE, 'MEMBER_DEMOGRAPHICS', 'Member Demographics');
                        pushPolicyInformation(memberElection.policyKey);
                    }}
                    save={(data) => save(data)}
                />}
            {page === MEMBER_DEMOGRAPHICS_PAGE &&
                (authService.isOkta
                    ? <OpenEnrollmentMemberDemographics
                        onConfirm={() => {
                            hasDependentCoverages
                                ? switchPage(FAMILY_DEMOGRAPHICS_PAGE, 'FAMILY_DEMOGRAPHICS', 'Family Demographics')
                                : switchPage(BENEFITS_PAGE, 'BENEFITS', 'Benefits');
                        }}
                        save={(data) => save(data)}/>
                    : <MemberDemographics
                        onConfirm={() => {
                            switchPage(ADDRESS_REVIEW_PAGE, 'ADDRESS_REVIEW', 'Address Review');
                        }}
                        save={(data) => save(data)}
                    />)}
            {page === ADDRESS_REVIEW_PAGE &&
                <AddressReview
                    onConfirm={() => {
                        hasDependentCoverages
                            ? switchPage(FAMILY_DEMOGRAPHICS_PAGE, 'FAMILY_DEMOGRAPHICS', 'Family Demographics')
                            : switchPage(BENEFITS_PAGE, 'BENEFITS', 'Benefits');
                    }}
                    onCancel={() => {
                        switchPage(MEMBER_DEMOGRAPHICS_PAGE, 'MEMBER_DEMOGRAPHICS', 'Member Demographics');
                    }}
                    save={(data) => save(data)}
                />}
            {page === FAMILY_DEMOGRAPHICS_PAGE &&
                <FamilyDemographics
                    onConfirm={() => {
                        switchPage(BENEFITS_PAGE, 'BENEFITS', 'Benefits');
                    }}
                    onCancel={() => {
                        switchPage(MEMBER_DEMOGRAPHICS_PAGE, 'MEMBER_DEMOGRAPHICS', 'Member Demographics');
                    }}
                    save={(data) => save(data)}
                />}
            {page === BENEFITS_PAGE &&
                <BenefitElection
                    onConfirm={() => {
                        switchPage(REVIEW_PAGE, 'REVIEW', 'Review');
                    }}
                    onCancel={() => {
                        hasDependentCoverages
                            ? switchPage(FAMILY_DEMOGRAPHICS_PAGE, 'FAMILY_DEMOGRAPHICS', 'Family Demographics')
                            : switchPage(MEMBER_DEMOGRAPHICS_PAGE, 'MEMBER_DEMOGRAPHICS', 'Member Demographics');
                    }}
                    save={(data) => save(data)}
                />}
            {page === REVIEW_PAGE &&
                <Review
                    onConfirm={() => {
                        switchPage(CONFIRMATION_PAGE, 'CONFIRMATION', 'Confirmation');
                    }}
                    onCancel={() => {
                        switchPage(BENEFITS_PAGE, 'BENEFITS', 'Benefits');
                    }}
                    save={(data) => save(data)}
                />}
            {page === CONFIRMATION_PAGE && <Confirmation/>}
            {page === SPINNER_PAGE && <Spinner/>}
            {page === OUT_OF_ENROLLMENT_PAGE && <OutOfEnrollment/>}
            {page === ERROR_PAGE && <Error
                setupError={!Boolean(memberElection.policyKey)
                    || memberElection.electionStatus === ERRORED}/>}
        </div>
        <Footer/>
    </div>;
};

export default Home;