import {BENEFIT_MAXIMUM, MAXIMUM} from '../../properties/benefitOptions';
import {numeric, stripCents} from '../stringUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';

export const getBenefitMaximum = (benefitOptions) => {
    const benefitMaximum = parseInt(numeric(stripCents(getMatchingBenefitOption(benefitOptions, BENEFIT_MAXIMUM))));
    const maximum = parseInt(numeric(stripCents(getMatchingBenefitOption(benefitOptions, MAXIMUM))));

    if (benefitMaximum && maximum) {
        return Math.max(benefitMaximum, maximum);
    } else if (Boolean(maximum)) {
        return maximum;
    } else if (Boolean(benefitMaximum)) {
        return benefitMaximum;
    }

    return 0;
};