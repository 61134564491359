import {User, UserManager, WebStorageStateStore} from 'oidc-client';

export class AuthService {
    get settings() {
        return this.userManager.settings;
    }

    get userKey() {
        return `oidc.user:${this.settings.authority}:${this.settings.client_id}`;
    }

    constructor(userManager, storage, isOkta) {
        this.userManager = userManager;
        this.storage = storage;
        this.isOkta = isOkta;
    }

    isAuthenticated = () => {
        const storedUser = this.getStoredUser();
        return !!storedUser && !storedUser.expired;
    };

    signinRedirect = async () => {
        return await this.userManager.signinRedirect();
    };

    signinRedirectCallback = async () => {
        return await this.userManager.signinRedirectCallback();
    };

    signoutRedirect = async () => {
        return await this.userManager.signoutRedirect();
    };

    signoutRedirectCallback = async () => {
        return await this.userManager.signoutRedirectCallback();
    };

    getUser = async () => {
        return await this.userManager.getUser();
    };

    getStoredUser = () => {
        const serializedUser = this.storage.getItem(this.userKey);
        return serializedUser
            ? User.fromStorageString(serializedUser)
            : null;
    };

    getAccessToken = async () => {
        if (!this.isAuthenticated()) {
            return null;
        }

        const user = await this.getUser();
        return user.access_token;
    };

    static createFromSettings(settings) {
        const storage = window.sessionStorage;
        const userManager = new UserManager({
            ...settings,
            userStore: new WebStorageStateStore({store: storage})
        });
        return new AuthService(userManager, storage, settings.isOkta);
    }
}