import {numeric} from '../stringUtil';
import {BENEFIT_MINIMUM, INCREMENT_AMOUNT} from '../../properties/benefitOptions';
import moment from 'moment';
import CurrencyUtil from '../currencyUtil';
import {VOLUNTARY_TERM_LIFE_SORT_CODES} from '../../properties/coverages';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import {getADnDIncludedRateFactor} from './getADnDIncludedRateFactorUtil';
import {getRateFactor} from './getRateFactorUtil';
import {getBenefitMaximum} from './getBenefitMaximumUtil';

export const buildIncrementRates = (state, rate, benefitOptions, dateOfBirth, smoker, gender, benefitKey, sortCode) => {
    const benefitMinimum = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_MINIMUM)))
        || parseInt(numeric(getMatchingBenefitOption(benefitOptions, INCREMENT_AMOUNT)));
    const benefitMaximum = getBenefitMaximum(benefitOptions);
    const benefitIncrement = parseInt(numeric(getMatchingBenefitOption(benefitOptions, INCREMENT_AMOUNT)));

    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const age = moment().diff(moment(dateOfBirth, 'MM/DD/YYYY'), 'years');
    const rateFactor = VOLUNTARY_TERM_LIFE_SORT_CODES.includes(sortCode)
        ? getADnDIncludedRateFactor(rate, age, smoker, gender, sortCode)
        : getRateFactor(rate, age, smoker, gender, sortCode);

    const increments = Math.trunc((benefitMaximum - benefitMinimum) / benefitIncrement + 1);
    return Array(increments).fill().map((_, index) => {
        return {
            display: CurrencyUtil.formatUSD(benefitIncrement * index + benefitMinimum, {minimumFractionDigits: 0}),
            value: benefitIncrement * index + benefitMinimum,
            rate: getRate(benefitIncrement, index, benefitMinimum, rate, rateFactor, contributionDollar, contributionPercent)
        };
    });
};

const getRate = (benefitIncrement, index, benefitMinimum, rate, rateFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round((((benefitIncrement * index + benefitMinimum) / rate.rateVolumeUnit * rateFactor) - contributionDollar) * 100) / 100
        : Math.round((((benefitIncrement * index + benefitMinimum) / rate.rateVolumeUnit * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};