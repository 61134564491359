import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {criticalIllnessRateSelector} from '../../redux/selectors/CriticalIllnessRateSelector';
import {
    hasChildDependent,
    hasSpouseDependent,
    NON_WAIVABLE_COVERAGE_MESSAGE,
    WAIVABLE_COVERAGE_MESSAGE
} from './ElectionUtil';
import DropDownField from '../common/form/DropDownField';
import Button from '../common/form/Button';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeaderWithAmount from '../common/header/CoverageTableHeaderWithAmount';
import {CRITICAL_ILLNESS_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useCriticalIllnessCoverageDispatcher} from '../../hook/dispatch/useCriticalIllnessCoverageDispatcher';
import {useCriticalIllnessCoverageRemover} from '../../hook/remover/useCriticalIllnessCoverageRemover';
import {DependentAmountError} from '../common/warning/DependentAmountError';
import {EoiInfo} from '../common/warning/EoiInfo';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

export const CHILD_AMOUNT_MESSAGE = 'Critical illness insurance for your dependent children is 25% of your benefit for no additional cost to you.';

const CriticalIllnessElection = () => {
    const criticalIllnessCoverage = useSelector(criticalIllnessRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatchCriticalIllnessCoverage = useCriticalIllnessCoverageDispatcher();
    const removeCriticalIllnessCoverage = useCriticalIllnessCoverageRemover();
    const downloadBenefitSummary = useBenefitSummaryService();

    const buildCoverageSummary = () => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Critical Illness insurance provides limited benefits.</li>
                <li>An additional layer of protection beyond what is covered by medical and income protection insurance,
                    providing money that can be used for anything while you focus on a full recovery.
                </li>
                <li>Payable when you have one of the following illnesses: cancer, coronary artery bypass graft, heart
                    attack, major organ failure or stroke.
                </li>
                <li>Benefits are paid regardless of other insurance coverage or actual expenses incurred.</li>
                <li>{'To learn more about what your critical illness insurance benefit provides you, please download the '}
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(criticalIllnessCoverage.memberGroupKey, 'VCI')}
                    >
                        {'benefit summary'}
                    </a>{' and watch this '}
                    <a
                        target='_blank'
                        href={CRITICAL_ILLNESS_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
                {!criticalIllnessCoverage.childBenefitKey && <li>{CHILD_AMOUNT_MESSAGE}</li>}
            </ul>
        </div>;
    };

    const buildTable = () => {
        return <div key={`criticalIllnessElection-rate${criticalIllnessCoverage.esclKey}`}>
            {buildCoverageSummary()}
            <div>
                {<table className='table'>
                    <CoverageTableHeaderWithAmount/>
                    {buildTableBody()}
                </table>}
                <EoiInfo coverage={criticalIllnessCoverage}/>
                <DependentAmountError coverage={criticalIllnessCoverage} relationship={'Spouse'}/>
                <div id='criticalIllnessElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {criticalIllnessCoverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = () => {
        return <tbody>
        {buildRow(criticalIllnessCoverage.employeeRates, criticalIllnessCoverage.benefitKey)}
        {hasSpouseDependent(memberElection.familyDemographics.dependents, criticalIllnessCoverage.benefitOptions)
        && buildRow(criticalIllnessCoverage.spouseRates, criticalIllnessCoverage.spouseBenefitKey)}
        {hasChildDependent(memberElection.familyDemographics.dependents) && Boolean(criticalIllnessCoverage.childBenefitKey)
        && buildRow(criticalIllnessCoverage.childRates, criticalIllnessCoverage.childBenefitKey)}
        </tbody>;
    };

    const shouldShowButton = (benefitKey) => {
        if (benefitKey === criticalIllnessCoverage.spouseBenefitKey || benefitKey === criticalIllnessCoverage.childBenefitKey) {
            return hasElectedCoverage(criticalIllnessCoverage.benefitKey);
        }
        return true;
    };

    const buildRow = (rates, coverageBenefitKey) => {
        const coverageGroupType = coverageBenefitKey === criticalIllnessCoverage.benefitKey
            ? 'You'
            : coverageBenefitKey === criticalIllnessCoverage.spouseBenefitKey
                ? 'Spouse'
                : 'Child';

        return <tr key={`criticalIllnessElection${coverageBenefitKey}-row`}
                   id={`criticalIllnessElection${coverageBenefitKey}-row`}
                   className={hasElectedCoverage(coverageBenefitKey) ? 'bg-light-blue' : ''}>
            <td id={`criticalIllnessElection${coverageBenefitKey}-displayName`}>{coverageGroupType}</td>
            <td>
                <DropDownField
                    ariaLabel={coverageGroupType + ' Critical Illness Amount'}
                    value={memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                        ? memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount
                        : rates[0].value}
                    options={rates}
                    onChange={(e) => {
                        dispatchCriticalIllnessCoverage(
                            rates.find(rate => rate.value === parseInt(e.target.value)),
                            coverageBenefitKey
                        );
                    }}
                    disabled={!hasElectedCoverage(coverageBenefitKey)}
                />
            </td>
            <td id={`criticalIllnessElection${coverageBenefitKey}-rate`}>{
                memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages
                        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).rate)
                    : CurrencyUtil.formatUSD(rates[0].rate)
            }</td>
            <td>
                {shouldShowButton(coverageBenefitKey) &&
                    <Button
                        style={{fontSize: '13px', minWidth: '140px'}}
                        default={hasElectedCoverage(coverageBenefitKey)}
                        clickText={hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                        onClick={() => hasElectedCoverage(coverageBenefitKey)
                            ? removeCriticalIllnessCoverage(coverageBenefitKey)
                            : dispatchCriticalIllnessCoverage(rates[0], coverageBenefitKey)}
                    >
                        <i
                            style={{paddingRight: '5px'}}
                            className={hasElectedCoverage(coverageBenefitKey) ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}
                        />
                        {hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                </Button>}
            </td>
        </tr>;
    };

    const hasElectedCoverage = (benefitKey) => memberElection.enrolledCoverages
        && memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === benefitKey);

    return <div className='col-md-12'>
        {buildTable()}
    </div>;
};

export default CriticalIllnessElection;