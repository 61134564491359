import RequiredFieldIndicator from './RequiredFieldIndicator';
import React from 'react';
import FormError from '../warning/FormError';
import {pushCallToAction} from '../../../utils/googleAnalyticsUtil';

const SmokerQuestion = (props) => {
    return props.visible && <div
        style={props.style}
        className={props.smokerIsValid ? 'col-md-12' : 'col-md-12 has-error'}
    >
        <span>
            {props.spouse
                ? 'Has this person used nicotine products in the past 12 months?'
                : 'Have you used nicotine products in the past 12 months?'}
            <RequiredFieldIndicator/>
        </span>
        <div className='btn-group btn-group-sm' role='group' style={{margin: '10px'}}>
            <button
                id='SmokerQuestion-yesInput'
                disabled={props.disabled}
                className={props.smoker === 'Yes' ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    props.handleButtonClick('Yes');
                    pushCallToAction('Yes');
                }}
            >
                Yes
            </button>
            <button
                id='SmokerQuestion-noInput'
                disabled={props.disabled}
                className={props.smoker === 'No' ? 'btn btn-default active' : 'btn btn-default'}
                onClick={() => {
                    props.handleButtonClick('No');
                    pushCallToAction('No');
                }}
            >
                No
            </button>
        </div>
        <FormError errorText='Please answer the question'/>
    </div>;
};

export default SmokerQuestion;