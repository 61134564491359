import {
    GUARANTEED_ISSUE_AMOUNT,
    GUARANTEED_ISSUE_AMOUNT_70_PLUS,
    GUARANTEED_ISSUE_AMOUNT_UNDER_70
} from '../../properties/benefitOptions';
import {getAge} from '../dateValidation';
import moment from 'moment';
import {numeric, stripCents} from '../stringUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';

export const getGuaranteedIssueAmount = (benefitOptions, dateOfBirth) => {
    const guaranteedIssueAmount = parseInt(numeric(stripCents(getMatchingBenefitOption(benefitOptions, GUARANTEED_ISSUE_AMOUNT))));
    const guaranteedIssueAmountUnder70 = parseInt(numeric(stripCents(getMatchingBenefitOption(benefitOptions, GUARANTEED_ISSUE_AMOUNT_UNDER_70))));
    const guaranteedIssueAmount70Plus = parseInt(numeric(stripCents(getMatchingBenefitOption(benefitOptions, GUARANTEED_ISSUE_AMOUNT_70_PLUS))));

    if (dateOfBirth && guaranteedIssueAmountUnder70 && guaranteedIssueAmount70Plus) {
        return getAge(moment(dateOfBirth, 'MM/DD/YYYY')) < 70
            ? checkZero(guaranteedIssueAmountUnder70)
            : checkZero(guaranteedIssueAmount70Plus);
    }
    return checkZero(guaranteedIssueAmount);
};

const checkZero = (amount) => {
    return amount === 0 ? undefined : amount;
};