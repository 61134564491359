export const getContributionPercentRate = (state, memberGroupKey, benefitKey) => {
    const contribution = getContributionData(state, memberGroupKey, benefitKey);
    return (contribution && contribution.employerContributionPercent) ? (100 - contribution.employerContributionPercent) / 100 : 1.0;
};

export const getContributionDollarRate = (state, memberGroupKey, benefitKey) => {
    const contribution = getContributionData(state, memberGroupKey, benefitKey);
    return (contribution && contribution.employerContributionDollar) ? contribution.employerContributionDollar : 0;
};

const getContributionData = (state, memberGroupKey, benefitKey) => {
    const contributionData = state.contributionData
        && state.contributionData.find(data => data.memberGroupKey === memberGroupKey);
    return contributionData && contributionData.productContributions
        && contributionData.productContributions.find(contribution => contribution.benefitKey === benefitKey);
};