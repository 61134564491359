import {CANNOT_EXCEED_AMOUNT} from '../../properties/benefitOptions';
import {numeric} from '../stringUtil';

export const getDependentCannotExceedPercent = (benefitOptions) => {
    const benefitOption = benefitOptions
        .find(benefitOption => benefitOption.label.includes(CANNOT_EXCEED_AMOUNT));
    if (benefitOption) {
        return parseInt(numeric(benefitOption.value)) / 100;
    }
    return 0.5;
};