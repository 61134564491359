import {
    CHILD_CRITICAL_ILLNESS_SORT_CODE,
    EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE,
    SPOUSE_CRITICAL_ILLNESS_SORT_CODE
} from '../../properties/coverages';
import {isChild, isPartner} from '../../properties/relationships';
import {getCoverageBySortCode} from '../../utils/rates/getCoverageBySortCodeUtil';
import {buildIncrementRates} from '../../utils/rates/buildIncrementRatesUtil';
import {getGuaranteedIssueAmount} from '../../utils/rates/getGuaranteedIssueAmountUtil';
import {getDependentCannotExceedPercent} from '../../utils/rates/getDependentCannotExceedPercentUtil';
import {buildChildRates} from '../../utils/rates/buildChildRatesUtil';
import {getCoverage} from '../../utils/rates/getCoverageUtil';

export const criticalIllnessRateSelector = (state) => {
    const firstAvailableMemberGroupKey = state.memberElection.memberGroups[0];

    const convertToRateTranche = (rate, memberGroupKey) => {
        const employeeCoverage = getCoverageBySortCode(state, memberGroupKey, EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE);
        const spouseCoverage = getCoverageBySortCode(state, memberGroupKey, SPOUSE_CRITICAL_ILLNESS_SORT_CODE);
        const childCoverage = getCoverageBySortCode(state, memberGroupKey, CHILD_CRITICAL_ILLNESS_SORT_CODE);
        const spouse = state.memberElection.familyDemographics.dependents.find(dependent => isPartner(dependent.relationship));
        return {
            memberGroupKey: rate.memberGroupKey,
            esclKey: rate.esclKey,
            employeeRates: buildIncrementRates(
                state,
                rate,
                employeeCoverage.benefitOptions,
                state.memberElection.memberDemographics.dateOfBirth,
                state.memberElection.memberDemographics.smoker,
                state.memberElection.memberDemographics.gender,
                employeeCoverage.benefitKey,
                EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE
            ),
            employeeGuaranteedIssueAmount: getGuaranteedIssueAmount(employeeCoverage.benefitOptions),
            spouseBenefitKey: spouseCoverage.benefitKey,
            spouseRates: state.memberElection.familyDemographics.dependents.some(dependent => isPartner(dependent.relationship))
                ? buildIncrementRates(
                    state,
                    rate,
                    spouseCoverage.benefitOptions,
                    spouse.dateOfBirth,
                    spouse.smoker,
                    spouse.gender,
                    spouseCoverage.benefitKey,
                    SPOUSE_CRITICAL_ILLNESS_SORT_CODE
                )
                : [],
            spouseGuaranteedIssueAmount: getGuaranteedIssueAmount(spouseCoverage.benefitOptions),
            spouseCannotExceedPercent: getDependentCannotExceedPercent(spouseCoverage.benefitOptions),
            childCannotExceedPercent: childCoverage && getDependentCannotExceedPercent(childCoverage.benefitOptions),
            childBenefitKey: childCoverage && childCoverage.benefitKey,
            childRates: childCoverage
            && state.memberElection.familyDemographics.dependents.some(dependent => isChild(dependent.relationship))
                ? buildChildRates(state, rate, childCoverage.benefitKey, childCoverage.benefitOptions, CHILD_CRITICAL_ILLNESS_SORT_CODE, childCoverage.allowedAmounts)
                : [],
            ...getCoverage(state, rate.memberGroupKey, rate.bntpKey)
        };
    };

    if (!Boolean(state.memberElection.memberDemographics.dateOfBirth)) {
        return undefined;
    }
    return state.policyData.rates
        .filter(rate => rate.rateProduct.compassGroupSortCode === EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE
            && rate.memberGroupKey === firstAvailableMemberGroupKey)
        .map(rate => convertToRateTranche(rate, firstAvailableMemberGroupKey))[0];
};