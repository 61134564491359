import {MALE, NON_BINARY, NON_SMOKER, SMOKER, UNI_SMOKER, UNISEX} from '../../properties/rates';
import {ALL_ADND_SORT_CODES} from '../../properties/coverages';

export const getRateFactor = (rate, age, smoker, gender, sortCode) => {
    return findTheTranche(rate, age, smoker, gender, sortCode).factor;
};

export const getRateAmount = (rate, age, smoker, gender, sortCode) => {
    return findTheTranche(rate, age, smoker, gender, sortCode).amount;
};

const findTheTranche = (rate, age, smoker, gender, sortCode) => {
    let tranche = rate.rateTranches
        .find(rateTranche => (rateTranche.rateProduct.compassGroupSortCode === sortCode)
            && (rateTranche.genderType === UNISEX
                || rateTranche.genderType === null
                || rateTranche.genderType.toUpperCase() === gender.toUpperCase()
                || gender === NON_BINARY && rateTranche.genderType === MALE)
            && (rateTranche.smokerType === UNI_SMOKER
                || rateTranche.smokerType === null
                || (smoker === 'Yes' && rateTranche.smokerType === SMOKER)
                || (smoker === 'No' && rateTranche.smokerType === NON_SMOKER)));
    if (ALL_ADND_SORT_CODES.includes(sortCode) && !(Boolean(tranche))) {
        return {factor: 0, amount: 0};
    }
    if (!Boolean(tranche)) {
        throw new Error(`Error Rate Tranche not found for sort code ${sortCode}`);
    }
    return tranche
        .bands
        .find(band => band.lowerAge <= age && (band.upperAge >= age || band.upperAge === null));
};