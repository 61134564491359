import {HOSPITAL_INDEMNITY_SORT_CODES} from '../../properties/coverages';
import {convertToRateList} from '../../utils/rates/convertToRateListUtil';
import {isChild} from '../../properties/relationships';


export const hospitalIndemnityRateSelector = (state) => {
    const coverages = state.policyData.rates
        .filter(rate => HOSPITAL_INDEMNITY_SORT_CODES.includes(rate.rateProduct.compassGroupSortCode))
        .map(rateTier => convertToRateList(state, rateTier));
    const filteredCoverages = filterDuplicateCoverages(coverages);

    const modifiedFilteredCoverages = filteredCoverages.map(coverage => {
        return {
            ...coverage,
            rates: mappedThreeTierRates(state.memberElection.familyDemographics.dependents, coverage)
        };
    });

    if (modifiedFilteredCoverages.length) {
        return modifiedFilteredCoverages;
    }
};

const filterDuplicateCoverages = (coverages) => {
    let reducedArray = [];
    coverages.forEach((coverage) => {
        if (!rateIsAlreadyInList(coverage, reducedArray)) {
            reducedArray.push(coverage);
        }
    });
    return reducedArray;
};

const rateIsAlreadyInList = (rate, list) => {
    return list.some(item => isSameRate(item, rate));
};

const isSameRate = (firstRate, secondRate) => {
    if (firstRate.esclKey === secondRate.esclKey
        && firstRate.benefitKey === secondRate.benefitKey
        && firstRate.rates.length === secondRate.rates.length) {
        return firstRate.rates.every((rate, i) => {
            return rate.rate === secondRate.rates[i].rate;
        });
    }
    return false;
};

const mappedThreeTierRates = (dependents, coverage) => {
    const threeTierRate = coverage.rates.find(rate => rate.tier === 'THREE_TIER_PLUS_ONE');
    if (threeTierRate && Boolean(dependents)) {
        let mappedThreeTierRates = coverage.rates.filter(rate => rate.displayName !== 'You and your dependent');
        dependents.forEach(dependent =>
            mappedThreeTierRates.push({
                ...threeTierRate,
                displayName: `You and ${dependent.firstName}${isChild(dependent.relationship) ? ' (Child)' : ''}`,
                guid: dependent.guid
            }));
        return mappedThreeTierRates;
    } else {
        return coverage.rates;
    }
};