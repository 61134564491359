import {BIWEEKLY, HOUR, MONTH, SEMIMONTHLY, WEEK} from '../../properties/wageFrequencies';

export const getWeeklyEmployeeSalary = (wage, wageFrequency) => {
    return getYearlySalary(wage, wageFrequency) / 52;
};

export const getMonthlyEmployeeSalary = (wage, wageFrequency) => {
    return getYearlySalary(wage, wageFrequency) / 12;
};

export const getYearlySalary = (wage, wageFrequency) => {
    const frequency = wageFrequency && wageFrequency.toUpperCase();
    let result;
    if (frequency === HOUR) {
        result =  wage * 40 * 52;
    } else if (frequency === WEEK) {
        result = wage * 52;
    } else if (frequency === MONTH) {
        result = wage * 12;
    } else if (frequency === BIWEEKLY) {
        result = wage * 26;
    } else if (frequency === SEMIMONTHLY) {
        result = wage * 2 * 12;
    } else {
        result = wage;
    }

    return result;
};