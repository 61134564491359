import {combineReducers} from 'redux';
import memberElectionReducer from './MemberElectionReducer';
import policyDataReducer from './PolicyDataReducer';
import contributionDataReducer from './ContributionDataReducer';
import benefitSummaryDataReducer from './BenefitSummaryDataReducer';

const reducer = () => combineReducers({
    benefitSummaryData: benefitSummaryDataReducer,
    contributionData: contributionDataReducer,
    memberElection: memberElectionReducer,
    policyData: policyDataReducer
});

export default reducer;