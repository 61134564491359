import {useVisionCoveragePopulator} from '../populator/useVisionCoveragePopulator';
import {useDispatch} from 'react-redux';

export const useVisionCoverageDispatcher = () => {
    const populateVisionCoverage = useVisionCoveragePopulator();
    const dispatch = useDispatch();

    const dispatchCoverage = (selectedRate) => {
        const data = populateVisionCoverage(selectedRate);
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
    };

    return (selectedRate) => dispatchCoverage(selectedRate);
};