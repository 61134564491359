import axios from 'axios';
import {getBaseUrl} from './UtilRepository';

export const getMemberElectionData = async (accessToken, isOkta) => {
    const url = await getBaseUrl(isOkta);
    const response = await axios.get(
        `${url}/memberElection`,
        {headers: {Authorization: `Bearer ${accessToken}`}}
    );
    return response.data;
};

export const postMemberElectionData = async (accessToken, data, isOkta) => {
    const url = await getBaseUrl(isOkta);
    const response = await axios.post(
        `${url}/memberElection`,
        data,
        {headers: {Authorization: `Bearer ${accessToken}`}}
    );
    return response.status;
};