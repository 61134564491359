export const mapCensusBenefitCoverageName = (productName) => {
    switch (productName) {
        case 'Life':
            return 'Group Term Life You';
        case 'Dependent Life':
            return 'Group Term Life Family';
        case 'Voluntary Life':
            return 'Voluntary Term Life You';
        case 'Voluntary Spouse Life':
            return 'Voluntary Term Life Spouse';
        case 'Voluntary Child Life':
            return 'Voluntary Term Life Child(ren)';
        case 'Critical Illness':
            return 'Critical Illness You';
        case 'Spouse Critical Illness':
            return 'Critical Illness Spouse';
        case 'Child Critical Illness':
            return 'Critical Illness Child(ren)';
        default:
            return productName;
    }
};
