import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthService} from './service/AuthService';
import {cognitoAuthSettings, oktaAuthSettings} from './config/AuthSettings';
import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './redux/reducer/Reducer';
import thunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';

const main = async () => {
    const history = createBrowserHistory();

    const store = createStore(
        reducer(),
        compose(
            applyMiddleware(thunk),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
    const cognitoSettings = await cognitoAuthSettings();
    const oktaSettings = await oktaAuthSettings();
    const authService = AuthService.createFromSettings(cognitoSettings);
    const oktaAuthService = AuthService.createFromSettings(oktaSettings);

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App authService={authService} oktaAuthService={oktaAuthService} history={history}/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );

    // expose store when run in Cypress
    if (window.Cypress) {
        window.store = store;
    }
};

main().catch(err => console.error(err));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
