export const getDependentCoverage = (coverages, memberCoverage) => {
    return coverages
        .find(dependentCoverage => dependentCoverage.memberGroupKey === memberCoverage.memberGroupKey &&
            dependentCoverage.product.productType === memberCoverage.product.productType);
};

export const removeDependentCoverage = (dependent, benefitKey) => {
    return {
        ...dependent,
        coverages: dependent.coverages.filter(coverageBenefitKey => coverageBenefitKey !== benefitKey)
    };
};

export const removeExclusionKey = (dependent, exclusionKeyName) => {
    const dependentWithoutExclusionKey = {...dependent};
    delete dependentWithoutExclusionKey[exclusionKeyName];
    return dependentWithoutExclusionKey;
};

export const addCoverageToDependent = (dependent, benefitKey) => {
    return dependent.coverages.find(coverageBenefitKey => coverageBenefitKey === benefitKey)
        ? {...dependent}
        : {...dependent, coverages: [...dependent.coverages, benefitKey]};
};