import React from 'react';
import Header from './common/header/Header';
import Footer from './common/Footer';
import Error from './view/Error';

const ErrorPage = () => {
    return <div className='container' style={{position: 'relative', minHeight: '75vh'}}>
        <Header showLogout={false}/>
        <div style={{paddingBottom: '90px'}}>
            <Error/>
        </div>
        <Footer/>
    </div>;
};

export default ErrorPage;