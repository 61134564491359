import React from 'react';
import {DefaultDisclosure} from './DefaultDisclosure';
import {CaliforniaDisclosure} from './CaliforniaDisclosure';
import {useSelector} from 'react-redux';

const EConsentDisclosure = () => {
    const issuerState = useSelector(issuerStateSelector);

    return <div className='disclosure-scroll'>
        <h3>Disclosure and Consent Regarding Conducting Business Electronically</h3>
        {issuerState === 'California'
            ? <CaliforniaDisclosure/>
            : <DefaultDisclosure/>}
    </div>;
};

export const issuerStateSelector = (state) => {
    return state.policyData && state.policyData.billGroupData && state.policyData.billGroupData.policy.contractState;
};

export default EConsentDisclosure;

