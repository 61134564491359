import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {criticalIllnessRateSelector} from '../../redux/selectors/CriticalIllnessRateSelector';
import {useDependentPopulator} from './useDependentPopulator';
import {CRITICAL_ILLNESS_ENSPACEN} from '../../properties/coverages';
import CurrencyUtil from '../../utils/currencyUtil';

export const useCriticalIllnessCoveragePopulator = () => {
    const criticalIllnessCoverage = useSelector(criticalIllnessRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateDependents = useDependentPopulator();

    const populateEnrolledCoverage = (selectedRate, selectedBenefitKey) => {
        const enrolledCoverages = buildEnrolledCoverages(selectedBenefitKey, selectedRate);
        const dependents = populateDependents(selectedBenefitKey, criticalIllnessCoverage);

        return {
            enrolledCoverages: enrolledCoverages,
            benefitOptions: memberElection.benefitOptions
                .filter(benefitOption => !benefitOption.labelAliasName.includes('MEDICAL_COVERAGE')),
            familyDemographics: {dependents: dependents},
        };
    };

    const buildEnrolledCoverages = (selectedBenefitKey, selectedRate) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== selectedBenefitKey);

        enrolledCoverages.push({
            benefitKey: selectedBenefitKey,
            esclKey: criticalIllnessCoverage.esclKey,
            memberGroupKey: criticalIllnessCoverage.memberGroupKey,
            coverageName: CRITICAL_ILLNESS_ENSPACEN,
            tierDisplayName: getTierDisplayName(selectedBenefitKey),
            rate: selectedRate.rate,
            amount: selectedRate.value,
            benefit: CurrencyUtil.formatUSD(selectedRate.value),
            guaranteedIssueAmount: getGuaranteedIssueAmount(selectedBenefitKey),
            dependent: !isEmployeeCoverage(selectedBenefitKey)
        });
        return enrolledCoverages;
    };

    const getTierDisplayName = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? 'You'
            : isSpouseCoverage(selectedBenefitKey)
                ? 'Spouse'
                : 'Child(ren)';
    };

    const getGuaranteedIssueAmount = (selectedBenefitKey) => {
        return isEmployeeCoverage(selectedBenefitKey)
            ? criticalIllnessCoverage.employeeGuaranteedIssueAmount
            : isSpouseCoverage(selectedBenefitKey)
                ? criticalIllnessCoverage.spouseGuaranteedIssueAmount
                : null;
    };

    const isEmployeeCoverage = (selectedBenefitKey) => {
        return selectedBenefitKey === criticalIllnessCoverage.benefitKey;
    };

    const isSpouseCoverage = (selectedBenefitKey) => {
        return selectedBenefitKey === criticalIllnessCoverage.spouseBenefitKey;
    };

    return (selectedRate, selectedBenefitKey) => populateEnrolledCoverage(selectedRate, selectedBenefitKey);
};