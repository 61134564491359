import React from 'react';
import DependentDemographics from '../dependent/DependentDemographics';
import Button from '../common/form/Button';
import {useSelector} from 'react-redux';
import {guid} from '../../utils/guidUtil';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import FormError from '../common/warning/FormError';
import {useKeypress} from '../../hook/effect/useKeypress';

const FamilyDemographics = (props) => {
    const memberElection = useSelector(memberElectionSelector);
    const [dependents, setDependents] = React.useState([]);
    const [familyDemographicsIsValid, setFamilyDemographicsIsValid] = React.useState(true);
    const [clearEnrolledCoverages, setClearEnrolledCoverages] = React.useState(false);
    const hasBenefitElections = Boolean(memberElection.enrolledCoverages) && memberElection.enrolledCoverages.length > 0;

    React.useEffect(() => {
        const existingDependents = memberElection.familyDemographics.dependents ?? [];
        setDependents(existingDependents.map(dependent => ({
            ...dependent,
            editable: false,
            guid: dependent.guid
                ? dependent.guid
                : guid()
        })));
    }, [memberElection]);

    useKeypress('Enter',
        () => validatePage(dependents, setFamilyDemographicsIsValid) && saveAndConfirm(props, dependents),
        [dependents, setFamilyDemographicsIsValid, props]
    );

    return <div className='container'>
        <div className='row'>
            {buildDependentPanels(dependents, setDependents, hasBenefitElections, setClearEnrolledCoverages)}
            <div id='familyDemographicsFormErrorDiv'
                 data-gtm='call-to-action'
                 style={{textAlign: 'center'}}
                 className={familyDemographicsIsValid ? 'col-md-8' : 'col-md-8 has-error'}>
                <FormError errorText='Please finish entering family data'/>
            </div>
        </div>
        <div className='row'>
            <button id='addDependentButton'
                    data-gtm='call-to-action'
                    className='btn btn-link'
                    style={{outline: 'none'}}
                    onClick={() => addDependentPanel(dependents, setDependents)}
            >+ Add a family member
            </button>
        </div>
        <div className='row col-md-12'>
            <hr className='dotted'/>
            <Button
                next
                clickText={'Next'}
                onClick={() => {
                    validatePage(dependents, setFamilyDemographicsIsValid) &&
                    saveAndConfirm(props, dependents, clearEnrolledCoverages);
                }}
            >
                {'Next '}
            </Button>
            <Button
                className='pull-left'
                clickText={'Back'}
                back
                onClick={() => {
                    props.onCancel();
                }}
            >
                {' Back'}
            </Button>
        </div>
    </div>;
};

const addDependentPanel = (dependents, setDependents) => {
    setDependents([...dependents, {editable: true, guid: guid()}]);
};

const buildDependentPanels = (dependents, setDependents, hasBenefitElections, setClearEnrolledCoverages) => {
    return dependents && dependents.map((dependent, index) => (
        <DependentDemographics
            key={dependent.guid}
            index={index}
            dependent={dependent}
            dependents={dependents}
            setDependents={setDependents}
            hasBenefitElections={hasBenefitElections}
            setClearEnrolledCoverages={setClearEnrolledCoverages}
        />
    ));
};

const validatePage = (dependents, setFamilyDemographicsIsValid) => {
    const noDependentsAreEditable = dependents.every(dependent => !dependent.editable);
    setFamilyDemographicsIsValid(noDependentsAreEditable);
    return noDependentsAreEditable;
};

const clearDependentCoverages = (dependents) => {
    return dependents.map(dependent => ({
        ...dependent,
        dentalBenefitExclusionKey: undefined,
        visionBenefitExclusionKey: undefined,
        accidentBenefitExclusionKey: undefined,
        coverages: []
    }));
};

const saveAndConfirm = (props, dependents, clearEnrolledCoverages) => {
    let data = clearEnrolledCoverages
        ? {
            familyDemographics: {dependents: clearDependentCoverages(dependents)},
            enrolledCoverages: [],
            benefitOptions: []
        }
        : {familyDemographics: {dependents: [...dependents]}};
    props.save(data);
    props.onConfirm();
};

export default FamilyDemographics;