export const DENTAL_SORT_CODES = ['F10', 'F30'];
export const DEPENDENT_DENTAL_SORT_CODES = ['F11', 'F31'];

export const HOSPITAL_INDEMNITY_SORT_CODES = ['K01','K03', 'K05', 'K07'];
export const DEPENDENT_HOSPITAL_INDEMNITY_SORT_CODES = ['K02','K04', 'K06', 'K08'];

export const VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES = ['K03', 'K05', 'K07'];

export const VISION_SORT_CODES = ['V01', 'V03'];
export const DEPENDENT_VISION_SORT_CODES = ['V02', 'V04'];

export const ACCIDENT_SORT_CODES = ['C14'];
export const DEPENDENT_ACCIDENT_SORT_CODES = ['C15'];

export const EMPLOYEE_CRITICAL_ILLNESS_SORT_CODE = 'CI1';
export const SPOUSE_CRITICAL_ILLNESS_SORT_CODE = 'CI2';
export const CHILD_CRITICAL_ILLNESS_SORT_CODE = 'CI3';

export const EMPLOYEE_GROUP_TERM_LIFE_SORT_CODE = 'A10';
export const DEPENDENT_GROUP_TERM_LIFE_SORT_CODE = 'A30';

export const EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE = 'A20';

export const SHORT_TERM_DISABILITY_SORT_CODE = 'C11';
export const VOLUNTARY_SHORT_TERM_DISABILITY_SORT_CODE = 'C13';
export const SHORT_TERM_DISABILITY_SORT_CODES = [SHORT_TERM_DISABILITY_SORT_CODE, VOLUNTARY_SHORT_TERM_DISABILITY_SORT_CODE];

export const LONG_TERM_DISABILITY_SORT_CODE = 'D11';
export const VOLUNTARY_LONG_TERM_DISABILITY_SORT_CODE = 'D13';
export const LONG_TERM_DISABILITY_SORT_CODES = [LONG_TERM_DISABILITY_SORT_CODE, VOLUNTARY_LONG_TERM_DISABILITY_SORT_CODE];

export const EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE = 'B10';
export const SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE = 'B30';
export const CHILD_VOLUNTARY_TERM_LIFE_SORT_CODE = 'B50';

export const EMPLOYEE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE = 'B20';
export const SPOUSE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE = 'B40';

export const ALL_ADND_SORT_CODES = [EMPLOYEE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE, SPOUSE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE, EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE];

export const VOLUNTARY_TERM_LIFE_SORT_CODES = [EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE, SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE];

export const INCREMENT_PLAN_TYPE = 'Increment';
export const MULTIPLE_OF_PAY_PLAN_TYPE = 'MultipleOfPay';
export const PERCENT_OF_SALARY_PLAN_TYPE = 'PercentOfSalary';
export const FLAT_PLAN_TYPE = 'Flat';

export const NONE = 'None';
export const ACCIDENT = 'Accident';
export const CRITICAL_ILLNESS = 'CriticalIllness';
export const CRITICAL_ILLNESS_ENSPACEN = 'Critical Illness';
export const GROUP_TERM_LIFE = 'GroupTermLife';
export const GROUP_TERM_LIFE_ENSPACEN = 'Group Term Life';
export const LONG_TERM_DISABILITY = 'LongTermDisability';
export const LONG_TERM_DISABILITY_ENSPACEN = 'Long Term Disability';
export const LONG_TERM_DISABILITY_DASHED = 'Long-Term Disability';
export const SHORT_TERM_DISABILITY = 'ShortTermDisability';
export const SHORT_TERM_DISABILITY_ENSPACEN = 'Short Term Disability';
export const SHORT_TERM_DISABILITY_DASHED = 'Short-Term Disability';
export const VOLUNTARY_TERM_LIFE = 'VoluntaryTermLife';
export const VOLUNTARY_TERM_LIFE_ENSPACEN = 'Voluntary Term Life';
export const DENTAL = 'Dental';
export const VISION = 'Vision';
export const HOSPITAL_INDEMNITY = 'HospitalIndemnity';
export const HOSPITAL_INDEMNITY_ENSPACEN = 'Hospital Indemnity';
export const VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN = 'Voluntary Hospital Indemnity';

export const CUSTOM_COVERAGE_NAMES = [CRITICAL_ILLNESS_ENSPACEN, VOLUNTARY_TERM_LIFE_ENSPACEN, GROUP_TERM_LIFE_ENSPACEN];

export const TIER_COVERAGE_NAMES = [DENTAL, VISION, ACCIDENT, HOSPITAL_INDEMNITY_ENSPACEN, VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN];