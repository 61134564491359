import React from 'react';
import {useSelector} from 'react-redux';
import Button from '../common/form/Button';
import CoverageTable from '../review/CoverageTable';
import {waivedCoverageSelector} from '../../redux/selectors/WaivedCoverageSelector';
import {SUBMITTED} from '../../properties/electionStatuses';
import moment from 'moment';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES} from '../../properties/coverages';
import {getVHOSDisplayName} from '../../utils/hosUtil';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';

const Review = (props) => {
    const hospitalIndemnityCoverages = useSelector(hospitalIndemnityRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const waivedCoverages = useSelector(waivedCoverageSelector);

    return <div className='container'>
        <div className='row col-md-8'>
            <div className='panel'>
                <div className='panel-heading-dashboard'>
                    Selected benefits
                </div>
                <div className='panel-body'>
                    <div>
                        {Boolean(memberElection.enrolledCoverages.length)
                            ? <CoverageTable
                                shouldShowAdditionalColumns
                                shouldShowCostData
                                enrolledCoverages={memberElection.enrolledCoverages}
                            />
                            : <h3 id='reviewNoBenefit'>No benefit selections have been made</h3>}
                    </div>
                </div>
            </div>
        </div>
        {Boolean(waivedCoverages && waivedCoverages.length) &&
        <div id='reviewWaivedCoverageDiv' className='row col-md-8'>
            <div className='panel'>
                <div className='panel-heading-dashboard'>
                    Waived benefits
                </div>
                <div className='panel-body'>
                    <div>
                        <div>
                            <table id='waivedCoverageTable' className='table'>
                                <thead>
                                <tr id='reviewHeaderRow'>
                                    <th>Product</th>
                                </tr>
                                </thead>
                                <tbody>
                                {waivedCoverages
                                    .sort((coverage1, coverage2) => coverage1.product.productType.localeCompare(coverage2.product.productType))
                                    .map(waivedCoverage => <tr key={waivedCoverage.benefitKey}>
                                        <td>{VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES.includes(waivedCoverage.product.compassGroupSortCode)
                                            ? getVHOSDisplayName(hospitalIndemnityCoverages, waivedCoverage)
                                            : waivedCoverage.product.productType}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <div className='row col-md-12'>
            <hr className='dotted'/>
            <Button
                next
                clickText={'Submit'}
                onClick={() => {
                    props.save({
                        memberDemographics: memberElection.memberDemographics,
                        familyDemographics: memberElection.familyDemographics,
                        enrolledCoverages: memberElection.enrolledCoverages,
                        benefitOptions: memberElection.benefitOptions,
                        electionStatus: SUBMITTED,
                        signatureDate: moment().format('MM/DD/YYYY')});
                    props.onConfirm();
                }}
            >
                {'Submit '}
            </Button>
            <Button
                className='pull-left'
                clickText={'Back'}
                back
                onClick={() => props.onCancel()}
            >
                {' Back'}
            </Button>
        </div>
    </div>;
};

export default Review;