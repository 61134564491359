import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {NON_WAIVABLE_COVERAGE_MESSAGE, WAIVABLE_COVERAGE_MESSAGE} from './ElectionUtil';
import DropDownField from '../common/form/DropDownField';
import Button from '../common/form/Button';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeaderWithAmount from '../common/header/CoverageTableHeaderWithAmount';
import {INCREMENT_PLAN_TYPE} from '../../properties/coverages';
import {shortTermDisabilityRateSelector} from '../../redux/selectors/ShortTermDisabilityRateSelector';
import {SHORT_TERM_DISABILITY_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useShortTermDisabilityCoverageDispatcher} from '../../hook/dispatch/useShortTermDisabilityCoverageDispatcher';
import {useDisabilityCoverageRemover} from '../../hook/remover/useDisabilityCoverageRemover';
import {EoiInfo} from '../common/warning/EoiInfo';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const ShortTermDisabilityElection = () => {
    const shortTermDisabilityCoverage = useSelector(shortTermDisabilityRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatchShortTermDisability = useShortTermDisabilityCoverageDispatcher();
    const removeShortTermDisability = useDisabilityCoverageRemover();
    const downloadBenefitSummary = useBenefitSummaryService();

    const buildCoverageSummary = () => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Can you or your family go without a paycheck for 2 weeks? 4 weeks? 6 weeks?</li>
                <li>Many things can cause you to miss extended periods of work: extended illness, accidents, or
                    maternity leave.
                </li>
                <li>Short Term Income Protection helps you by replacing a portion of your paycheck if you're too sick or
                    hurt to work.
                </li>
                <li>To learn more about what
                    your short term disability benefit
                    provides you, please download
                    the
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(shortTermDisabilityCoverage.memberGroupKey, shortTermDisabilityCoverage.product.prefix)}
                    >
                        {' benefit summary'}
                    </a>{' and watch this '}
                    <a
                        target='_blank'
                        href={SHORT_TERM_DISABILITY_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildTable = () => {
        return <div key={`shortTermDisabilityElection-rate${shortTermDisabilityCoverage.esclKey}`}>
            {buildCoverageSummary()}
            <div>
                {<table className='table'>
                    <CoverageTableHeaderWithAmount/>
                    {buildTableBody()}
                </table>}
                <EoiInfo coverage={shortTermDisabilityCoverage}/>
                <div id='shortTermDisabilityElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {shortTermDisabilityCoverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = () => {
        return <tbody>
        {buildRow(shortTermDisabilityCoverage.employeeRates, shortTermDisabilityCoverage.benefitKey)}
        </tbody>;
    };

    const buildRow = (rates, coverageBenefitKey) => {
        return <tr key={`shortTermDisabilityElection${coverageBenefitKey}-row`}
                   id={`shortTermDisabilityElection${coverageBenefitKey}-row`}
                   className={hasElectedCoverage(coverageBenefitKey) ? 'bg-light-blue' : ''}>
            <td id={`shortTermDisabilityElection${coverageBenefitKey}-displayName`}>You</td>
            <td>
                {shortTermDisabilityCoverage.planType === INCREMENT_PLAN_TYPE
                    ? <DropDownField
                        ariaLabel='Short Term Disability Amount'
                        value={memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                            ? memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount
                            : rates[0].value}
                        options={rates}
                        onChange={(e) => dispatchShortTermDisability(rates.find(rate => rate.value === parseInt(e.target.value)))}
                        disabled={!hasElectedCoverage(coverageBenefitKey)}
                    />
                    :
                    <span>{memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                        ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount)
                        : CurrencyUtil.formatUSD(rates[0].value)}
                    </span>}
            </td>
            <td id={`shortTermDisabilityElection${coverageBenefitKey}-rate`}>{
                memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages
                        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).rate)
                    : CurrencyUtil.formatUSD(rates[0].rate)
            }</td>
            <td>
                <Button
                    clickText={hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={hasElectedCoverage(coverageBenefitKey)}
                    onClick={() => hasElectedCoverage(coverageBenefitKey)
                        ? removeShortTermDisability(coverageBenefitKey)
                        : dispatchShortTermDisability(rates[0])}>
                    <i style={{paddingRight: '5px'}}
                       className={hasElectedCoverage(coverageBenefitKey) ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const hasElectedCoverage = (benefitKey) => memberElection.enrolledCoverages
        && memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === benefitKey);

    return <div className='col-md-12'>
        {buildTable()}
    </div>;
};

export default ShortTermDisabilityElection;