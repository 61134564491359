import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {visionRateSelector} from '../../redux/selectors/VisionRateSelector';
import Button from '../common/form/Button';
import {
    hasChildDependent,
    hasSpouseDependent,
    NON_WAIVABLE_COVERAGE_MESSAGE,
    WAIVABLE_COVERAGE_MESSAGE
} from './ElectionUtil';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeader from '../common/header/CoverageTableHeader';
import {VISION_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useTieredCoverageRemover} from '../../hook/remover/useTieredCoverageRemover';
import {useVisionCoverageDispatcher} from '../../hook/dispatch/useVisionCoverageDispatcher';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const VisionElection = () => {
    const visionCoverage = useSelector(visionRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const downloadBenefitSummary = useBenefitSummaryService();
    const dispatchVisionCoverage = useVisionCoverageDispatcher();
    const removeVisionCoverage = useTieredCoverageRemover();

    const buildCoverageSummary = (coverage) => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Provides full or partial coverage for vision exams and hardware (frames, lenses, contacts); not
                    intended
                    to cover 100% of all vision costs.
                </li>
                <li>Gives you access to a network of providers who discount fees for services.</li>
                <li>{'To learn more about what your vision benefit provides you, please download the '}
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(coverage.memberGroupKey, coverage.product.voluntary ? 'VOLUNTARY_VISION' : 'VISION')}
                    >
                        {'benefit summary'}
                    </a>{' and watch this '}
                    <a
                        target='_blank'
                        href={VISION_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildRow = (selectedRate, coverage, index) => {
        const activeTier = isActiveTier(coverage, selectedRate);
        return <tr key={`${selectedRate.tier}-row-${index}`} id={`${selectedRate.tier}-row`}
                   className={activeTier ? 'bg-light-blue' : ''}>
            <td id={`${selectedRate.tier}-displayName-${index}`}>{selectedRate.displayName}</td>
            <td id={`${selectedRate.tier}-rate`}>{CurrencyUtil.formatUSD(selectedRate.rate)}</td>
            <td>
                <Button
                    id={`${selectedRate.tier}-coverageButton-${index}`}
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={activeTier}
                    clickText={activeTier ? 'Remove' : 'Add'}
                    onClick={() => {
                        activeTier
                            ? removeVisionCoverage(coverage, 'visionBenefitExclusionKey')
                            : dispatchVisionCoverage(selectedRate);
                    }}
                >
                    <i style={{paddingRight: '5px'}} className={activeTier ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {activeTier ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const buildTable = (coverage) => {
        return <div key={`visioncoverage-rate${coverage.esclKey}`}>
            {buildCoverageSummary(coverage)}
            <div>
                {<table className='table'>
                    <CoverageTableHeader/>
                    {buildTableBody(coverage)}
                </table>}
                <div id='visionElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {coverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = (coverage) => {
        let rateList = coverage.rates;
        return <tbody>
        {rateList
            .filter(rates => shouldDisplayRow(rates, memberElection.familyDemographics.dependents, coverage.benefitOptions))
            .map((rates, index) => buildRow(rates, coverage, index))}
        </tbody>;
    };

    const isActiveTier = (coverage, rates) => {
        return memberElection.enrolledCoverages
            && memberElection.enrolledCoverages
                .some(electedCoverage => electedCoverage.esclKey === coverage.esclKey
                    && electedCoverage.tier === rates.tier
                    && electedCoverage.tierDisplayName === rates.displayName);
    };

    const shouldDisplayRow = (rate, dependents, benefitOptions) => {
        if (rate.displayName === 'You and your spouse') {
            return hasSpouseDependent(dependents, benefitOptions);
        } else if (rate.displayName === 'You and your children') {
            return hasChildDependent(dependents);
        } else if (rate.displayName === 'You and your family') {
            return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents);
        } else {
            return rate.displayName === 'You';
        }
    };

    return <div className='col-md-12'>
        {buildTable(visionCoverage)}
    </div>;
};

export default VisionElection;