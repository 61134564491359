import React from 'react';
import CurrencyUtil from '../../utils/currencyUtil';
import {EoiWarning} from '../common/warning/EoiWarning';
import {TIER_COVERAGE_NAMES} from '../../properties/coverages';

const CoverageTable = (props) => {

    const render = () => {
        return <table id='reviewTable' className='table' style={{marginBottom: 0}}>
            {buildTableHeader()}
            {buildTableBody()}
            {buildTableFooter()}
        </table>;
    };

    const buildRow = (coverage) => {
        return <tr key={coverage.benefitKey}>
            <td id={`${coverage.coverageName}-displayName`}>{coverage.coverageName}</td>
            {props.shouldShowAdditionalColumns &&
            <>
                <td id={`${coverage.coverageName}-tierDisplayName`}>{coverage.tierDisplayName}</td>
                <td id={`${coverage.coverageName}-benefit`}>{coverage.benefit}
                    {coverage.guaranteedIssueAmount !== null && parseInt(coverage.amount) > coverage.guaranteedIssueAmount && '*'}
                </td>
            </>
            }
            {props.shouldShowCostData &&
            <td id={`${coverage.coverageName}-rate`}>{CurrencyUtil.formatUSD(coverage.rate)}</td>}
        </tr>;
    };

    const buildTableBody = () => {
        return <tbody>
        {props.enrolledCoverages && props.enrolledCoverages
            .filter(coverage => !coverage.accidentalDeathAndDismemberment)
            .filter(coverage => !coverage.dependent || !TIER_COVERAGE_NAMES.includes(coverage.coverageName))
            .sort((coverage1, coverage2) => coverage1.coverageName.localeCompare(coverage2.coverageName))
            .map(coverage => buildRow(coverage))}
        </tbody>;
    };

    const buildTableFooter = () => {
        return props.shouldShowCostData &&
            <tfoot>
                <tr id='coverageTableFooterRow'>
                    <td colSpan={props.shouldShowAdditionalColumns ? '3' : '1'}><strong>Total</strong></td>
                    <td>
                        <strong>
                            {CurrencyUtil.formatUSD(props.enrolledCoverages
                                .filter(coverge => Boolean(coverge.rate))
                                .map(coverage => coverage.rate)
                                .reduce((firstRate, secondRate) => firstRate + secondRate, 0))}
                        </strong>
                    </td>
                </tr>
                <tr className='small'>
                    {props.shouldShowAdditionalColumns &&
                    <td colSpan='4' id='coverageTableGuaranteedWarning'>
                        <EoiWarning
                            shouldHavePromotionalBanner={props.shouldHavePromotionalBanner}
                            display={props.enrolledCoverages
                                .some(coverage => coverage.guaranteedIssueAmount !== null
                                    && parseInt(coverage.amount) > coverage.guaranteedIssueAmount)}/>
                    </td>}
                </tr>
            </tfoot>;
    };

    const buildTableHeader = () => {
        return <thead>
        <tr id='coverageTableHeaderRow'>
            <th>Product</th>
            {props.shouldShowAdditionalColumns &&
            <>
                <th>Who's covered</th>
                <th>Your benefit</th>
            </>}
            {props.shouldShowCostData &&
            <th>Cost per month</th>}
        </tr>
        </thead>;
    };

    return render();
};

export default CoverageTable;