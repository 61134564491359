import {numeric} from '../stringUtil';
import {BENEFIT_MINIMUM, BENEFIT_PERCENT} from '../../properties/benefitOptions';
import moment from 'moment';
import CurrencyUtil from '../currencyUtil';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import {getRateFactor} from './getRateFactorUtil';
import {getBenefitMaximum} from './getBenefitMaximumUtil';

export const buildPercentOfSalaryRates = (state, rate, benefitOptions, dateOfBirth, smoker, gender, earnings, benefitKey, sortCode) => {
    const benefitMinimum = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_MINIMUM)));
    const benefitMaximum = getBenefitMaximum(benefitOptions);

    const benefitPercentOfSalary = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_PERCENT))) / 100;
    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const age = moment().diff(moment(dateOfBirth, 'MM/DD/YYYY'), 'years');
    const rateFactor = getRateFactor(rate, age, smoker, gender, sortCode);

    const roundedPercentOfEarnings = Math.round(earnings * benefitPercentOfSalary);
    const percentOfSalary = Math.max(Math.min(roundedPercentOfEarnings, benefitMaximum), benefitMinimum);

    return [{
        display: CurrencyUtil.formatUSD(percentOfSalary, {minimumFractionDigits: 2}),
        value: percentOfSalary,
        rate: getRate(percentOfSalary, rate.rateVolumeUnit, rateFactor, contributionDollar, contributionPercent)
    }];
};

const getRate = (percentOfSalary, rateVolumeUnit, rateFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round(((percentOfSalary / rateVolumeUnit * rateFactor) - contributionDollar) * 100) / 100
        : Math.round(((percentOfSalary / rateVolumeUnit * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};