import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {longTermDisabilityRateSelector} from '../../redux/selectors/LongTermDisabilityRateSelector';
import {LONG_TERM_DISABILITY_ENSPACEN} from '../../properties/coverages';
import CurrencyUtil from '../../utils/currencyUtil';

export const useLongTermDisabilityCoveragePopulator = () => {
    const longTermDisabilityCoverage = useSelector(longTermDisabilityRateSelector);
    const memberElection = useSelector(memberElectionSelector);

    const populateEnrolledCoverage = (selectedRate) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== longTermDisabilityCoverage.benefitKey);

        enrolledCoverages.push({
            benefitKey: longTermDisabilityCoverage.benefitKey,
            esclKey: longTermDisabilityCoverage.esclKey,
            memberGroupKey: longTermDisabilityCoverage.memberGroupKey,
            coverageName: LONG_TERM_DISABILITY_ENSPACEN,
            tierDisplayName: 'You',
            rate: selectedRate.rate,
            amount: selectedRate.value,
            benefit: CurrencyUtil.formatUSD(selectedRate.value),
            voluntary: longTermDisabilityCoverage.product.voluntary,
            guaranteedIssueAmount: getGuaranteedIssueAmount(),
            dependent: false
        });

        return {enrolledCoverages: enrolledCoverages};
    };

    const getGuaranteedIssueAmount = () => {
        return longTermDisabilityCoverage.employeeGuaranteedIssueAmount
            ? longTermDisabilityCoverage.employeeGuaranteedIssueAmount
            : null;
    };

    return (selectedRate) => populateEnrolledCoverage(selectedRate);
};