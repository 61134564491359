import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {
    CUSTOM_COVERAGE_NAMES,
    DEPENDENT_ACCIDENT_SORT_CODES,
    DEPENDENT_DENTAL_SORT_CODES,
    DEPENDENT_HOSPITAL_INDEMNITY_SORT_CODES,
    DEPENDENT_VISION_SORT_CODES, VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN,
    VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES
} from '../../properties/coverages';
import CoverageTable from '../review/CoverageTable';
import FormWarning from './warning/FormWarning';
import React from 'react';
import {mapCensusBenefitCoverageName} from '../../utils/mapper/coverageNameMapperUtil';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';
import {getVHOSDisplayName} from '../../utils/hosUtil';

const ShoppingCart = () => {
    const memberElection = useSelector(memberElectionSelector);
    const hospitalIndemnityRates = useSelector(hospitalIndemnityRateSelector);

    const render = () => {
        return <>
            <div className='panel'>
                <div className='panel-heading-dashboard'>
                    Selected benefits
                </div>
                <div className='panel-body'>
                    <CoverageTable shouldShowCostData
                                   enrolledCoverages={mapEnrolledCoveragesForShoppingCart()}/>
                </div>
            </div>
            {shouldRender() &&
                <div className='panel'>
                    <div className='panel-heading-dashboard'>
                        Previously enrolled benefits
                    </div>
                    <div className='panel-body'>
                        <CoverageTable enrolledCoverages={mapCensusBenefitsForShoppingCart()}/>
                    </div>
                </div>
            }
            {shouldRender() &&
                <FormWarning
                    display={!Boolean(memberElection.censusMember.benefits
                        .every(censusBenefit => memberElection.enrolledCoverages
                            .some(enrolledCoverages => enrolledCoverages.benefitKey === censusBenefit.benefitKey)))}
                    warningText='You previously selected a coverage that is no longer elected. This may result in a loss of coverage after enrollment is complete.'
                />}
        </>;
    };

    const shouldRender = () => {
        return Boolean(memberElection.censusMember &&
            memberElection.censusMember.benefits &&
            mapCensusBenefitsForShoppingCart().length);
    };

    const mapEnrolledCoveragesForShoppingCart = () => {
        return memberElection.enrolledCoverages.map(enrolledCoverage => {
            if (CUSTOM_COVERAGE_NAMES.includes(enrolledCoverage.coverageName)) {
                return {
                    ...enrolledCoverage,
                    coverageName: enrolledCoverage.coverageName + ' ' + enrolledCoverage.tierDisplayName
                };
            }
            return enrolledCoverage;
        });
    };

    const mapCensusBenefitsForShoppingCart = () => {
        return memberElection.censusMember.benefits
            .filter(censusBenefit => censusBenefit.benefitStatus === 'Active')
            .filter(censusBenefit => !censusBenefit.product.accidentalDeathAndDismemberment)
            .filter(censusBenefit => !DEPENDENT_DENTAL_SORT_CODES.includes(censusBenefit.product.compassGroupSortCode))
            .filter(censusBenefit => !DEPENDENT_VISION_SORT_CODES.includes(censusBenefit.product.compassGroupSortCode))
            .filter(censusBenefit => !DEPENDENT_ACCIDENT_SORT_CODES.includes(censusBenefit.product.compassGroupSortCode))
            .filter(censusBenefit => !DEPENDENT_HOSPITAL_INDEMNITY_SORT_CODES.includes(censusBenefit.product.compassGroupSortCode))
            .map(previouslySelectedCoverage => {
                return {
                    benefitKey: previouslySelectedCoverage.benefitKey,
                    coverageName: VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES.includes(previouslySelectedCoverage.product.compassGroupSortCode)
                        ? getVHOSCoverageName(previouslySelectedCoverage)
                        : mapCensusBenefitCoverageName(previouslySelectedCoverage.productName)
                };
            });
    };

    const getVHOSCoverageName = (censusBenefit) => {
        const matchingCoverage = hospitalIndemnityRates
            .find(coverage => coverage.benefitKey === censusBenefit.benefitKey);
        return matchingCoverage
            ? getVHOSDisplayName(hospitalIndemnityRates, matchingCoverage)
            : VOLUNTARY_HOSPITAL_INDEMNITY_ENSPACEN;
    };

    return render();

};

export default ShoppingCart;