import {
    EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE,
    EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE,
    SPOUSE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE,
    SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE,
    EMPLOYEE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE
} from '../../properties/coverages';
import {getRateFactor} from './getRateFactorUtil';

export const getADnDIncludedRateFactor = (rate, age, smoker, gender, sortCode) => {
    let aDnDSortCode;
    switch (sortCode) {
        case EMPLOYEE_VOLUNTARY_TERM_LIFE_SORT_CODE:
            aDnDSortCode = EMPLOYEE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE;
            break;
        case SPOUSE_VOLUNTARY_TERM_LIFE_SORT_CODE:
            aDnDSortCode = SPOUSE_VOLUNTARY_TERM_LIFE_ADND_SORT_CODE;
            break;
        default:
            aDnDSortCode =  EMPLOYEE_GROUP_TERM_LIFE_ADND_SORT_CODE;
    }
    const coverageRateFactor = getRateFactor(rate, age, smoker, gender, sortCode);
    const coverageADnDRateFactor = getRateFactor(rate, age, smoker, gender, aDnDSortCode);
    return coverageRateFactor + coverageADnDRateFactor;
};