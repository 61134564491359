import React from 'react';
import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {NON_WAIVABLE_COVERAGE_MESSAGE, WAIVABLE_COVERAGE_MESSAGE} from './ElectionUtil';
import DropDownField from '../common/form/DropDownField';
import Button from '../common/form/Button';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeaderWithAmount from '../common/header/CoverageTableHeaderWithAmount';
import {INCREMENT_PLAN_TYPE} from '../../properties/coverages';
import {longTermDisabilityRateSelector} from '../../redux/selectors/LongTermDisabilityRateSelector';
import {LONG_TERM_DISABILITY_INFORMATION_LINK} from '../../properties/videos';
import {useBenefitSummaryService} from '../../hook/useBenefitSummaryService';
import {useLongTermDisabilityCoverageDispatcher} from '../../hook/dispatch/useLongTermDisabilityCoverageDispatcher';
import {useDisabilityCoverageRemover} from '../../hook/remover/useDisabilityCoverageRemover';
import {EoiInfo} from '../common/warning/EoiInfo';
import {pushTextLink} from '../../utils/googleAnalyticsUtil';

const LongTermDisabilityElection = () => {
    const longTermDisabilityCoverage = useSelector(longTermDisabilityRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const downloadBenefitSummary = useBenefitSummaryService();
    const dispatchLongTermDisability = useLongTermDisabilityCoverageDispatcher();
    const removeLongTermDisability = useDisabilityCoverageRemover();

    const buildCoverageSummary = () => {
        return <div className='col-md-12'>
            <br/>
            <ul style={{paddingLeft: '0'}}>
                <li>Being unable to work for an extended period of time may cause financial strain for you and your
                    family.
                </li>
                <li>Without a regular paycheck, you might have to make major changes: changing housing/living situation,
                    or borrow from friends or family.
                </li>
                <li>Long Term Income Protection will keep a steady income coming in while you recover from a long term
                    illness or injury.
                </li>
                {longTermDisabilityCoverage.benefitOptions
                    .find(option => option.label === 'Bonus Up Plan' && option.value === 'Yes')
                && <li id='longTermDisabilityBonusUpItem'>The premium amount displayed is the amount your employer is
                    paying for coverage by adding the cost to your W-2 as taxable income.</li>}
                <li>{'To learn more about what your long term disability benefit provides you, please download the '}
                    <a
                        href='#'
                        onClick={() => downloadBenefitSummary(longTermDisabilityCoverage.memberGroupKey, longTermDisabilityCoverage.product.prefix)}
                    >
                        {'benefit summary'}
                    </a>
                    {' and watch this '}
                    <a
                        target='_blank'
                        href={LONG_TERM_DISABILITY_INFORMATION_LINK}
                        onClick={() => pushTextLink('video')}
                    >
                        {'video'}
                    </a>
                    {'.'}
                </li>
            </ul>
        </div>;
    };

    const buildTable = () => {
        return <div key={`longTermDisabilityElection-rate${longTermDisabilityCoverage.esclKey}`}>
            {buildCoverageSummary()}
            <div>
                {<table className='table'>
                    <CoverageTableHeaderWithAmount/>
                    {buildTableBody()}
                </table>}
                <EoiInfo coverage={longTermDisabilityCoverage}/>
                <div id='longTermDisabilityElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {longTermDisabilityCoverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = () => {
        return <tbody>
        {buildRow(longTermDisabilityCoverage.employeeRates, longTermDisabilityCoverage.benefitKey)}
        </tbody>;
    };

    const buildRow = (rates, coverageBenefitKey) => {
        return <tr key={`longTermDisabilityElection${coverageBenefitKey}-row`}
                   id={`longTermDisabilityElection${coverageBenefitKey}-row`}
                   className={hasElectedCoverage(coverageBenefitKey) ? 'bg-light-blue' : ''}>
            <td id={`longTermDisabilityElection${coverageBenefitKey}-displayName`}>You</td>
            <td>
                {longTermDisabilityCoverage.planType === INCREMENT_PLAN_TYPE
                    ? <DropDownField
                        ariaLabel='Long Term Disability Amount'
                        value={memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                            ? memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount
                            : rates[0].value}
                        options={rates}
                        onChange={(e) => dispatchLongTermDisability(rates.find(rate => rate.value === parseInt(e.target.value)))}
                        disabled={!hasElectedCoverage(coverageBenefitKey)}
                    />
                    :
                    <span>{memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                        ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).amount)
                        : CurrencyUtil.formatUSD(rates[0].value)}
                    </span>}
            </td>
            <td id={`longTermDisabilityElection${coverageBenefitKey}-rate`}>{
                memberElection.enrolledCoverages.find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey)
                    ? CurrencyUtil.formatUSD(memberElection.enrolledCoverages
                        .find(enrolledCoverage => enrolledCoverage.benefitKey === coverageBenefitKey).rate)
                    : CurrencyUtil.formatUSD(rates[0].rate)
            }</td>
            <td>
                <Button
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={hasElectedCoverage(coverageBenefitKey)}
                    clickText={hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                    onClick={() => hasElectedCoverage(coverageBenefitKey)
                        ? removeLongTermDisability(coverageBenefitKey)
                        : dispatchLongTermDisability(rates[0])}
                >
                    <i style={{paddingRight: '5px'}}
                       className={hasElectedCoverage(coverageBenefitKey) ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {hasElectedCoverage(coverageBenefitKey) ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const hasElectedCoverage = (benefitKey) => memberElection.enrolledCoverages
        && memberElection.enrolledCoverages.some(enrolledCoverage => enrolledCoverage.benefitKey === benefitKey);

    return <div className='col-md-12'>
        {buildTable()}
    </div>;
};

export default LongTermDisabilityElection;