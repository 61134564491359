import {useAccidentCoveragePopulator} from '../populator/useAccidentCoveragePopulator';
import {useSelector} from 'react-redux';
import {accidentRateSelector} from '../../redux/selectors/AccidentRateSelector';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {getTierDisplayName} from '../../utils/mapper/coverageMapperUtil';
import {DEPENDENT_ACCIDENT_SORT_CODES} from '../../properties/coverages';

export const useAccidentCoverageMapper = () => {
    const accidentCoverage = useSelector(accidentRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const populateAccidentCoverage = useAccidentCoveragePopulator();

    const map = () => {
        const rateDisplayName = getTierDisplayName(memberElection.censusMember.dependents, DEPENDENT_ACCIDENT_SORT_CODES, accidentCoverage.rates);
        const currentRate = accidentCoverage.rates.find(rate => rate.displayName === rateDisplayName);
        return populateAccidentCoverage(currentRate);
    };

    return () => map();
};