import {useSelector} from 'react-redux';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {shortTermDisabilityRateSelector} from '../../redux/selectors/ShortTermDisabilityRateSelector';
import {SHORT_TERM_DISABILITY_ENSPACEN} from '../../properties/coverages';
import CurrencyUtil from '../../utils/currencyUtil';

export const useShortTermDisabilityCoveragePopulator = () => {
    const shortTermDisabilityCoverage = useSelector(shortTermDisabilityRateSelector);
    const memberElection = useSelector(memberElectionSelector);

    const populateEnrolledCoverage = (selectedRate) => {
        const enrolledCoverages = memberElection.enrolledCoverages
            .filter(enrolledCoverage => enrolledCoverage.benefitKey !== shortTermDisabilityCoverage.benefitKey);

        enrolledCoverages.push({
            benefitKey: shortTermDisabilityCoverage.benefitKey,
            esclKey: shortTermDisabilityCoverage.esclKey,
            memberGroupKey: shortTermDisabilityCoverage.memberGroupKey,
            coverageName: SHORT_TERM_DISABILITY_ENSPACEN,
            tierDisplayName: 'You',
            rate: selectedRate.rate,
            amount: selectedRate.value,
            benefit: CurrencyUtil.formatUSD(selectedRate.value),
            voluntary: shortTermDisabilityCoverage.product.voluntary,
            dependent: false
        });

        return {enrolledCoverages: enrolledCoverages};
    };

    return (selectedRate) => populateEnrolledCoverage(selectedRate);
};