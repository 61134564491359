import React from 'react';
import RequiredFieldIndicator from '../common/form/RequiredFieldIndicator';
import FormError from '../common/warning/FormError';
import {alphabeticWithSpacesHyphensAndApostrophes} from '../../utils/stringUtil';

const FirstLastNameInput = (props) => {

    return <div className={props.containerClass ? props.containerClass : 'col-md-5'}>
        <label id={props.id} style={{'width': '100%'}}
               className={props.isValid ? '' : 'has-error'}>
            {props.labelText} {props.required && <RequiredFieldIndicator/>}
            <input
                type='text'
                value={props.value}
                onChange={(e) => props.setValue(alphabeticWithSpacesHyphensAndApostrophes(e.target.value))}
                className='form-control'
                style={{textTransform: 'uppercase'}}
                maxLength={50}
                disabled={props.disabled}
                autoComplete='new-password'
            />
            <FormError errorText={`Please enter a valid ${props.labelText}`}/>
        </label>
    </div>;
};

export default FirstLastNameInput;