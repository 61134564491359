import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AuthCallback from '../component/auth/AuthCallback';
import ProtectedRoute from './ProtectedRoute';
import Home from '../component/Home';
import ErrorPage from '../component/ErrorPage';
import LogoutCallback from '../component/auth/LogoutCallback';
import Logout from '../component/auth/Logout';
import LoggedOut from '../component/auth/LoggedOut';
import Welcome from '../component/Welcome';
import {AUTH_TYPE_COGNITO, AUTH_TYPE_OKTA} from '../properties/auth';

const Routes = () => {
    return (
        <Switch>
            <Route path={'/.auth/logoutCallback'} render={() => <LogoutCallback auth={AUTH_TYPE_COGNITO}/>}/>
            <Route path={'/.auth/oktaLogoutCallback'} render={() => <LogoutCallback auth={AUTH_TYPE_OKTA}/>}/>
            <Route path={'/logout'} component={Logout}/>
            <Route path={'/loggedOut'} render={() => <LoggedOut auth={AUTH_TYPE_COGNITO}/>}/>
            <Route path={'/oktaLoggedOut'} render={() => <LoggedOut auth={AUTH_TYPE_OKTA}/>}/>
            <Route path={'/.auth/callback'} render={() => <AuthCallback auth={AUTH_TYPE_COGNITO}/>}/>
            <Route path={'/.auth/oktaCallback'} render={() => <AuthCallback auth={AUTH_TYPE_OKTA}/>}/>
            <Route path={'/error'} component={ErrorPage}/>
            <ProtectedRoute path={'/new'} component={Home} auth={AUTH_TYPE_COGNITO}/>
            <ProtectedRoute path={'/existing'} component={Home} auth={AUTH_TYPE_OKTA}/>
            <Route path={'/'} component={Welcome}/>
        </Switch>
    );
};

export default Routes;