import {useDispatch} from 'react-redux';
import {useHospitalIndemnityCoveragePopulator} from '../populator/useHospitalIndemnityCoveragePopulator';

export const useHospitalIndemnityCoverageDispatcher = () => {
    const populateHospitalIndemnityCoverage = useHospitalIndemnityCoveragePopulator();
    const dispatch = useDispatch();

    const dispatchCoverage = (selectedRate, coverage) => {
        const data = populateHospitalIndemnityCoverage(selectedRate, coverage);
        dispatch({
            type: 'UPDATE_MEMBER_ELECTION',
            data: data
        });
    };

    return (selectedRate, coverage) => dispatchCoverage(selectedRate, coverage);
};