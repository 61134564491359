import {numeric} from '../stringUtil';
import {BENEFIT_PERCENT, INCREMENT_AMOUNT, MINIMUM_ELECTION} from '../../properties/benefitOptions';
import moment from 'moment';
import CurrencyUtil from '../currencyUtil';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import {getRateFactor} from './getRateFactorUtil';
import {getBenefitMaximum} from './getBenefitMaximumUtil';

export const buildTermDisabilityIncrementRates = (state, rate, benefitOptions, dateOfBirth, smoker, gender, earnings, benefitKey, sortCode) => {
    const benefitMinimum = parseInt(numeric(getMatchingBenefitOption(benefitOptions, MINIMUM_ELECTION)));
    const benefitIncrement = parseInt(numeric(getMatchingBenefitOption(benefitOptions, INCREMENT_AMOUNT)));
    const benefitMaximum = getTermDisabilityBenefitMaximum(benefitOptions, benefitIncrement, earnings);

    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const age = moment().diff(moment(dateOfBirth, 'MM/DD/YYYY'), 'years');
    const rateFactor = getRateFactor(rate, age, smoker, gender, sortCode);

    const increments = Math.trunc((benefitMaximum - benefitMinimum) / benefitIncrement + 1);
    return Array(increments).fill().map((_, index) => {
        return {
            display: CurrencyUtil.formatUSD(benefitIncrement * index + benefitMinimum, {minimumFractionDigits: 0}),
            value: benefitIncrement * index + benefitMinimum,
            rate: getRate(benefitIncrement, index, benefitMinimum, rate, rateFactor, contributionDollar, contributionPercent)
        };
    });
};

const getRate = (benefitIncrement, index, benefitMinimum, rate, rateFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round((((benefitIncrement * index + benefitMinimum) / rate.rateVolumeUnit * rateFactor) - contributionDollar) * 100) / 100
        : Math.round((((benefitIncrement * index + benefitMinimum) / rate.rateVolumeUnit * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};

const getTermDisabilityBenefitMaximum = (benefitOptions, benefitIncrement, earnings) => {
    const benefitPercent = parseInt(numeric(getMatchingBenefitOption(benefitOptions, BENEFIT_PERCENT))) / 100;
    const salaryIncrementMaximum = Math.floor((earnings * benefitPercent) / benefitIncrement) * benefitIncrement;
    const benefitMaximum = getBenefitMaximum(benefitOptions);

    return Math.min(salaryIncrementMaximum, benefitMaximum);
};