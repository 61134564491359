import axios from 'axios';
import {getBaseUrl} from './UtilRepository';

export const getPolicyData = async (accessToken, isOkta) => {
    const url = await getBaseUrl(isOkta);
    const response = await axios.get(
        `${url}/policyData`,
        {headers: {Authorization: `Bearer ${accessToken}`}}
    );
    return response.data;
};