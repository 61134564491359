import {numeric, stripCents} from '../stringUtil';
import {getMatchingBenefitOption} from './getMatchingBenefitOptionUtil';
import moment from 'moment';
import {BENEFIT_MAXIMUM, BENEFIT_MINIMUM, INCREMENT_AMOUNT, MULTIPLE_OF_PAY} from '../../properties/benefitOptions';
import {getYearlySalary} from './getEmployeeSalaryUtil';
import {getADnDIncludedRateFactor} from './getADnDIncludedRateFactorUtil';
import {getContributionDollarRate, getContributionPercentRate} from './getContributionRateUtil';

export const buildMultipleOfPayRates = (state, rate, memberCoverage, benefitKey, sortCode) => {
    const employeeSalary = getYearlySalary(state.memberElection.wage, state.memberElection.wageFrequency);
    const benefitMinimum = parseInt(numeric(getMatchingBenefitOption(memberCoverage.benefitOptions, BENEFIT_MINIMUM)))
        || parseInt(numeric(getMatchingBenefitOption(memberCoverage.benefitOptions, INCREMENT_AMOUNT)));
    const benefitMaximum = parseInt(numeric(getMatchingBenefitOption(memberCoverage.benefitOptions, BENEFIT_MAXIMUM)));
    const multipleOfPayBenefitOption = numeric(stripCents(getMatchingBenefitOption(memberCoverage.benefitOptions, MULTIPLE_OF_PAY)));
    const allowedAmounts = memberCoverage.allowedAmounts.length ? memberCoverage.allowedAmounts : [multipleOfPayBenefitOption];

    const contributionPercent = getContributionPercentRate(state, rate.memberGroupKey, benefitKey);
    const contributionDollar = getContributionDollarRate(state, rate.memberGroupKey, benefitKey);

    const age = moment().diff(moment(state.memberElection.memberDemographics.dateOfBirth, 'MM/DD/YYYY'), 'years');
    const rateFactor = getADnDIncludedRateFactor(rate, age, state.memberElection.memberDemographics.smoker, state.memberElection.memberDemographics.gender, sortCode);

    const rateVolume = rate.rateVolumeUnit;
    let payRates = buildPayRates(allowedAmounts, employeeSalary, rateVolume, rateFactor, contributionDollar, contributionPercent);
    payRates = filterOutOverMaximumAndUnderMinimum(payRates, benefitMaximum, benefitMinimum);
    addMaxRateIfOverOrEqualMaximum(employeeSalary, allowedAmounts, rateVolume, benefitMaximum, payRates, rateFactor, contributionDollar, contributionPercent);
    addMinRateIfUnderOrEqualMinimum(employeeSalary, allowedAmounts, rateVolume, benefitMinimum, payRates, rateFactor, contributionDollar, contributionPercent);
    return payRates;
};

const filterOutOverMaximumAndUnderMinimum = (payRates, benefitMaximum, benefitMinimum) => {
    return payRates.filter(rate => rate.value < benefitMaximum && rate.value > benefitMinimum);
};

const roundUpToRateVolume = (rateValue, rateVolume) => {
    return Math.ceil(rateValue / rateVolume) * rateVolume;
};

const buildPayRates = (allowedAmounts, employeeSalary, rateVolume, rateFactor, contributionDollar, contributionPercent) => {
    return allowedAmounts.map(allowedAmount => {
        const value = roundUpToRateVolume(employeeSalary * (parseInt(allowedAmount) / 100), rateVolume);
        return {
            display: `${parseInt(allowedAmount) / 100}X Salary`,
            value: value,
            rate: getRate(value, rateVolume, rateFactor, contributionDollar, contributionPercent)
        };
    });
};

const getRate = (value, rateVolume, rateFactor, contributionDollar, contributionPercent) => {
    const rateAmount = contributionDollar
        ? Math.round(((value / rateVolume * rateFactor) - contributionDollar) * 100) / 100
        : Math.round(((value / rateVolume * rateFactor) * contributionPercent) * 100) / 100;

    return rateAmount < 0 ? 0 : rateAmount;
};

const addMaxRateIfOverOrEqualMaximum = (employeeSalary, allowedAmounts, rateVolume, benefitMaximum, payRates, rateFactor, contributionDollar, contributionPercent) => {
    const highestEmployeeMultipleOfPay = roundUpToRateVolume(employeeSalary * (parseInt(allowedAmounts[allowedAmounts.length - 1]) / 100), rateVolume);
    if (benefitMaximum <= highestEmployeeMultipleOfPay) {
        payRates.push({
            display: `${parseInt(allowedAmounts[payRates.length]) / 100}X Salary`,
            value: benefitMaximum,
            rate: getRate(benefitMaximum, rateVolume, rateFactor, contributionDollar, contributionPercent)
        });
    }
};

const addMinRateIfUnderOrEqualMinimum = (employeeSalary, allowedAmounts, rateVolume, benefitMinimum, payRates, rateFactor, contributionDollar, contributionPercent) => {
    const lowestEmployeeMultipleOfPay = roundUpToRateVolume(employeeSalary * (parseInt(allowedAmounts[0]) / 100), rateVolume);
    if (benefitMinimum >= lowestEmployeeMultipleOfPay) {
        payRates.unshift({
            display: `${parseInt(allowedAmounts[0]) / 100}X Salary`,
            value: benefitMinimum,
            rate: getRate(benefitMinimum, rateVolume, rateFactor, contributionDollar, contributionPercent)
        });
    }
};